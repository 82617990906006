import PropTypes from 'prop-types';
import { useMemo, useEffect, useReducer, useCallback } from 'react';

import axios, { endpoints } from 'src/utils/axios';

import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';
import { toast } from 'react-toastify';
import { AuthContext } from './auth-context';
import { setSession, isValidToken } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'RELOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

         await ApiService.get(Endpoints.me)

        .then((response) => {

          const responseData = response.data;

          if (responseData?.status === true) {
            const { data } = response.data;
            setSession(response.data.accessToken);

            dispatch({
              type: 'INITIAL',
              payload: {
                user: {
                  ...data,
                  accessToken: response.data?.accessToken,
                },
              },
            });
            // toast.success(responseData.message, {
            //   position: toast.POSITION.TOP_RIGHT,
            // });
          } else {
            toast.error(responseData.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            throw Error(responseData)
          }
        });
} else {
  dispatch({
    type: 'INITIAL',
    payload: {
      user: null,
    },
  });
}
    } catch (error) {
  console.error(error);
  dispatch({
    type: 'INITIAL',
    payload: {
      user: null,
    },
  });
}
  }, []);

useEffect(() => {
  initialize();
}, [initialize]);

// LOGIN
const login = useCallback(async (mobile_no, password) => {


  try {

    await ApiService.post(Endpoints.login, {
      mobile_no,
      password,
    })
      .then((response) => {

        const responseData = response.data;

        if (responseData?.status === true) {
          const { accessToken, data } = response.data;
          setSession(accessToken);
          dispatch({
            type: 'LOGIN',
            payload: {
              user: {
                ...data,
                accessToken,
              },
            },
          });
          toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          throw Object.assign(new Error(responseData.message), { data: responseData.data });
          // throw Error(responseData)
        }
      });

  } catch (error) {
    console.log('errorsdsdddssd', error.data)
    // toast.error("Error submitting form...", {
    //   position: toast.POSITION.TOP_RIGHT,
    // });
    // throw Error(error.data.message)
    throw Object.assign(new Error(error.data.message), { data:error.data});

  };
}, []);
const relogin = useCallback(async (mobile_no, password) => {


  try {

    await ApiService.get(Endpoints.relogin)
      .then((response) => {

        const responseData = response.data;

        if (responseData?.status === true) {
          const { accessToken, data } = response.data;
          setSession(accessToken);
          dispatch({
            type: 'LOGIN',
            payload: {
              user: {
                ...data,
                accessToken,
              },
            },
          });
          toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          throw Object.assign(new Error(responseData.message), { data: responseData.data });
          // throw Error(responseData)
        }
      });

  } catch (error) {
    console.log('errorsdsdddssd', error.data)
    // toast.error("Error submitting form...", {
    //   position: toast.POSITION.TOP_RIGHT,
    // });
    // throw Error(error.data.message)
    throw Object.assign(new Error(error.data.message), { data:error.data});

  };
}, []);

// REGISTER
const register = useCallback(async (email, password, firstName, lastName) => {
  const data = {
    email,
    password,
    firstName,
    lastName,
  };

  const response = await axios.post(endpoints.auth.register, data);

  const { accessToken, user } = response.data;

  sessionStorage.setItem(STORAGE_KEY, accessToken);

  dispatch({
    type: 'REGISTER',
    payload: {
      user: {
        ...user,
        accessToken,
      },
    },
  });
}, []);

// LOGOUT
const logout = useCallback(async () => {
  setSession(null);
  dispatch({
    type: 'LOGOUT',
  });
}, []);

// ----------------------------------------------------------------------

const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

const status = state.loading ? 'loading' : checkAuthenticated;

const memoizedValue = useMemo(
  () => ({
    user: state.user,
    method: 'jwt',
    loading: status === 'loading',
    authenticated: status === 'authenticated',
    unauthenticated: status === 'unauthenticated',
    //
    login,
    register,
    relogin,
    logout,
  }),
  [login, logout,relogin, register, state.user, status]
);

return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
