import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { Autocomplete, FormControl, TextField } from '@mui/material';

// ----------------------------------------------------------------------

export default function SubCategoriesEdit({ currentUser, open, onClose }) {
  const { enqueueSnackbar } = useSnackbar();


  const defaultValues = useMemo(
    () => ({
      name: currentUser?.name || '',
    }),
    [currentUser]
  );

 const [selectedStatus, setSelectedStatus] = React.useState(null);

 const handleStatusChange = (event, newValue) => {
   setSelectedStatus(newValue);
 };

const options = [
  {value:'1' , label:'Software Service'},
  {value:'2' , label:'Loan Service'},
  {value:'3' , label:'CA Service'},
]


  const methods = useForm({
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
      onClose();
      enqueueSnackbar('Update success!');
      console.info('DATA', data);
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <Dialog
      maxWidth={false}
      open={open}
      onClose={onClose}

    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>Edit Categories</DialogTitle>

        <DialogContent className='mt-2'>

          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField name="name" label="Name" />

            <FormControl
                sx={{
                  flexShrink: 0,
                  width: { xs: 5, md: 250 },
                }}
              >
                {/* <InputLabel>Scheme</InputLabel> */}

                <Autocomplete
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  options={options}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField {...params} label="Category" variant="outlined" />
                  )}
                />
              </FormControl>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Close
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Submit
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

SubCategoriesEdit.propTypes = {
  currentUser: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
