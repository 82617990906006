import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { useMockedUser } from 'src/hooks/use-mocked-user';

import { fData } from 'src/utils/format-number';

import { countries } from 'src/assets/data';

import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFSwitch,
  RHFTextField,
  RHFUploadAvatar,
  RHFAutocomplete,
} from 'src/components/hook-form';
import { IconButton, TextField } from '@mui/material';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import ChangeIconData from 'src/sections/setup-tools/portal-setting/changeicon-data';
import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';
import BankData from './bank-data';

// ----------------------------------------------------------------------

export default function BankDetails() {
  const [children, setChildren] = useState([]);
  const [changetype, setSetChangetype] = useState([])
  const [open, setOpen] = useState(false);

  const handleOpen = (type) => {
    setOpen(true);
    setSetChangetype(type)

  };

  const appendChild = () => {
    setChildren([...children, <BankData key={children.length} />]);
  };
  const [isSubmitting, setIsSubmitting] = useState(true);
  const [tableData, setTableData] = useState([]);
  const { id } = useParams();
  const getTableData = async () => {
    setIsSubmitting(true)
    try {
      // const responsePermission = await ApiService.post(Endpoints.getuserpermission);
      // console.log('responsePermission', responsePermission)
      // setPermissionsData(responsePermission?.data?.data)
      ApiService.post(Endpoints.memberprofile.bankaccountget, { member_id: id }).then((response) => {
        const responseData = response.data
        console.log(response.data);
        setTableData(responseData.data);
        setIsSubmitting(false)

      });
    } catch (error) {
      setIsSubmitting(false)
      console.error("Error submitting form:", error);
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
  useEffect(() => {
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div style={{ textAlign: 'end' }}>
        <IconButton style={{ color: '#000', marginLeft: 'auto', marginTop: '-65px' }} onClick={() => { handleOpen('UPDATE_BANK') }}>
          <Iconify icon="typcn:info" />
        </IconButton>
        <LoadingButton onClick={appendChild} variant='contained' sx={{ marginTop: '-65px' }} startIcon={<Iconify icon="mingcute:add-line" />}>Add New</LoadingButton>
      </div>
      <div>
        {isSubmitting === false && tableData?.length > 0 ?
          tableData?.map((element) => (
            <BankData row={element} />
          ))
          :
          isSubmitting === true ?? <BankData />
        }

        {children}
      </div>
      {open === true ? <ChangeIconData open={open} setOpen={setOpen} type={changetype} member_id={id} /> : null}

    </>
  );
}
