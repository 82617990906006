'use client';

/* eslint-disable import/no-extraneous-dependencies */

import { useForm } from 'react-hook-form';
import React, { useState, useCallback, useEffect } from 'react';

import { Stack } from '@mui/system';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import { DatePicker } from '@mui/x-date-pickers';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import { Grid, Dialog, Select, MenuItem, TextField, InputLabel, DialogTitle, FormControl, DialogActions, DialogContent, OutlinedInput, InputAdornment, CircularProgress, Autocomplete, CardHeader, CardContent, Tabs, tabsClasses, Tab } from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter, useSearchParams } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';

import { _userList } from 'src/_mock';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import FormProvider from 'src/components/hook-form/form-provider';
import {
  useTable,
  getComparator,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';
import { toast } from 'react-toastify';
// import { notFound } from 'next/navigation';

// const STATUS_OPTIONS = [{ value: 'all', label: 'All' }, ...USER_STATUS_OPTIONS];

// ----------------------------------------------------------------------

export default function AddressMemberData() {
  const table = useTable();

  const settings = useSettingsContext();

  const router = useRouter();

  const confirm = useBoolean();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [resT, setResT] = useState("");
  const prms = useSearchParams();
  const tname = prms.get('t');
  const [currentTab, setCurrentTab] = useState('balanceenquiry');
  const [address, setAddress] = useState(false);
  const [district, setDistrict] = useState(false);
  const [state, setState] = useState(false);
  const [pincode, setPincode] = useState(false);
  const [city, setCity] = useState(false);

  const [selectedState, setSelectedState] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [stateData, setStateData] = useState();
  const [districtData, setDistrictData] = useState();
  const methods = useForm();


const [errors, setErrors] = useState({
  district: '',
  address: '',
  state: '',
  city: '',
  pincode: '',
});

  const handleSubmit = async (e) => {
    e.preventDefault();
    // router.push(paths.member.distributor);
    const requiredFields = ['district', 'address','state','city','pincode'];
    const newErrors = {};

    // Check if any of the required fields are empty
    requiredFields.forEach((field) => {
      if (!district) {
        newErrors.district = 'District is required';
      } else {
        newErrors.district = '';
      }

      if (!address) {
        newErrors.address = 'Address is required';
      } else {
        newErrors.address = '';
      }
      if (!state) {
        newErrors.state = 'State is required';
      } else {
        newErrors.state = '';
      }
      if (!city) {
        newErrors.city = 'City is required';
      } else {
        newErrors.city = '';
      }
      if (!pincode) {
        newErrors.pincode = 'Pincode is required';
      } else {
        newErrors.pincode = '';
      }
    });

    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error !== '')) {
      return;
    }
    try {
      setIsSubmitting(true)
      await ApiService.post(Endpoints.membercreate.pg4, {
        t: tname,
        district:selectedDistrict,
        address,
        state:selectedState,
        city,
        pincode

      }).then((response) => {
        setIsSubmitting(false)
        const responseData = response.data;
        console.log(responseData);

        if (responseData?.status === true) {
          console.log(response.data);
          setResT(response.data?.data?.t)
          router.push(paths.member.createmember);
          toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setIsSubmitting(false)
          toast.error("Data Invalid", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

      });

    } catch (error) {
      setIsSubmitting(false)
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    };

  };

  const getStateData = async () => {
    try {
      ApiService.post(Endpoints.portalsetting.getstate).then((response) => {
        const responseData = response.data;
        if (responseData?.status === true) {

          const apidata = responseData.data
            // .filter((element) => element.status === true)
            .map((element) => ({
              value: element.id,
              label: element.name,
            }));
          setStateData(apidata);
        }
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Error submitting form', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const getDistrictData = async (state_id) => {
    try {
      ApiService.post(Endpoints.portalsetting.getdistrict,{id:state_id}).then((response) => {
        const responseData = response.data;
        if (responseData?.status === true) {

          const apidata = responseData.data
            // .filter((element) => element.status === true)
            .map((element) => ({
              value: element.id,
              label: element.name,
            }));
          setDistrictData(apidata);
        }
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Error submitting form', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };




  const handlePincodeKeyPress = (e) => {
    // Allow only numeric digits (0-9)
    if (e.key && !/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
    if (e.target.value.length >= 5) {
      e.preventDefault();
    }
  };
  const {
    setValue,
  } = methods;
  const handleStateChange = async (event, newValue) => {
    if (newValue?.value) {
      setSelectedState(newValue);
      await getDistrictData(newValue?.value);

      // setFormData({ ...formData, schema_id: newValue.value ? newValue.value : null });
    }
  };


  const handleDistrictChange = (event, newValue) => {
    if (newValue?.value) {
      setSelectedDistrict(newValue);

      // setFormData({ ...formData, schema_id: newValue.value ? newValue.value : null });
    }
  };

  useEffect(() => {
    getStateData();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (

    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <FormProvider {...methods}>
        <Grid container spacing={2}>

          <Grid item xs={12} md={6} lg={4}>
            <TextField label="Address" name='address' onChange={(e) => { setAddress(e.target.value) }} value={address || ''} sx={{ width: '100%' }} />
            <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.address}</p>

          </Grid>
          <Grid item xs={12} md={6} lg={4}>
          <FormControl
              sx={{
                flexShrink: 0,
                width: '100%',
              }}
            >
              <Autocomplete
              name='state'
                value={selectedState}
                onChange={handleStateChange}
                options={stateData}
                getOptionLabel={(option) => (option.label !== undefined ? option.label : '')}
                renderInput={(params) => (
                  <TextField {...params} label="Select State" variant="outlined" />
                )}
              />
            </FormControl>
            <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.state}</p>

          </Grid>
          <Grid item xs={12} md={6} lg={4}>
          <FormControl
              sx={{
                flexShrink: 0,
                width: '100%',
              }}
            >
              <Autocomplete
               name='district'
                value={selectedDistrict}
                onChange={handleDistrictChange}
                options={districtData}
                getOptionLabel={(option) => (option.label !== undefined ? option.label : '')}
                renderInput={(params) => (
                  <TextField {...params} label="Select District" variant="outlined" />
                )}
              />
            </FormControl>
            <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.district}</p>

          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField label="City" name='city' onChange={(e) => { setCity(e.target.value) }} value={city || ''} sx={{ width: '100%' }} />
            <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.city}</p>

          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField label="Pincode" name='pincode' onChange={(e) => { setPincode(e.target.value) }} value={pincode || ''} sx={{ width: '100%' }}  onKeyPress={handlePincodeKeyPress} onInput={(e) => { setValue('mobile_no', e.target.value.replace(/[^0-9]/g, '').slice(0, 5)) }}/>
            <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.pincode}</p>

          </Grid>
          <Grid item xs={12} md={12} lg={12} textAlign='center' sx={{ mt: 3 }}>
            <LoadingButton variant="contained" type="submit" loading={isSubmitting} onClick={handleSubmit}>
              Submit
            </LoadingButton>
          </Grid>
        </Grid>
      </FormProvider>
    </Container>


  );
}
