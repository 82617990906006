'use client'

/* eslint-disable import/no-extraneous-dependencies */


// ----------------------------------------------------------------------

import { toast } from "react-toastify";
import React, { useMemo, useState, useEffect, useCallback } from "react";

import { Box } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { Grid, Table, TableRow, Checkbox, TableBody, TableCell, TableHead, TableContainer, FormControlLabel, FormControl, Autocomplete, TextField, Button, Stack, Tabs, Tab } from "@mui/material";

import ApiService from "src/common";
import Endpoints from "src/common/endpoints";
import CustomTabPanel from "src/components/customtabpanel/tabpanel";


export default function SchemeDetails({ ...prop }) {
  // console.log('prop', prop)

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apitableData, setApiTableData] = useState([]);
  const [aepsTableData, setAepsTableData] = useState([]);
  const [selectedApi, setSelectedApi] = React.useState([apitableData[0]]);

  const getCommissionData = async (schema_id = prop?.schemeId) => {
    try {
      ApiService.post(Endpoints.getschemacommission, { schema_id, type: 'aeps' }).then(
        (response) => {
          const responseData = response.data;

          if (responseData?.status === true) {
            setAepsTableData(responseData.data);
          }
        }
      );
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Error submitting form', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };


  const getApiData = async () => {
    try {
      ApiService.post(Endpoints.getAllSchema).then((response) => {
        const responseData = response.data;
        if (responseData?.status === true) {
          const apidata = responseData.data
            .filter((element) => element.status === true)
            .map((element) => ({
              value: element.id,
              label: element.schema_name,
              status: element.status,
            }));
          // prop?.schemeId
          // if (apidata.length > 0 && !selectedApi) {
          setApiTableData(apidata)
          // setSelectedApi(apidata[0]);
          setSelectedApi(apidata.find((item) => item.value === prop?.schemeId));
          // }
        }
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Error submitting form', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleSubmit = async () => {


    console.log('selectedApi', selectedApi)

    try {
        setIsSubmitting(true)

      ApiService.post(Endpoints.memberprofile.schemaupdate, { member_id: prop?.user_id, schema_id:selectedApi?.value }).then((response) => {

        const responseData = response.data;
        if (responseData?.status === true) {
          toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
         prop?.onCloseDrawer();
          setIsSubmitting(false)
        } else {
          toast.error(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Error submitting form', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

  }
  const handleApiChange = (event, newValue) => {
    if (newValue?.value) {
      setSelectedApi(newValue);
      getCommissionData(newValue?.value)
    }
  };

  useEffect(() => {

    getApiData();
    getCommissionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const [value, setValue] = useState(0); // Assuming you want to manage the active tab with state

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const a11yProps = (index) => ({
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  });
  return (
    <>
      <Stack direction="row" sx={{ m: 4 }} alignItems="center">
        <FormControl
          sx={{
            mr: 2,
            flexShrink: 0,
            width: '200px',
          }}
        >
          <Autocomplete
            value={selectedApi}
            onChange={handleApiChange}
            options={apitableData}
            getOptionLabel={(option) => (option.label !== undefined ? option.label : '')}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Scheme"
                variant="outlined"
              />
            )}
          />

        </FormControl>
        <Box sx={{ textAlign: 'center', mr: 2 }}>

          <LoadingButton onClick={handleSubmit} variant="contained" loading={isSubmitting} sx={{ mr: 1 }}>
            Submit
          </LoadingButton>
          <Button variant="outlined" onClick={prop?.onCloseDrawer}>
            Close
          </Button>
        </Box>
      </Stack>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {/* <Tab label="one" {...a11yProps(0)}  /> */}
          <Tab label="AEPS" {...a11yProps(0)} />
          {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>


      <CustomTabPanel value={value} index={0}>
        <TableContainer>
          <Table className="role_table" style={{ border: '1px solid #00a76fa4' }}>
            <TableHead style={{ border: '1px solid #00a76fa4' }}>
              <TableRow>
                <TableCell style={{ border: '1px solid #00a76fa4' }}>Provider</TableCell>
                <TableCell style={{ border: '1px solid #00a76fa4' }}>Type</TableCell>
                <TableCell style={{ border: '1px solid #00a76fa4' }}>Super Distributor</TableCell>
                <TableCell style={{ border: '1px solid #00a76fa4' }}>Md</TableCell>
                <TableCell style={{ border: '1px solid #00a76fa4' }}>Distributor</TableCell>
                <TableCell style={{ border: '1px solid #00a76fa4' }}>Retailer</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(aepsTableData) &&
                aepsTableData?.map((data, id) => (
                  <TableRow key={id}>
                    <TableCell style={{ border: '1px solid #00a76fa4' }}>{data.slab_label}</TableCell>
                    <TableCell style={{ border: '1px solid #00a76fa4' }}>{data.type}</TableCell>
                    <TableCell style={{ border: '1px solid #00a76fa4' }}>{data.sd}</TableCell>
                    <TableCell style={{ border: '1px solid #00a76fa4' }}>{data.master_distributor}</TableCell>
                    <TableCell style={{ border: '1px solid #00a76fa4' }}>{data.distributor}</TableCell>
                    <TableCell style={{ border: '1px solid #00a76fa4' }}>{data.retailer}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ mt: 5, textAlign: 'center', mr: 2 }}>
          {/* <LoadingButton onClick={handleSubmit} variant="contained" loading={isSubmitting}>
          Submit
        </LoadingButton> */}
        </Box>
      </CustomTabPanel>

      {/* <CustomTabPanel value={value} index={2}>
        Item Three Content
      </CustomTabPanel> */}



    </>
  );
}

// ----------------------------------------------------------------------



