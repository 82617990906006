const initialState = {
  isAuthenticated: false,
  todoMsg: ''
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        isAuthenticated: action.payload
      }
    default:
      return {
        ...state
      }
  }
}

export default authReducer;
