'use client';

/* eslint-disable import/no-extraneous-dependencies */

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { paths } from 'src/routes/paths';
import { useRouter, useSearchParams } from 'src/routes/hooks';

import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';

import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { Autocomplete, FormControl, TextField } from '@mui/material';


// ----------------------------------------------------------------------

export default function PG_4({ params }) {
  const router = useRouter();
  const [resT, setResT] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [address, setAddress] = useState(false);
  const [district, setDistrict] = useState(false);
  const [state, setState] = useState(false);
  const [pincode, setPincode] = useState(false);
  const prms = useSearchParams();
  const tname = prms.get('t');
  const [selectedState, setSelectedState] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [stateData, setStateData] = useState();
  const [districtData, setDistrictData] = useState();

  const [errors, setErrors] = useState({
    district: '',
    address: '',
    state: '',
    pincode: '',
  });


  const handleSubmit = async (e) => {
    e.preventDefault();
    const requiredFields = ['district', 'address','state','pincode'];
    const newErrors = {};

    // Check if any of the required fields are empty
    requiredFields.forEach((field) => {
      if (!district) {
        newErrors.district = 'District is required';
      } else {
        newErrors.district = '';
      }

      if (!address) {
        newErrors.address = 'Address is required';
      } else {
        newErrors.address = '';
      }
      if (!state) {
        newErrors.state = 'State is required';
      } else {
        newErrors.state = '';
      }

      if (!pincode) {
        newErrors.pincode = 'Pincode is required';
      } else {
        newErrors.pincode = '';
      }
    });

    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error !== '')) {
      return;
    }
    try {
      setIsSubmitting(true)
      await ApiService.post(Endpoints.pg4, {
        t: tname,
        district: selectedDistrict,
        address,
        state: selectedState,
        pincode
      }).then((response) => {
        setIsSubmitting(false)
        const responseData = response.data;
        console.log(responseData);

        if (responseData?.status === true) {
          console.log(response.data);
          setResT(response.data?.data?.t)
          router.push(paths.auth.jwt.login);
          toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setIsSubmitting(false)
          toast.error("Data Invalid", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

      });

    } catch (error) {
      setIsSubmitting(false)
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    };
  }


  const handleStateChange = async (event, newValue) => {
    if (newValue?.value) {
      setSelectedState(newValue);
      await getDistrictData(newValue?.value);

      // setFormData({ ...formData, schema_id: newValue.value ? newValue.value : null });
    }
  };


  const handleDistrictChange = (event, newValue) => {
    if (newValue?.value) {
      setSelectedDistrict(newValue);

      // setFormData({ ...formData, schema_id: newValue.value ? newValue.value : null });
    }
  };

  const getStateData = async () => {
    try {
      ApiService.post(Endpoints.portalsetting.getstate).then((response) => {
        const responseData = response.data;
        if (responseData?.status === true) {

          const apidata = responseData.data
            // .filter((element) => element.status === true)
            .map((element) => ({
              value: element.id,
              label: element.name,
            }));
          setStateData(apidata);
        }
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Error submitting form', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    getStateData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getDistrictData = async (state_id) => {
    try {
      ApiService.post(Endpoints.portalsetting.getdistrict, { id: state_id }).then((response) => {
        const responseData = response.data;
        if (responseData?.status === true) {
          const apidata = responseData.data
            .map((element) => ({
              value: element.id,
              label: element.name,
            }));
          setDistrictData(apidata);
          console.log('apidata', apidata)
        }
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Error submitting form', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handlePincodeKeyPress = (e) => {
    // Allow only numeric digits (0-9)
    if (e.key && !/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
    if (e.target.value.length >= 5) {
      e.preventDefault();
    }
  };

  const methods = useForm({});
  const {
    setValue,
  } = methods;
  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
      <Typography variant="h4">Address Details</Typography>
    </Stack>
  );
  const renderTerms = (
    <Typography
      component="div"
      sx={{
        color: 'text.secondary',
        mt: 2.5,
        typography: 'caption',
        textAlign: 'center',
      }}
    >
      {'By signing up, I agree to '}
      <Link underline="always" color="text.primary">
        Terms of Service
      </Link>
      {' and '}
      <Link underline="always" color="text.primary">
        Privacy Policy
      </Link>
      .
    </Typography>
  );
  const renderForm = (
    <FormProvider methods={methods} onSubmit={handleSubmit}>
      <Stack spacing={2.5}  >
        <RHFTextField name="address" label="Address" onChange={(e) => { setAddress(e.target.value) }} value={address || ''} />
        <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.address}</p>

        <FormControl
          sx={{
            flexShrink: 0,
            // width: '100%',
          }}
        >
          <Autocomplete
            name='state'
            value={selectedState}
            onChange={handleStateChange}
            options={stateData}
            getOptionLabel={(option) => (option.label !== undefined ? option.label : '')}
            renderInput={(params1) => (
              <TextField {...params1} label="Select State" variant="outlined" />
            )}
          />
        </FormControl>
        <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.state}</p>

      </Stack>
      <Stack spacing={2}>
        <FormControl
          sx={{
            flexShrink: 0,
            mt: 2
            // width: '100%',
          }}
        >
          <Autocomplete
            name='district'
            value={selectedDistrict}
            onChange={handleDistrictChange}
            options={districtData}
            getOptionLabel={(option) => (option.label !== undefined ? option.label : '')}
            renderInput={(params2) => (
              <TextField {...params2} label="Select District" variant="outlined" />
            )}
          />
        </FormControl>
        <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.district}</p>

        <RHFTextField name="pincode" label="Pincode" onChange={(e) => { setPincode(e.target.value) }} value={pincode || ''} sx={{ mb: 2 }}  onKeyPress={handlePincodeKeyPress} onInput={(e) => { setValue('mobile_no', e.target.value.replace(/[^0-9]/g, '').slice(0, 5)) }}/>
        <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.pincode}</p>

      </Stack>
      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="Submit"
        variant="contained"
        loading={isSubmitting}
      >
        Finish
      </LoadingButton>
      {/* </Stack> */}
    </FormProvider>
  );
  return (
    <>
      {renderHead}

      {renderForm}

      {renderTerms}
    </>
  );
}
PG_4.propTypes = {
  params: PropTypes.object.isRequired,
};
