import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

import Iconify from 'src/components/iconify';
import { toast } from 'react-toastify';
import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';
import BankData from './bank-data';

// ----------------------------------------------------------------------

export default function BankDetails() {
  const [children, setChildren] = useState([]);

  const appendChild = () => {
    setChildren([...children, <BankData key={children.length} />]);
  };
  const [isSubmitting, setIsSubmitting] = useState(true);
  const [tableData, setTableData] = useState([]);

  const getTableData = async () => {
    setIsSubmitting(true)
    try {
      // const responsePermission = await ApiService.post(Endpoints.getuserpermission);
      // console.log('responsePermission', responsePermission)
      // setPermissionsData(responsePermission?.data?.data)
      ApiService.post(Endpoints.profile.bankaccountget).then((response) => {
        const responseData = response.data
        console.log(response.data);
        setTableData(responseData.data);
        setIsSubmitting(false)

      });
    } catch (error) {
      setIsSubmitting(false)
      console.error("Error submitting form:", error);
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
  useEffect(() => {
    getTableData();
  }, []);
  return (
    <>
      <div style={{ textAlign: 'end' }}>
        <LoadingButton onClick={appendChild} variant='contained' sx={{ marginTop: '-65px' }} startIcon={<Iconify icon="mingcute:add-line" />}>Add New</LoadingButton>
      </div>
      <div>
        {isSubmitting===false && tableData?.length > 0 ?
          tableData?.map((element) => (
            <BankData row={element} />
          ))
          :
          isSubmitting===true ?? <BankData />
        }

        {children}
        </div>
    </>
  );
}
