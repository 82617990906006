import { useForm, Controller } from 'react-hook-form';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';

import FormProvider from 'src/components/hook-form';
import { useSnackbar } from 'src/components/snackbar';
import { IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { useAuthContext } from 'src/auth/hooks';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';


// ----------------------------------------------------------------------

export default function PWDManager() {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuthContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [passwrod, setPassword] = useState('');

  const password = useBoolean();
  const password1 = useBoolean();
  const password2 = useBoolean();


  const methods = useForm({
    defaultValues: {
      selected: ['activity_comments', 'application_product'],
    },
  });



  const {
    watch,
    setValue,
    handleSubmit,
  } = methods;



  const [formData, setFormData] = useState({
    confirm_password: '',
    new_password: '',
    old_password: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData, ...formData,
      [name]: value,
    }));
  };




  const values = watch();

  const onSubmit = handleSubmit(async (data) => {
    console.log('data', formData)


    try {
      setIsSubmitting(true)
      await ApiService.post(Endpoints.changeprofile.passwordchange, formData).then(async (response) => {
        const responseData = response.data
        if (responseData?.status === true) {
          // setUserData(responseData.data);

          // Set values in the form fields using setValue

          toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
        } else {
          toast.error(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
        }
      });
    } catch (error) {
      setIsSubmitting(false)
      console.error("Error submitting form:", error);
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  });
  const getPassword = async () => {
    try {
      // setIsSubmitting(true)
      await ApiService.post(Endpoints.changeprofile.getpassword).then(async (response) => {
        const responseData = response.data
        console.log('responseData', responseData)
        if (responseData?.status === true) {
          setPassword(responseData?.data?.password)
        } else {
          toast.error(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
        }
      });
    } catch (error) {
      setIsSubmitting(false)
      console.error("Error submitting form:", error);
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  useEffect(() => {
    getPassword();
  }, [])
  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack spacing={3} sx={{ p: 3 }}>
        <Typography variant="h6" sx={{ textAlign: 'end' }}>Current Password : {passwrod}</Typography>
      </Stack>
      <Stack component={Card} spacing={3} sx={{ p: 3 }}>
        <Grid container spacing={3}>
          <Grid xs={12} md={6}>
            <TextField label="Old Password" name='old_password' sx={{ width: '100%' }} onChange={handleInputChange} type={password.value ? 'text' : 'password'}
              value={formData.old_password || ''} InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={password.onToggle} edge="end">
                      <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}/>
          </Grid>
          <Grid xs={12} md={6}>
            <TextField label="New Password" name='new_password' sx={{ width: '100%' }} onChange={handleInputChange}  type={password1.value ? 'text' : 'password'}

              value={formData.new_password || ''} InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={password1.onToggle} edge="end">
                      <Iconify icon={password1.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}/>
          </Grid>
          <Grid xs={12} md={6}>
            <TextField label="Confirmed Password" name='confirm_password' sx={{ width: '100%' }} onChange={handleInputChange}
              value={formData.confirm_password || ''}  type={password2.value ? 'text' : 'password'} InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={password2.onToggle} edge="end">
                      <Iconify icon={password2.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              />
          </Grid>
        </Grid>
        <LoadingButton type="submit" variant="contained" loading={isSubmitting} sx={{ ml: 'auto' }}>
          Submiit
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
