'use client';

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Stack } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { Grid, Select, MenuItem, InputLabel, FormControl, OutlinedInput, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";

import ApiService from "src/common";
import Biometric from "src/common/biometric";
import Endpoints from "src/common/endpoints";

import { RHFTextField } from "src/components/hook-form";
import FormProvider from "src/components/hook-form/form-provider";
import { toast } from "react-toastify";
import { useAuthContext } from "src/auth/hooks";



export default function CashWithdrawal() {
  const { user } = useAuthContext();

  // ip fetch
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [ipAddress, setIpAddress] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);

          // Update formData with location data
          setFormData((prevData) => ({
            ...prevData,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }));
          setCashWithdraw((prevData) => ({
            ...prevData,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }));
        },
      );
    }

    fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data1) => {
        setIpAddress(data1.ip);

        // Update formData with IP address
        setFormData((prevData) => ({
          ...prevData,
          ipaddress: data1.ip,
        }));
        setCashWithdraw((prevData) => ({
          ...prevData,
          ipaddress: data1.ip,
        }));
      })
      .catch((error) => console.error('Error getting IP address:', error));
  }, []);


  const methods = useForm({
    resolver: yupResolver(),
  });
  const {
    setValue,
    reset,
  } = methods;

  const [cashWithdraw, setCashWithdraw] = useState({
    latitude: null,
    longitude: null,
    ipaddress: null,
  });

  const [selectedStatus, setSelectedStatus] = useState('');

  const [capturData, setCapturData] = useState('');

  const onChangeCaptureData = (data) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      rawData: data,
    }));
    setCashWithdraw((prevFormData) => ({
      ...prevFormData,
      rawData: data,
    }));
    setCapturData(data)

  }
  const banks = [
    {
      "value": "990320",
      "name": "Airtel Payment Bank"
    },
    {
      "value": "608112",
      "name": "Allahabad Bank"
    },
    {
      "value": "607024",
      "name": "Allahabad UP Gramin Bank"
    },
    {
      "value": "607076",
      "name": "Andhra Bank"
    },
    {
      "value": "607198",
      "name": "Andhra Pradesh Grameena Vikash Bank"
    },
    {
      "value": "607121",
      "name": "Andhra Pragathi Grameena Bank"
    },
    {
      "value": "607051",
      "name": "AP Mahesh Coop Urban Bank Ltd"
    },
    {
      "value": "607024",
      "name": "Aryavart Bank"
    },
    {
      "value": "607064",
      "name": "Assam Gramin Vikash Bank"
    },
    {
      "value": "608087",
      "name": "AU Small Finance Bank"
    },
    {
      "value": "607153",
      "name": "Axis Bank"
    },
    {
      "value": "607063",
      "name": "Bangiya Gramin Vikash Bank"
    },
    {
      "value": "606985",
      "name": "Bank Of Baroda"
    },
    {
      "value": "508505",
      "name": "Bank of India"
    },
    {
      "value": "607387",
      "name": "Bank of Maharashtra"
    },
    {
      "value": "606995",
      "name": "Baroda Gujarat Gramin Bank"
    },
    {
      "value": "607280",
      "name": "Baroda Rajasthan Kshetriya Gramin Bank"
    },
    {
      "value": "606993",
      "name": "Baroda Uttar Pradesh Gramin Bank "
    },
    {
      "value": "607396",
      "name": "Canara Bank"
    },
    {
      "value": "607082",
      "name": "Catholic Syrian Bank"
    },
    {
      "value": "607264",
      "name": "Central Bank of India"
    },
    {
      "value": "607080",
      "name": "Chaitanya Godavari Gramin Bank"
    },
    {
      "value": "607214",
      "name": "Chhattisgarh Rajya Gramin Bank"
    },
    {
      "value": "607324",
      "name": "City Union Bank "
    },
    {
      "value": "607184",
      "name": "Corporation Bank"
    },
    {
      "value": "607136",
      "name": "Dakshin Bihar Gramin Bank"
    },
    {
      "value": "508547",
      "name": "Dena Bank"
    },
    {
      "value": "607099",
      "name": "Dena Gujarat Gramin Bank"
    },
    {
      "value": "607218",
      "name": "Ellaquai Dehati Bank"
    },
    {
      "value": "508998",
      "name": "Equitas Small Finance Bank"
    },
    {
      "value": "607363",
      "name": "Federal Bank"
    },
    {
      "value": "817304",
      "name": "Fincare Small Finance Bank"
    },
    {
      "value": "608001",
      "name": "Fino Payments Bank"
    },
    {
      "value": "607024",
      "name": "Gramin bank of Aryavart"
    },
    {
      "value": "607152",
      "name": "HDFC Bank"
    },
    {
      "value": "607140",
      "name": "Himachal Pradesh Gramin Bank"
    },
    {
      "value": "508534",
      "name": "ICICI Bank"
    },
    {
      "value": "607095",
      "name": "IDBI Bank"
    },
    {
      "value": "608117",
      "name": "IDFC First Bank"
    },
    {
      "value": "608314",
      "name": "India Post Payment Bank"
    },
    {
      "value": "607105",
      "name": "Indian bank"
    },
    {
      "value": "607126",
      "name": "Indian Overseas Bank"
    },
    {
      "value": "607189",
      "name": "IndusInd Bank"
    },
    {
      "value": "607440",
      "name": "Jammu &amp; Kashmir Bank "
    },
    {
      "value": "607210",
      "name": "Jharkhand Gramin Bank"
    },
    {
      "value": "607210",
      "name": "Jharkhand Rajya Gramin Bank"
    },
    {
      "value": "607270",
      "name": "Karnataka Bank"
    },
    {
      "value": "607512",
      "name": "Karnataka Gramin Bank"
    },
    {
      "value": "607122",
      "name": "Karnataka Vikas Grameena Bank "
    },
    {
      "value": "508662",
      "name": "Karur Vysya Bank"
    },
    {
      "value": "607365",
      "name": "Kashi Gomati Samyut Gramin Bank"
    },
    {
      "value": "607399",
      "name": "Kerala Gramin Bank"
    },
    {
      "value": "990309",
      "name": "Kotak Mahindra Bank"
    },
    {
      "value": "607058",
      "name": "Lakshmi Vilas Bank"
    },
    {
      "value": "607203",
      "name": "Langpi Dehangi Rural Bank"
    },
    {
      "value": "607136",
      "name": "Madhya Bihar Gramin Bank erstwhile Dakshin Bihar Gramin Bank"
    },
    {
      "value": "607022",
      "name": "Madhya Pradesh Gramin Bank"
    },
    {
      "value": "607232",
      "name": "Madhyanchal Gramin Bank"
    },
    {
      "value": "607000",
      "name": "Maharashtra Gramin Bank "
    },
    {
      "value": "607062",
      "name": "Manipur Rural Bank "
    },
    {
      "value": "607206",
      "name": "Meghalaya Rural Bank"
    },
    {
      "value": "607230",
      "name": "Mizoram Rural Bank"
    },
    {
      "value": "607022",
      "name": "Narmada Jhabua Gramin Bank"
    },
    {
      "value": "607060",
      "name": "Odisha Gramya Bank "
    },
    {
      "value": "607027",
      "name": "Oriental Bank of Commerce"
    },
    {
      "value": "607052",
      "name": "Pallavan Grama Bank"
    },
    {
      "value": "607059",
      "name": "Pandyan Grama Bank"
    },
    {
      "value": "607079",
      "name": "Paschim Banga Gramin Bank"
    },
    {
      "value": "607400",
      "name": "Pragathi Krishna Gramin Bank"
    },
    {
      "value": "607135",
      "name": "Prathama Bank"
    },
    {
      "value": "607135",
      "name": "Prathma UP Gramin Bank"
    },
    {
      "value": "607054",
      "name": "Puduvai Bharathiar Grama Bank"
    },
    {
      "value": "607087",
      "name": "Punjab &amp; Sind Bank"
    },
    {
      "value": "607138",
      "name": "Punjab Gramin Bank"
    },
    {
      "value": "607027",
      "name": "Punjab National Bank"
    },
    {
      "value": "607212",
      "name": "Purvanchal Gramin Bank"
    },
    {
      "value": "607509",
      "name": "Rajasthan Marudhara Gramin Bank"
    },
    {
      "value": "607393",
      "name": "Ratnakar Bank"
    },
    {
      "value": "607053",
      "name": "Saptagiri Grameena Bank"
    },
    {
      "value": "607139",
      "name": "Sarva Haryana Gramin Bank"
    },
    {
      "value": "607135",
      "name": "Sarva UP Gramin Bank "
    },
    {
      "value": "607200",
      "name": "Saurashtra Gramin Bank"
    },
    {
      "value": "607119",
      "name": "Shivalik Mercantile Cooperative Bank "
    },
    {
      "value": "607475",
      "name": "South Indian Bank"
    },
    {
      "value": "607094",
      "name": "State Bank of India"
    },
    {
      "value": "607580",
      "name": "Syndicate Bank"
    },
    {
      "value": "607187",
      "name": "Tamilnad Mercantile Bank"
    },
    {
      "value": "607052",
      "name": "TamilNadu Grama Bank"
    },
    {
      "value": "607195",
      "name": "Telangana Grameena Bank"
    },
    {
      "value": "652150",
      "name": "The Saraswat Co-operative Bank Ltd"
    },
    {
      "value": "607065",
      "name": "Tripura Gramin Bank"
    },
    {
      "value": "607066",
      "name": "UCO Bank"
    },
    {
      "value": "508991",
      "name": "Ujjivan Small Finance Bank Limited"
    },
    {
      "value": "607161",
      "name": "Union Bank of India"
    },
    {
      "value": "607646",
      "name": "United Bank Of India"
    },
    {
      "value": "607234",
      "name": "Utkal Gramin Bank"
    },
    {
      "value": "607073",
      "name": "Uttar Banga Kshetriya Gramin Bank "
    },
    {
      "value": "607069",
      "name": "Uttar Bihar Grameen Bank "
    },
    {
      "value": "607197",
      "name": "Uttarakhand Gramin Bank"
    },
    {
      "value": "607210",
      "name": "Vananchal Gramin Bank"
    },
    {
      "value": "607020",
      "name": "Vidarbha Konkan Gramin Bank"
    },
    {
      "value": "607075",
      "name": "Vijaya Bank"
    },
    {
      "value": "607618",
      "name": "YES Bank"
    },
    {
      "value": "608032",
      "name": "Paytm Payments Bank"
    },
    {
      "value": "100013",
      "name": "PAYTM UAT BALANCE ENQUIRY"
    },
    {
      "value": "100051",
      "name": "PAYTM UAT CAHSWITHDRAW"
    },
    {
      "value": "100012",
      "name": "PAYTM UAT MINISTAEMENT"
    }
  ];
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({
    adhaarnumber: '',
    name: '',
    mobile: '',
    bank: '',
    bankname: '',
    amount: '',
  });

  const [formData, setFormData] = useState({
    adhaarnumber: '',
    name: '',
    mobile: '',
    bank: '',
    bankname: '',
    amount: '',
    latitude: null,
    longitude: null,
    ipaddress: null,
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === 'bank') {
      const selectedBank = banks.find((bank) => bank.value === value);
      if (selectedBank) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          bankname: selectedBank.name,
        }));
      }
    }
    if (name === 'status') {
      handleStatusChange(e);
    }
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const requiredFields = ['adhaarnumber', 'name', 'mobile', 'bank', 'bankname', 'amount'];
    const newErrors = {};

    // Check if any of the required fields are empty
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
      } else {
        newErrors[field] = '';
      }
    });
    setErrors(newErrors);
    if (Object.values(newErrors).some((error) => error !== '')) {
      return;
    }

    if (capturData) {
      // console.log('', )
    } else {
      console.log('Please capture data before submitting.');
    }
    try {
      setIsSubmitting(true)
      await ApiService.post(Endpoints.cashwithdraw, formData).then((response) => {
        // console.log( response.data);
      const responseData = response.data
      toast.success("Data Submitted Successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      });
      setIsSubmitting(false)

    } catch (error) {
      setIsSubmitting(false)
      // console.error("Error submitting form:", error);
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const [cw, setCw] = useState(true);
  const [twoFactor, setTwoFactor] = useState(false);


  // timer start
  const [timer, setTimer] = useState(3 * 60); // 3 minutes in seconds

  useEffect(() => {

    const startTimer = () => {
      setTwoFactor(true);
      setTimer(3 * 60);
    };

    // Function to handle opening the dialog and restarting the timer
    const showDialog = () => {
      setTwoFactor(true);
      startTimer();
    };

    // Set timeout to open the dialog after the initial timer duration
    const timeoutId = setTimeout(showDialog, timer * 1000);

    // Set interval to decrement the timer every second
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    // Cleanup function to clear the timeout and interval when the component unmounts or when the timer changes
    return () => {
      clearTimeout(timeoutId);
      clearInterval(intervalId);
    };
  }, [timer]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };
// timer end

  const handleMobileNoKeyPress = (e) => {
    // Allow only numeric digits (0-9)
    if (e.key && !/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
    if (e.target.value.length >= 10) {
      e.preventDefault();
    }
  };
  const handleAadharNoKeyPress = (e) => {
    // Allow only numeric digits (0-9)
    if (e.key && !/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
    if (e.target.value.length >= 12) {
      e.preventDefault();
    }
  };
  const handleClose = () => {
    setTwoFactor(false);
    setCw(false);

  };

  const handleCashWithdraw = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitting(true)
      await ApiService.post(Endpoints.cashwithdrawauthenticate, cashWithdraw).then((response) => {
        // console.log( response.data);
        const responseData = response.data
        toast.success(responseData.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
      setIsSubmitting(false)

    } catch (error) {
      setIsSubmitting(false)
      // console.error("Error submitting form:", error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setCw(false);
  }


  const renderForm = (
    <FormProvider methods={methods} onSubmit={handleSubmit}>
      <Stack spacing={2.5} sx={{ p: 3 }}>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}> */}
            <RHFTextField name="adhaarnumber" label="Aadharcard Number" onChange={handleInputChange} onKeyPress={handleAadharNoKeyPress}
              onInput={(e) => { setValue('aadhar_no', e.target.value.replace(/[^0-9]/g, '').slice(0, 12)) }} />
                      <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.adhaarnumber}</p>

          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth>
              <InputLabel>Bank</InputLabel>

              <Select
                name="bank"
                value={formData.bank}
                onChange={handleInputChange}
                input={<OutlinedInput label="Name" />}
                renderValue={(selected) => {
                  const selectedBank = banks.find((bank) => bank.value === selected);
                  return selectedBank ? selectedBank.name : '';
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 240,
                      maxWidth: '100%',
                    },
                  },
                }}
              >
                {banks.map((bank) => (
                  <MenuItem key={bank.value} value={bank.value}>
                    {bank.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.bank}</p>

          </Grid>
          <Grid item xs={12} md={6} lg={4}>

            <RHFTextField name="name" label="Name" onChange={handleInputChange} />
            <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.name}</p>

          </Grid>


          <Grid item xs={12} md={6} lg={4}>
            <RHFTextField name="mobile" label="Mobile" onChange={handleInputChange} onKeyPress={handleMobileNoKeyPress}
              onInput={(e) => { setValue('mobile_no', e.target.value.replace(/[^0-9]/g, '').slice(0, 10)) }} />
                      <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.mobile}</p>

          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <RHFTextField name="amount" label="Amount" onChange={handleInputChange} />
            <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.amount}</p>

          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Biometric capturData={onChangeCaptureData} />
          </Grid>

        </Grid>
        <Grid container>
          <Grid item xs={12} md={4} lg={2} sx={{ mx: 'auto', mt: 2 }}>
            <LoadingButton
              fullWidth
              color="inherit"
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              disabled={!capturData}

            >
              Submit
            </LoadingButton>
          </Grid>
        </Grid>
        {/* </Stack> */}
      </Stack>
    </FormProvider>
  );


  return (
    <>
      {renderForm}

      {/* {twoFactor ? (
        <p>Timer is complete!</p>
      ) : (
        <p>Authentication complete {formatTime(timer)}</p>
      )} */}

      {/* <Dialog className='add_dialog' open={twoFactor} onClose={handleClose} >
        <form>
          <DialogTitle>Two Factor Authentication</DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Biometric capturData={setCapturData} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" type="submit" disabled={!capturData} >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog> */}


      <Dialog className='add_dialog' open={cw} onClose={handleClose} >
        <form>
          <DialogTitle>CW Two Factor Authentication</DialogTitle>
          <DialogContent>
            {/* Your form content */}
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Biometric capturData={onChangeCaptureData} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" type="submit" disabled={!capturData} onClick={handleCashWithdraw}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )

}
