/* eslint-disable */
'use client';


import { combineReducers } from "redux";
import { useDispatch, useSelector } from "react-redux";

import authReducer from "./AuthReducer";

const rootReducer = combineReducers({
  auth: authReducer
})


export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;

export default rootReducer
