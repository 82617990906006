'use client';

/* eslint-disable import/no-extraneous-dependencies */

import { useForm } from 'react-hook-form';
import React, { useState, useCallback, useEffect } from 'react';

import { Stack } from '@mui/system';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import { DatePicker } from '@mui/x-date-pickers';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import {
  Grid,
  Dialog,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  DialogTitle,
  FormControl,
  DialogActions,
  DialogContent,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
  Autocomplete,
} from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';

import { _userList } from 'src/_mock';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import FormProvider from 'src/components/hook-form/form-provider';
import {
  useTable,
  getComparator,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';
import { toast } from 'react-toastify';
import AllLeadTable from './alllead-table';
// import { notFound } from 'next/navigation';

// const STATUS_OPTIONS = [{ value: 'all', label: 'All' }, ...USER_STATUS_OPTIONS];

const TABLE_HEAD = [
  { id: 'id', label: 'Id' ,width:80},
  { id: 'retailer', label: 'Retailer Details' },
  { id: 'customer', label: 'Customer Details' },
  { id: 'address', label: 'Address',width:200 },
  { id: 'service', label: 'Service Requested',width:200  },
  { id: 'reason', label: 'Reason',width:100  },
  { id: 'status', label: 'Status' },
];

// ----------------------------------------------------------------------

export default function AllLeadData() {
  const table = useTable({ defaultRowsPerPage: 10 });

  const settings = useSettingsContext();

  const router = useRouter();

  const confirm = useBoolean();

  const [tableData, setTableData] = useState([]);
  const [apiLimitAndSkip, setApiLimitAndSkip] = useState({ limit: 10, skip: 0 });
  const [totalrecored, setTotalrecored] = useState([]);

  const [open, setOpen] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [permissionsData, setPermissionsData] = useState([]);

  const [apitableData, setApiTableData] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const methods = useForm();

  const [formData, setFormData] = useState({
    cust_name: '',
    cust_mobile_no: '',
    cust_email: '',
    cust_address: '',
    cust_state: '',
    remark: '',
    category: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      ...formData,
      [name]: value,
    }));
  };

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);
      await ApiService.post(Endpoints.createretailers, formData).then(async (response) => {
        const responseData = response.data;
        if (responseData?.status === true) {
          console.log(response.data);
          await getTableData();
          handleClose();
          toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          handleClose();
          setIsSubmitting(false);
        } else {
          toast.error(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false);
        }
      });
    } catch (error) {
      setIsSubmitting(false);
      console.error('Error submitting form:', error);
      toast.error('Error submitting form', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
  });

  const [selectedStatus, setSelectedStatus] = useState('');

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };
  const [filters, setFilters] = useState({ name: '' });

  const handleFilterName = (value) => {
    // Update the filters state with the new value
    setFilters((prevFilters) => ({ ...prevFilters, name: value }));
  };

  const handleEditRow = useCallback(
    (id) => {
      router.push(`${paths.member.retailer}`);
    },
    [router]
  );

  const getTableData = async (limit, page) => {

    setIsSubmitting(true);
    try {
      ApiService.post(Endpoints.getretailers, null, { params: { limit: Number(limit), page: Number(page) } }).then(
        (response) => {
          const responseData = response.data;
          console.log(response.data);
          // if (responseData?.status === true) {
          table.setPage(page)
          table.setRowsPerPage(limit)
          // }
          setTotalrecored(responseData?.totalrecored);
          setTableData(responseData.data);
          setIsSubmitting(false);
        }
      );
    } catch (error) {
      setIsSubmitting(false);
      console.error('Error submitting form:', error);
      toast.error('Error submitting form', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const getPermissionData = async () => {
    try {
      ApiService.post(Endpoints.rolegetpermission).then((response) => {
        const responseData = response.data;
        if (responseData?.status === true) {
          setPermissionsData(responseData?.data);
        }
      });
    } catch (error) {
      toast.error('Error submitting form', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const getApiData = async () => {
    try {
      ApiService.post(Endpoints.getAllSchema).then((response) => {
        const responseData = response.data;
        console.log('responseData', responseData);
        if (responseData?.status === true) {
          const apidata = responseData.data.map((element) => ({
            value: element.id,
            label: element.schema_name,
          }));
          console.log('responseData.data', apidata);
          setApiTableData(apidata);
        }
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Error submitting form', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const [selectedApi, setSelectedApi] = React.useState(null);

  const handleApiChange = (event, newValue) => {
    const selectedValue = event.target.value;
    setSelectedApi(selectedValue);
    setFormData((prevData) => ({
      ...prevData, ...formData,
      category: selectedValue,
    }));

  };

  const category =[
    {
      value:'loan',
      name:'Housing Loan',
    },
    {
      value:'loan',
      name:'Housing Loan',
    },
    {
      value:'loan',
      name:'Housing Loan',
    },
    {
      value:'loan',
      name:'Housing Loan',
    }
  ]


  useEffect(() => {
    getApiData();
    // getTableData();
    try {
      ApiService.post(Endpoints.getretailers, null, { params: { limit: 10, page: 0 } }).then(
        (response) => {
          const responseData = response.data;
          console.log(response.data);
          setTotalrecored(responseData?.totalrecored);
          setTableData(responseData.data);
          setIsSubmitting(false);
        }
      );
    } catch (error) {
      setIsSubmitting(false);
      console.error('Error submitting form:', error);
      toast.error('Error submitting form', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    getPermissionData();
  }, []);

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="All Leads"
          links={[
            { name: 'Dashboard', href: paths.dashboard.root },
            { name: 'Services' },
            { name: 'All Leads' },
          ]}
          action={
            <>
              <LoadingButton
                // onClick={handleOpen}
                variant="contained"
                startIcon={<Iconify icon="mdi:refresh" />}
                sx={{ mr: 1 }}
                loading={isSubmitting}
                onClick={getTableData}
              >
                Refresh
              </LoadingButton>
              <Button
                onClick={handleOpen}
                variant="contained"
                startIcon={<Iconify icon="mingcute:add-line" />}
                sx={{ mr: 1 }}
              >
                Add New
              </Button>
              <LoadingButton
                variant="contained"
                startIcon={<Iconify icon="mdi:file-excel" />}
                loading={isSubmitting}
              // onClick={getTableData}
              >
                Export
              </LoadingButton>
            </>
          }
          sx={{ mb: { xs: 3, md: 5 } }}
        />

        <Card>
          <Stack
            spacing={2}
            alignItems={{ xs: 'flex-end', md: 'center' }}
            direction={{
              xs: 'column',
              md: 'row',
            }}
            sx={{
              p: 2.5,
            }}
          >
            <DatePicker
              label="Start date"
              format="dd/MM/yyyy"
              value={filters.startDate}
              // onChange={handleFilterStartDate}
              slotProps={{ textField: { fullWidth: true } }}
              sx={{
                maxWidth: { md: 150 },
              }}
            />

            <DatePicker
              label="End date"
              format="dd/MM/yyyy"
              value={filters.endDate}
              // onChange={handleFilterEndDate}
              slotProps={{
                textField: {
                  fullWidth: true,
                  // error: dateError,
                },
              }}
              sx={{
                maxWidth: { md: 150 },
              }}
            />

            <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
              <TextField
                fullWidth
                value={filters.name}
                onChange={(e) => handleFilterName(e.target.value)}
                placeholder="Search..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                value={filters.id}
                // onChange={(e) => handleFilterId(e.target.value)}
                placeholder="Agent Id"
              />
            </Stack>

            <FormControl
              sx={{
                flexShrink: 0,
                width: { xs: 5, md: 130 },
              }}
            >
              <InputLabel>Status</InputLabel>

              <Select
                value={selectedStatus}
                onChange={handleStatusChange}
                input={<OutlinedInput label="Name" />}
                renderValue={(selected) => selected}
                MenuProps={{
                  PaperProps: {
                    sx: { maxHeight: 240 },
                  },
                }}
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="block">Block</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              sx={{
                flexShrink: 0,
                width: { xs: 5, md: 130 },
              }}
            >
              <Button
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:search-fill" />}
                sx={{ height: 50 }}
              >
                Search
              </Button>
            </FormControl>
          </Stack>

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              numSelected={table.selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) =>
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                )
              }
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={confirm.onTrue}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={table.selected.length}
                // onSort={table.onSort}
                />
                {isSubmitting ? (
                  <CircularProgress
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      margin: 'auto',
                    }}
                    size={30}
                  />
                ) : (
                  <TableBody>
                    {tableData
                      // .slice(
                      //   table.page * table.rowsPerPage,
                      //   table.page * table.rowsPerPage + table.rowsPerPage
                      // )
                      .map((row) => (
                        <AllLeadTable
                          key={row.id}
                          row={row}
                          permissionsData={permissionsData}
                          selected={table.selected.includes(row.id)}
                          onSelectRow={() => table.onSelectRow(row.id)}
                          onEditRow={() => handleEditRow(row.id)}
                        />
                      ))}

                    {/* <TableNoData notFound={notFound} /> */}
                  </TableBody>
                )}
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={totalrecored}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            // onPageChange={table.onChangePage}
            rowsPerPageOptions={[10, 25, 50]}
            onRowsPerPageChange={(e) => {
              getTableData(e.target.value, 0);
            }}
            onPageChange={(a, b) => { getTableData(table.rowsPerPage, b); }}
          />
        </Card>
      </Container>

      <Dialog open={open} onClose={handleClose} className="add_dialog">
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle>Create Retailer</DialogTitle>
          <DialogContent>
            <FormProvider {...methods}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Customer Name"
                    name="cust_name"
                    onChange={handleInputChange}
                    value={formData.cust_name || ''}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Customer Phone"
                    name="cust_mobile_no"
                    onChange={handleInputChange}
                    value={formData.cust_mobile_no || ''}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Customer Email"
                    name="cust_email"
                    onChange={handleInputChange}
                    value={formData.cust_email || ''}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Customer Address"
                    name="cust_address"
                    onChange={handleInputChange}
                    value={formData.cust_address || ''}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Customer State"
                    name="cust_state"
                    onChange={handleInputChange}
                    value={formData.address || ''}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  {/* <TextField label="Scheme" name='schema_id' onChange={handleInputChange} value={formData.schema_id || ''} /> */}
                  <FormControl
                    sx={{
                      flexShrink: 0,
                      width: { xs: 5, md: 140 },
                    }}
                  >
                    <Autocomplete
                      name="category"
                      value={selectedApi}
                      onChange={handleApiChange}
                      options={category}
                      getOptionLabel={(option) => (option.label !== undefined ? option.label : '')}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Category" variant="outlined" />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Remark"
                    name="remark"
                    onChange={handleInputChange}
                    value={formData.address || ''}
                  />
                </Grid>
              </Grid>
            </FormProvider>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
              Submit
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator, filters }) {
  // const { name, status, role } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  return inputData;
}
