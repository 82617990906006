'use client';

/* eslint-disable import/no-extraneous-dependencies */

import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useState, useCallback } from 'react';

import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { Dialog, DialogTitle, DialogActions, DialogContent } from '@mui/material';

import { useSearchParams } from 'src/routes/hooks';

import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';

import FormProvider, { RHFTextField, RHFUploadAvatar } from 'src/components/hook-form';

// ----------------------------------------------------------------------



export default function PG_2({ params, onNextStep }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState('');
  const [panno, setPanno] = useState('');

  const prms = useSearchParams();
  const tname = prms.get('t');
  const [resT, setResT] = useState("");
  const [resPg, setResPg] = useState("");

  const [open, setOpen] = useState(false);

  const [panimgbase64, setPanimgBase64] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = () => {
    setIsSubmitting(true)
    onNextStep(resPg, resT);
  };

  const [errors, setErrors] = useState({
    pan: '',
    image: '',
  });
  const handleSubmit = async (e) => {
    e.preventDefault();

    const requiredFields = ['pan', 'image'];
    const newErrors = {};

    // Check if any of the required fields are empty
    requiredFields.forEach((field) => {
      if (!panno) {
        newErrors.pan = 'PAN is required';
      } else {
        newErrors.pan = '';
      }

      if (!panimgbase64) {
        newErrors.image = 'Image is required';
      } else {
        newErrors.image = '';
      }
    });

    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error !== '')) {
      return;
    }


    try {
      setIsSubmitting(true)
      await ApiService.post(Endpoints.pancard, {
        pan: panno,
        image: panimgbase64,
        t: tname
      }).then((response) => {
        setIsSubmitting(false)
        const responseData = response.data;
        setMessage(responseData ? responseData.message : 'No message available');

        if (responseData?.status === true) {

          if (responseData?.data?.show_model === 1) {
            setResPg(response.data?.data?.pg)
            setResT(response.data?.data?.t)
            setOpen(true);
          }
          toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setIsSubmitting(false)
          toast.error(responseData ? responseData.message : 'No message available', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        if (responseData?.status === false) {
          toast.error(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });

    } catch (error) {
      setIsSubmitting(false)
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });

    };
  }
  const methods = useForm({});
  const {
    setValue,
  } = methods;



  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (!file) {
        console.error('No file selected');
        return;
      }
      const allowedFormats = ['.jpeg', '.jpg', '.png'];
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (!allowedFormats.includes(`.${fileExtension}`)) {
        console.error(`Invalid file format. Allowed formats: ${allowedFormats.join(', ')}`);
        toast.error(`Invalid file format. Allowed formats: ${allowedFormats.join(', ')}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        if (!base64String) {
          console.error('Failed to read file');
          return;
        }
        setPanimgBase64(base64String);
        const newFile = Object.assign(file, {
          preview: URL.createObjectURL(file),
          base64: base64String,
        });
        setValue('image', newFile, { shouldValidate: true });
      };
      reader.onerror = (error) => {
        console.error('Error reading the file:', error);
      };
      reader.readAsDataURL(file);
    },
    [setValue, setPanimgBase64]
  );
  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
      <Typography variant="h4">Pancard Verification</Typography>
    </Stack>
  );
  const renderTerms = (
    <Typography
      component="div"
      sx={{
        color: 'text.secondary',
        mt: 2.5,
        typography: 'caption',
        textAlign: 'center',
      }}
    >
      {'By signing up, I agree to '}
      <Link underline="always" color="text.primary">
        Terms of Service
      </Link>
      {' and '}
      <Link underline="always" color="text.primary">
        Privacy Policy
      </Link>
      .
    </Typography>
  );

  const renderForm = (
    <FormProvider methods={methods} onSubmit={handleSubmit}>
      <Stack spacing={2.5}>
        <RHFTextField name="pan" label="Pancard Number" onChange={(e) => { setPanno(e.target.value) }} value={panno || ''} />
        <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.pan}</p>

        <Stack direction="column" spacing={0.5}>
          <Typography variant="body2"> Pancard Image </Typography>
          <RHFUploadAvatar
            name="image"
            maxSize={2097152}
            onDrop={handleDrop}
            helperText={
              <Typography
                variant="caption"
                sx={{
                  mt: 3,
                  mx: 'auto',
                  display: 'block',
                  textAlign: 'center',
                  color: 'text.disabled',
                }}
              >
                Allowed *.jpeg, *.jpg, *.png
              </Typography>
            }
          />
            <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.image}</p>

        </Stack>
        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Next
        </LoadingButton>
      </Stack>
    </FormProvider>
  );

  return (
    <>
      {renderHead}

      {renderForm}

      {renderTerms}
      <Dialog open={open} onClose={handleClose} className='add_dialog'>
        <form>
          <DialogTitle>Pancard Verification</DialogTitle>
          <DialogContent>
            <Typography
              variant="caption"
              sx={{
                mt: 0,
                mx: 'auto',
                fontSize: '18px',
                display: 'block',
                textAlign: 'center',
                color: '#00a76f',
                fontWeight: '600'
              }}
            >
              {message}
            </Typography>
          </DialogContent>
          <DialogActions>
            <LoadingButton onClick={handleSave} variant="contained" type="button" loading={isSubmitting} sx={{
              mx: 'auto'
            }}>
              OK
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}


PG_2.propTypes = {
  params: PropTypes.object.isRequired,
  onNextStep: PropTypes.func.isRequired,
};
