import { useState } from 'react';
import PropTypes from 'prop-types';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Switch, Button, MenuItem, Popover, Drawer, Typography, Divider, IconButton } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Stack } from '@mui/system';
import Label from 'src/components/label';
import Scrollbar from 'src/components/scrollbar';

// ----------------------------------------------------------------------

export default function AllLeadTable({ row, selected, onEditRow, permissionsData }) {
  // const { id, name, } = row;

  const format = moment(row.createdAt).format('D-MM-YYYY, h:mm:ss a')

  return (
    <TableRow hover selected={selected}>
      <TableCell sx={{}}>
        278
      </TableCell>
      <TableCell sx={{ px: 1 }}>
        Vadtya Sunil (7446)
      </TableCell>
      <TableCell sx={{ px: 1 }}>
        Vadtya Sunil (7446)
      </TableCell>
      <TableCell sx={{ px: 1 }}>
        Address - Kallahalli Thanda Hanasi H B Halli Vijayanagara 583135
        State - KARNATAKA
      </TableCell>
      <TableCell sx={{ px: 1, }}>
        Category - Housing Loan
        Date - 2022-04-22
        Remarks - Hello
      </TableCell>
      <TableCell sx={{ px: 1, }}>
        Reason
      </TableCell>
      <TableCell sx={{ px: 1, }}>
        <Label
          variant="soft"
          color={
            (row.status === 'initiated' && 'warning') ||
            (row.status === 'success' && 'success') ||
            (row.status === 'pending' && 'warning') ||
            (row.status === 'failed' && 'error') ||
            (row.status === 'complete' && 'success') ||
            'default'
          }
        >
          {row.status}
        </Label>
      </TableCell>
    </TableRow >
  );
}

AllLeadTable.propTypes = {
  onEditRow: PropTypes.func,
  permissionsData: PropTypes.object,
  row: PropTypes.object,
  selected: PropTypes.bool,
};
