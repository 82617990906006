'use client';

/* eslint-disable import/no-extraneous-dependencies */

// import * as XLSX from 'xlsx';
// import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import React, { useState, useEffect, useCallback } from 'react';

import { Stack } from '@mui/system';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import { DatePicker } from '@mui/x-date-pickers';
import TableContainer from '@mui/material/TableContainer';
import { Grid, Dialog, TextField, DialogTitle, FormControl, DialogActions, DialogContent, InputAdornment, Autocomplete, CircularProgress } from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import FormProvider from 'src/components/hook-form/form-provider';
import {
  useTable,
  getComparator,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';
import ChangeHistoryTable from './changehistory-table';

// import { notFound } from 'next/navigation';

// const STATUS_OPTIONS = [{ value: 'all', label: 'All' }, ...USER_STATUS_OPTIONS];

const TABLE_HEAD = [
  { id: 'id', label: 'Id', width: '100px' },
  { id: 'user', label: 'User Details' },
  { id: 'updatedby', label: 'Updated By' },
  { id: 'type', label: 'Type' },
  { id: 'local', label: 'Local Details' },
  { id: 'data', label: 'Data' }
];


// ----------------------------------------------------------------------

export default function ChangeHistoryData() {
  const table = useTable();

  const settings = useSettingsContext();

  const router = useRouter();

  const [tableData, setTableData] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);


  const methods = useForm();
  // const [formData, setFormData] = useState({
  //   role_name: '',
  //   display_name: '',
  // });

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData, ...formData,
  //     [name]: value,
  //   }));
  // };

  const getTableData = async () => {
    setIsSubmitting(true)
    try {
      ApiService.post(Endpoints.changehistoryget).then((response) => {
        const responseData = response.data
        console.log(response.data);
       
        setTableData(responseData.data);
        setIsSubmitting(false)

      });
    } catch (error) {
      setIsSubmitting(false)
      console.error("Error submitting form:", error);
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  useEffect(() => {
    getTableData()
  }, []);


  // const onSubmit = async (data) => {
  //   console.log(data);

  //   try {
  //     setIsSubmitting(true)
  //     await ApiService.post(Endpoints.createrole, formData).then(async (response) => {
  //       const responseData = response.data
  //       if (responseData?.status === true) {
  //         console.log(response.data);
  //         await getTableData()
  //         toast.success("Success !", {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //         setIsSubmitting(false)
  //       } else {
  //         toast.error(responseData.message, {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //         setIsSubmitting(false)
  //       }
  //     });
  //   } catch (error) {
  //     setIsSubmitting(false)
  //     console.error("Error submitting form:", error);
  //     toast.error("Error submitting form", {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //   }


  //   // handleSave();
  // };

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
  });



  const handleEditRow = useCallback(
    (id) => {
      // router.push(paths.dashboard.user.edit(id));
      router.push(`${paths.fund.transferreturn}`);
    },
    [router]
  );
  const [data, setData] = useState([]); // State to store the fetched data
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    name: '',
    agentId: '',
  });


  const handleSearch = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const handleSearchButtonClick = () => {
    // Call your API or fetch data based on the filters
    getTableData(filters).then((response) => {
      setData(response.data); // Assuming your API response has a 'data' property
    });
  };

  const options = [
    { value: 1, label: 'Success' },
    { value: 2, label: 'Pending' },
    { value: 3, label: 'Failed' },
    { value: 4, label: 'Approved' },
    { value: 5, label: 'Rejected' },
  ]

  const [selectedStatus, setSelectedStatus] = useState(['']);

  const handleStatusChange = (event, newValue) => {
    setSelectedStatus(newValue);
  };

  const type = [
    { value: 1, label: 'Transfer' },
    { value: 2, label: 'Return' },
    { value: 3, label: 'Request' },
  ]

  const [selectedType, setSelectedType] = useState(['']);

  const handleTypeChange = (event, newValue) => {
    setSelectedType(newValue);
  };

  // export data
// const exportToExcel = () => {
//   const fileType =
//     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
//   const fileExtension = '.xlsx';

//   try {
//     const ws = XLSX.utils.json_to_sheet(tableData);
//     const wb = { Sheets: { data: ws }, SheetNames: ['aepsdata'] };
//     const excelBuffer = XLSX.write(wb, {
//       bookType: 'xlsx',
//       type: 'array',
//     });
//     const data1 = new Blob([excelBuffer], { type: fileType });
//     saveAs(data1, `table_data${fileExtension}`);
//   } catch (error) {
//     console.error('Error exporting to Excel:', error);
//   }
// };

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading="Change History"
        links={[
          { name: 'Dashboard', href: paths.dashboard.root },
          { name: 'Transaction History' },
          { name: 'Change History' },
        ]}
        sx={{ mb: { xs: 3, md: 5 } }}
      />
      <Card>

        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <Scrollbar>
            <Table>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
                numSelected={table.selected.length}
                onSort={table.onSort}
              />
              {isSubmitting ? (<CircularProgress style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto' }} size={30} />
              ) : (
                <TableBody>
                  {tableData
                    .slice(
                      table.page * table.rowsPerPage,
                      table.page * table.rowsPerPage + table.rowsPerPage
                    )
                    .map((row) => (
                      <ChangeHistoryTable
                        key={row.id}
                        row={row}
                        selected={table.selected.includes(row.id)}
                        onSelectRow={() => table.onSelectRow(row.id)}
                        onEditRow={() => handleEditRow(row.id)}
                        getData={getTableData}
                      />
                    ))}
                </TableBody>
              )}
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={dataFiltered.length}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
        />
      </Card>
    </Container>

  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator, filters }) {
  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);


  return inputData;
}
