import * as Yup from 'yup';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import FormProvider, {
  RHFSwitch,
  RHFTextField,
  RHFUploadAvatar,
  RHFAutocomplete,
} from 'src/components/hook-form';
import { Autocomplete, FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';
import { toast } from 'react-toastify';
import { useAuthContext } from 'src/auth/hooks';
import { useParams } from 'src/routes/hooks';
import Iconify from 'src/components/iconify';
import ChangeIconData from 'src/sections/setup-tools/portal-setting/changeicon-data';

// ----------------------------------------------------------------------

export default function MappingManager() {
  const [apitableData, setApiTableData] = useState([]);
  const { user } = useAuthContext();

  const methods = useForm({});
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { id } = useParams();
  const {
    setValue,
    handleSubmit,
  } = methods;
  const [changetype, setSetChangetype] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedApi, setSelectedApi] = useState(null);

  const handleOpen = (type) => {
    setOpen(true);
    setSetChangetype(type)

  };
  const onSubmit = handleSubmit(async (data) => {
    try {
      setIsSubmitting(true)
      await ApiService.post(Endpoints.memberprofile.mappingmanagerupdate, {
        parent_id: selectedApi?.value,
        member_id: id,
      }).then((response) => {
        const responseData = response.data
        if (responseData?.status === true) {
          toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          setIsSubmitting(false)
        } else {
          setIsSubmitting(false)
          console.log('error', response.data);
        }
      });
    } catch (error) {
      setIsSubmitting(false)
      console.error("Error submitting form:", error);
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  });


  // const [selectedApi, setSelectedApi] = React.useState(filterData(apitableData,user?.parent_id));

  const handleApiChange = (event, newValue) => {
    // if (newValue?.value) {
    setSelectedApi(newValue);
    // }
  };
  const getApiData = async () => {
    try {
      ApiService.post(Endpoints.memberprofile.mappingmanagerget, { member_id: id }).then(async (response) => {
        const responseData = response.data;
        if (responseData?.status === true) {
          const apidata = responseData.data

          await getManagerData(apidata);

        }
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Error submitting form', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const getManagerData = async (data) => {
    try {
      ApiService.post(Endpoints.memberprofile.getmembermanager, { member_id: id }).then((response) => {
        const responseData = response.data;
        const apidata = responseData.data

        const selectedDaata = data.find((item) => item.value === apidata.parent_id)
        setApiTableData(data);

        setSelectedApi(selectedDaata);
        console.log('apidata', selectedDaata)
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Error submitting form', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  useEffect(() => {
    getApiData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      {/* {isDataLoading === false ? */}
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Grid container spacing={3}>
          <Grid xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Box
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <FormControl
                  sx={{
                    flexShrink: 0,
                    width: '100%',
                  }}
                >


                  {selectedApi !== null ? <Autocomplete
                    value={selectedApi}
                    onChange={handleApiChange}
                    options={apitableData}
                    getOptionLabel={(option) => (option.label !== undefined ? option.label : '')}
                    renderInput={(params) => (
                      <TextField {...params} label="Mapping Manager" variant="outlined" />
                    )}
                  /> : null}

                </FormControl>

                <IconButton style={{ color: '#000', marginLeft: 'auto' }} onClick={() => { handleOpen('MAPPING_MANAGER') }}>
                  <Iconify icon="typcn:info" />
                </IconButton>
              </Box>

              <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  Change
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
      {open === true ? <ChangeIconData open={open} setOpen={setOpen} type={changetype} member_id={id} /> : null}

      {/* : null} */}
    </>
  );
  function filterData(data, id1) {
    const datas = data?.find((item) => item.value === id);
    return datas;
  }
}
