import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { memo, useState, useCallback } from 'react';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Dialog, { dialogClasses } from '@mui/material/Dialog';

import { useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { useEventListener } from 'src/hooks/use-event-listener';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import Constants from 'src/common/constant';
import SearchNotFound from 'src/components/search-not-found';
import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

import { useAuthContext } from 'src/auth/hooks';
import FormProvider from 'src/components/hook-form/form-provider';
import { Autocomplete, Button, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from '@mui/material';
import { RHFTextField } from 'src/components/hook-form';
import { LoadingButton } from '@mui/lab';
import ResultItem from './result-item';
import { useNavData } from '../../dashboard/config-navigation';
import { applyFilter, groupedData, getAllItems } from './utils';

// ----------------------------------------------------------------------

function Searchbar() {
  const theme = useTheme();

  const router = useRouter();

  const search = useBoolean();
  const { user } = useAuthContext();

  const lgUp = useResponsive('up', 'lg');

  const [searchQuery, setSearchQuery] = useState('');
  const [isSubmitting, setIsSubmitting] = useState('');
  const [open, setOpen] = useState(false);
  const navData = useNavData();

  const handleClose = useCallback(() => {
    setOpen(false);

    search.onFalse();
    setSearchQuery('');
  }, [search]);

  const handleKeyDown = (event) => {
    if (event.key === 'k' && event.metaKey) {
      search.onToggle();
      setSearchQuery('');
    }
  };

  useEventListener('keydown', handleKeyDown);

  const handleClick = useCallback(
    (path) => {
      if (path.includes('http')) {
        window.open(path);
      } else {
        router.push(path);
      }
      handleClose();
    },
    [handleClose, router]
  );

  const handleSearch = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, []);

  const dataFiltered = applyFilter({
    inputData: getAllItems({ data: navData }),
    query: searchQuery,
  });

  const notFound = searchQuery && !dataFiltered.length;

  const [formData, setFormData] = useState({
    amount: '',
    remark: '',
    type: '',
  });

  const handleInputChange = (e, newInputValue) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      type: newInputValue,
    }));
  };

  const [selectedStatus, setSelectedStatus] = useState(null);

  const handleStatusChange = (event, newValue) => {
    setSelectedStatus(newValue);
    setFormData((prevData) => ({
      ...prevData,
      type: newValue ? newValue.value : '',
    }));
  };


  const methods = useForm({});

  const {
    handleSubmit,
  } = methods;

  const handleFundTransfer = () => {
    setOpen(true);
  }

  const onSubmit = handleSubmit(async (data) => {
    console.log('formData', formData)
    try {
      setIsSubmitting(true)
      await ApiService.post(Endpoints.fundtransfer.adminfundtransfer, { ...formData, type: selectedStatus?.value}).then(async (response) => {
        const responseData = response.data
        if (responseData?.status === true) {
          toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
          setOpen(false)
        } else {
          toast.error(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
        }
      });
    } catch (error) {
      setIsSubmitting(false)
      console.error("Error submitting form:", error);
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  });

  const options = [
    { value: 'transfer', label: 'Tranfer' },
    { value: 'return', label: 'Return' },
  ]


  const renderItems = () => {
    const data = groupedData(dataFiltered);

    return Object.keys(data)
      .sort((a, b) => -b.localeCompare(a))
      .map((group, index) => (
        <List key={group || index} disablePadding>
          {data[group].map((item) => {
            const { title, path } = item;

            const partsTitle = parse(title, match(title, searchQuery));

            const partsPath = parse(path, match(path, searchQuery));

            return (
              <ResultItem
                path={partsPath}
                title={partsTitle}
                key={`${title}${path}`}
                groupLabel={searchQuery && group}
                onClickItem={() => handleClick(path)}
              />
            );
          })}
        </List>
      ));
  };

  const renderButton = (
    <Stack direction="row" alignItems="center">
      {/* <IconButton onClick={search.onTrue}>
        <Iconify icon="eva:search-fill" />
      </IconButton> */}
      Main Wallet: <p style={{ margin: 0, color: '#00A76F', paddingLeft: '5px', fontWeight: '700', fontSize: '18px' }}> {`${user?.main_wallet}`}</p>
      {(user?.member_type === Constants.ROLE.ADMIN) === true ?
        <p style={{ margin: 0, paddingLeft: '30px', display: 'flex', alignItems: 'center' }}>Total Wallet: <p style={{ margin: 0, color: '#00A76F', paddingLeft: '5px', fontWeight: '700', fontSize: '18px' }}>{`${user?.totalMainWalletForAllUsers}`}</p></p>
        : null}
      {(user?.member_type === Constants.ROLE.ADMIN) === true ?
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <Button style={{ margin: 0, marginLeft: '30px', display: 'flex', alignItems: 'center', fontSize:'16px',fontWeight:'400' }} onClick={handleFundTransfer} variant="contained">Load Wallet</Button>
        : null}
      {/* {lgUp && <Label sx={{ px: 0.75, fontSize: 12, color: 'text.secondary' }}>⌘K</Label>} */}
    </Stack>
  );

  return (
    <>
      {renderButton}
      <Dialog
        fullWidth
        maxWidth={false}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: { maxWidth: 720 },
        }}
      >
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <DialogTitle>Fund Transfer / Return</DialogTitle>

          <DialogContent className='mt-2'>

            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <FormControl
                sx={{
                  flexShrink: 0,
                }}
              >
                <Autocomplete
                  name="type"
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  options={options}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField {...params} label="Fund Action" variant="outlined" />
                  )}
                />
              </FormControl>

              <RHFTextField name="amount" label="Amount" onChange={handleInputChange}
                value={formData.amount || ''} />
              <RHFTextField name="remark" label="Remark" onChange={handleInputChange}
                value={formData.remark || ''} />

            </Box>
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              Close
            </Button>

            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              Submit
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
      {/* <Dialog
        fullWidth
        maxWidth="sm"
        open={search.value}
        onClose={handleClose}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: 0,
        }}
        PaperProps={{
          sx: {
            mt: 15,
            overflow: 'unset',
          },
        }}
        sx={{
          [`& .${dialogClasses.container}`]: {
            alignItems: 'flex-start',
          },
        }}
      >
        <Box sx={{ p: 3, borderBottom: `solid 1px ${theme.palette.divider}` }}>
          <InputBase
            fullWidth
            autoFocus
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" width={24} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
            endAdornment={<Label sx={{ letterSpacing: 1, color: 'text.secondary' }}>esc</Label>}
            inputProps={{
              sx: { typography: 'h6' },
            }}
          />
        </Box>

        <Scrollbar sx={{ p: 3, pt: 2, height: 400 }}>
          {notFound ? <SearchNotFound query={searchQuery} sx={{ py: 10 }} /> : renderItems()}
        </Scrollbar>
      </Dialog> */}
    </>
  );
}

export default memo(Searchbar);
