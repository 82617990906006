'use client'

const Constants = {
  ROLE: {
    ADMIN: 1,
    WHITELABEL: 2,
    MASTER_DISTRIBUTOR: 3,
    DISTRIBUTOR: 4,
    RETAILER: 5,
  }

};


export const UsersPermissions = {
  aepsfund: {
    AepsSettlementRequest: 49,
    MatmSettlementRequest: 63,
  },
  aepsfundreport: {
    AepsSettlementRequest: 50,
    AepsSettlementReport: 51,
    MatmSettlementRequest: 64,
    MatmSettlementReport: 65,
    HmtSettlementRequest: 115,
    HmtSettlementReport: 114,
  },
  complaints: {
    ComplaintsSubjectManager: 38,
    ComplaintsManager: 39,
    ComplaintsSubmission: 40,
  },
  creditcard: {
    CreditCardLead: 128,
  },
  walletfund: {
    FundTransferAction: 9,
    FundReturnAction: 10,
    WalletLoadRequest: 11,
    PaytmLoadMainWallet: 119,
    CashFreeLoadMainWallet: 121,
  },
  walletfundreport: {
    WaletPaymentsReport: 15,
  },
  agentsList: {
    UtiAgentList: 18,
    AepsAgentList: 56,
    MatmAgentList: 89,

  },
  member: {
    CreateRetailer: 6,
    CreateDistributore: 5,
    CreateMasterDistributore: 4,
    CreateWhitelabel: 9,
    ManageMasterDistributor:1,
    ManageDistributor:2,
    ManageRetailer:3,
  },
  membersetting: {
    member_profile_edit: 7,
    member_password_reset: 8,
    member_stock_manager: 54,
    installed_applications: 103,
    member_permission: 118,
    onboarding_pending_status: 122
  },
  memberreport:{
    BillPaymentReport:41,
    RechargeReport:42,
    MoneyTransferReport:43,
    UtiPancardReport:44,
    UtiIdReport:45,
    MainWalletStatement:46,
    AepsReport:48,
    AepsWalletStatement:53,
    MemberAepsAgentList:62,
    MatmReport:87,
    MemberMatmAgentList:90,
    MatmWalletSatement:92,
    CashDepositReport:105,
    LedgerStatement:131,
    PaytmAepsReport:135,
    FAepsReport:136,
  },
  transactions:{
   RechargeStatement:16,
   BillPaymentStatement:17,
   UtiPancardReport:19,
   MoneyTransferStatement:20,
   AepsStatement:47,
   MatmStatement:88,
   CashDepositStatement:104,
   PaytmDepositStatement:116,
   MobikwikDepositStatement:117,
   FaepsStatement:127,
   PaytmAepsStatement:137,
  },
  transactionsediting:{
    UtiVleIdReportEditing:29,
    UtiPancardReportEditing:30,
    BillPayReportEditing:31,
    RechargeReportEditing:32,
    MoneyTransferReportEditing:33,
    AepsIdStatementEditing:58,
    AepsStatementEditing:59,
    MatmIdStatementEditing:93,
    MatmStatementStatus:94,
    MatmStatementEditing:95,
  },
  transactionstatus:{
    RechargeStatus:35,
    BillPaymentStatus:36,
    UtiPancardStatus:37,
    MoneyStatementStatus:60,
    AepsStatementStatus:61,
  },
  resources:{
    ChangeCompanyProfile:34,
    ViewCommision:55,
    AddBanner:67,
  },
  portalservices:{
    RechargeService:24,
    BillPaymentService:25,
    UtiPancardService:26,
    IMoney:27,
    IAepsService:28,
    CashDeposit:66,
    FAepsService:96,
    AadharpayService:97,
    MatmService:98,
    DmtBank1:99,
    DmtBank2:100,
    DmtBank3:101,
    DmtBenificiaryDelete:102,
    FixedDeposit:108,
    FixedDepositWithdraw:109,
    PaytmDeposit:110,
    MobikwikDeposit:111,
    LeadsService:112,
    HmtService:113,
    NsdlPancardService:126,
    PaytmAepsService:134,
  },
  usersetting:{
    AccountStatementView:12,
    ProfileEdit:13,
    PasswordManager:14,
    AepsWalletStatement:52,
    MatmWalletStatement:91,
    TdsSertificate:106,
    MultipleBankAdd:123,
    BusBooking:129,
    LedgerStatement:130,
  },
  setuptools:{
    ApiManager:21,
    BankAccountSetup:22,
    OperatorManager:23,
    SchemeManager:120,
    AxisBankSavingAccount:124,
    AxisBankCurrentAccount:125,
  }
}

export default Constants;
