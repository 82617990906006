'use client';

/* eslint-disable import/no-extraneous-dependencies */

import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import React, { useState, useEffect, useCallback } from 'react';

import { Box, Stack } from '@mui/system';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import { DatePicker } from '@mui/x-date-pickers';

import TableContainer from '@mui/material/TableContainer';
import { Grid, Dialog, TextField, DialogTitle, FormControl, DialogActions, DialogContent, InputAdornment, Autocomplete, CircularProgress, InputLabel, Select, MenuItem, OutlinedInput, IconButton } from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import FormProvider from 'src/components/hook-form/form-provider';
import {
  useTable,
  getComparator,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';
import Label from 'src/components/label';
import ChangeIconData from './changeicon-data';




// ----------------------------------------------------------------------

export default function PortalSettingData() {
  const table = useTable();

  const settings = useSettingsContext();

  const router = useRouter();

  const [tableData, setTableData] = useState([]);
  const [apitableData, setApiTableData] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitting1, setIsSubmitting1] = useState(false);

  const [portalValue, setPortalValue] = useState([])
  const [changetype, setSetChangetype] = useState([])

  const [open, setOpen] = useState(false);
  const handleOpen = (type) => {
    setOpen(true);

    setSetChangetype(type)

  };

  const handleClose = () => {
    setOpen(false);
  };

  const methods = useForm();

  const getApiData = async () => {
    setIsSubmitting1(true)
    try {
      ApiService.post(Endpoints.portalsetting.get).then((response) => {
        const responseData = response.data
        // console.log('responseData', responseData)
        if (responseData?.status === true) {
          setTableData(responseData?.data);
          setIsSubmitting1(false)
        }
      });
    } catch (error) {
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }


  useEffect(() => {
    getApiData();
  }, []);


  const handleChange = (code, value) => {
    setPortalValue((prevPortalValue) => {
      const updatedValue = { code, value };
      const existingIndex = prevPortalValue.findIndex((item) => item.code === code);

      if (existingIndex !== -1) {
        const updatedPortalValue = [...prevPortalValue];
        updatedPortalValue[existingIndex] = updatedValue;
        return updatedPortalValue;
      }

      return [...prevPortalValue, updatedValue];
    });
  };


  const handleSubmit = async (code) => {

    if (portalValue?.find((item) => (item?.code === code))) {
      try {
        setIsSubmitting(true)
        const response = await ApiService.post(Endpoints.portalsetting.update, {
          code,
          value: (portalValue?.find((item) => (item?.code === code)))?.value
        });
        const responseData = response.data;
        if (responseData?.status === true) {
          setIsSubmitting(false)
        } else {
          toast.error(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        setIsSubmitting(false)
        toast.error('Error submitting form', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };


  const types = [
    {
      "value": "auto",
      "name": "Auto"
    },
    {
      "value": "manual",
      "name": "Manual"
    },
  ]

  return (

      <Container maxWidth={settings.themeStretch ? false : 'lg'}>

        <CustomBreadcrumbs
          heading="Portal Settings"
          links={[
            { name: 'Dashboard', href: paths.dashboard.root },
            { name: 'Setup Tools' },
            { name: 'Portal Settings' },
          ]}

          sx={{ mb: { xs: 3, md: 5 } }}
        />

        {isSubmitting1 === true ? null : <Grid container spacing={3}>
          <Grid xs={12} md={4} item >
            <Card>
              {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}> */}
              <Label color='success' variant="soft" sx={{ minWidth: '100%', height: '40px', fontSize: 14, justifyContent: 'flex-start', paddingLeft: '14px' }}>
                Registration Status
                <IconButton style={{ color: '#000', marginLeft: 'auto' }} onClick={()=>{handleOpen('registrationtype')}}>
                  <Iconify icon="typcn:info" />
                </IconButton>
              </Label>

              {/* </div> */}



              <br /><br />
              <div style={{ padding: '10px' }}>
                <FormControl fullWidth >
                  <InputLabel>Registration Type</InputLabel>

                  <Select
                    name="registrationtype"
                    value={(portalValue?.find((item) => (item?.code === 'registrationtype'))) ?
                      (portalValue?.find((item) => (item?.code === 'registrationtype')))?.value :
                      (tableData?.find((item) => (item?.code === 'registrationtype')))?.value}
                    onChange={(e) => { handleChange('registrationtype', e.target.value) }}
                    input={<OutlinedInput label="Registration Type" />}
                    renderValue={(selected) => {
                      const selectedBank = types.find((type) => type.value === selected);
                      return selectedBank ? selectedBank.name : '';
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 240,
                          maxWidth: '100%',
                        },
                      },
                    }}
                  >
                    {types.map((type) => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl><br />
              </div>
              <Box sx={{ mt: 1, textAlign: 'end', mb: 2, mr: 2 }}>
                <LoadingButton onClick={(e) => { handleSubmit('registrationtype') }} variant="contained" loading={isSubmitting}>
                  Update Info
                </LoadingButton>
              </Box>
            </Card>
          </Grid>
          <Grid xs={12} md={4} item >
            <Card>
              <Label color='success' variant="soft" sx={{ minWidth: '100%', height: '40px', fontSize: 14, justifyContent: 'flex-start', paddingLeft: '14px' }}>
                AEPS NEFT Settlement Type
                <IconButton style={{ color: '#000', marginLeft: 'auto' }} onClick={()=>{handleOpen('neftsettlementtype')}}>
                  <Iconify icon="typcn:info" />
                </IconButton>
              </Label><br /><br />
              <div style={{ padding: '10px' }}>
                <FormControl fullWidth >
                  <InputLabel>AEPS NEFT Settlement Type</InputLabel>

                  <Select
                    name="neftsettlementtype"
                    value={(portalValue?.find((item) => (item?.code === 'neftsettlementtype'))) ?
                      (portalValue?.find((item) => (item?.code === 'neftsettlementtype')))?.value :
                      (tableData?.find((item) => (item?.code === 'neftsettlementtype')))?.value}
                    onChange={(e) => { handleChange('neftsettlementtype', e.target.value) }}
                    input={<OutlinedInput label="AEPS NEFT Settlement Type" />}
                    renderValue={(selected) => {
                      const selectedBank = types.find((type) => type.value === selected);
                      return selectedBank ? selectedBank.name : '';
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 240,
                          maxWidth: '100%',
                        },
                      },
                    }}
                  >
                    {types.map((type) => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl><br />

              </div>
              <Box sx={{ mt: 1, textAlign: 'end', mb: 2, mr: 2 }}>
                <LoadingButton onClick={(e) => { handleSubmit('neftsettlementtype') }} variant="contained" loading={isSubmitting}>
                  Update Info
                </LoadingButton>
              </Box>

            </Card>
          </Grid>
          <Grid xs={12} md={4} item >
            <Card>
              <Label color='success' variant="soft" sx={{ minWidth: '100%', height: '40px', fontSize: 14, justifyContent: 'flex-start', paddingLeft: '14px' }}>
                AEPS IMPS Settlement Type
                <IconButton style={{ color: '#000', marginLeft: 'auto' }} onClick={()=>{handleOpen('impssettlementtype')}}>
                  <Iconify icon="typcn:info" />
                </IconButton>
              </Label><br /><br />
              <div style={{ padding: '10px' }}>
                <FormControl fullWidth >
                  <InputLabel>AEPS IMPS Settlement Type</InputLabel>

                  <Select
                    name="impssettlementtype"
                    value={(portalValue?.find((item) => (item?.code === 'impssettlementtype'))) ?
                      (portalValue?.find((item) => (item?.code === 'impssettlementtype')))?.value :
                      (tableData?.find((item) => (item?.code === 'impssettlementtype')))?.value}
                    onChange={(e) => { handleChange('impssettlementtype', e.target.value) }}
                    input={<OutlinedInput label="AEPS IMPS Settlement Type" />}
                    renderValue={(selected) => {
                      const selectedBank = types.find((type) => type.value === selected);
                      return selectedBank ? selectedBank.name : '';
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 240,
                          maxWidth: '100%',
                        },
                      },
                    }}
                  >
                    {types.map((type) => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl><br />

              </div>
              <Box sx={{ mt: 1, textAlign: 'end', mb: 2, mr: 2 }}>
                <LoadingButton onClick={(e) => { handleSubmit('impssettlementtype') }} variant="contained" loading={isSubmitting}>
                  Update Info

                </LoadingButton>
              </Box>

            </Card>
          </Grid>
          <Grid xs={12} md={4} item >
            <Card>
              <Label color='success' variant="soft" sx={{ minWidth: '100%', height: '40px', fontSize: 14, justifyContent: 'flex-start', paddingLeft: '14px' }}>
                IMPS1 Charge
                <IconButton style={{ color: '#000', marginLeft: 'auto' }} onClick={()=>{handleOpen('imps1')}}>
                  <Iconify icon="typcn:info" />
                </IconButton>
              </Label><br /><br />
              <div style={{ padding: '10px' }}>
                <TextField label="IMPS1 Charge" name='imps1' value={(portalValue?.find((item) => (item?.code === 'imps1'))) ?
                  (portalValue?.find((item) => (item?.code === 'imps1')))?.value :
                  (tableData?.find((item) => (item?.code === 'imps1')))?.value}
                  onChange={(e) => { handleChange('imps1', e.target.value) }} />


              </div>
              <Box sx={{ mt: 1, textAlign: 'end', mb: 2, mr: 2 }}>
                <LoadingButton onClick={(e) => { handleSubmit('imps1') }} variant="contained" loading={isSubmitting}>
                  Update Info
                </LoadingButton>
              </Box>

            </Card>
          </Grid>
          <Grid xs={12} md={4} item >
            <Card>
              <Label color='success' variant="soft" sx={{ minWidth: '100%', height: '40px', fontSize: 14, justifyContent: 'flex-start', paddingLeft: '14px' }}>
                IMPS2 Charge <IconButton style={{ color: '#000', marginLeft: 'auto' }} onClick={()=>{handleOpen('imps2')}}>
                  <Iconify icon="typcn:info" />
                </IconButton>
              </Label><br /><br />
              <div style={{ padding: '10px' }}>
                <TextField label="IMPS2 Charge" name='imps2' value={(portalValue?.find((item) => (item?.code === 'imps2'))) ?
                  (portalValue?.find((item) => (item?.code === 'imps2')))?.value :
                  (tableData?.find((item) => (item?.code === 'imps2')))?.value}
                  onChange={(e) => { handleChange('imps2', e.target.value) }} />


              </div>
              <Box sx={{ mt: 1, textAlign: 'end', mb: 2, mr: 2 }}>
                <LoadingButton onClick={(e) => { handleSubmit('imps2') }} variant="contained" loading={isSubmitting}>
                  Update Info
                </LoadingButton>
              </Box>

            </Card>
          </Grid>
          <Grid xs={12} md={4} item >
            <Card>
              <Label color='success' variant="soft" sx={{ minWidth: '100%', height: '40px', fontSize: 14, justifyContent: 'flex-start', paddingLeft: '14px' }}>
                NEFT Charge <IconButton style={{ color: '#000', marginLeft: 'auto' }} onClick={()=>{handleOpen('neftcharge')}}>
                  <Iconify icon="typcn:info" />
                </IconButton>
              </Label><br /><br />
              <div style={{ padding: '10px' }}>
                <TextField label="NEFT Charge" name='neftcharge' value={(portalValue?.find((item) => (item?.code === 'neftcharge'))) ?
                  (portalValue?.find((item) => (item?.code === 'neftcharge')))?.value :
                  (tableData?.find((item) => (item?.code === 'neftcharge')))?.value}
                  onChange={(e) => { handleChange('neftcharge', e.target.value) }} />


              </div>
              <Box sx={{ mt: 1, textAlign: 'end', mb: 2, mr: 2 }}>
                <LoadingButton onClick={(e) => { handleSubmit('neftcharge') }} variant="contained" loading={isSubmitting}>
                  Update Info
                </LoadingButton>
              </Box>

            </Card>
          </Grid>
        </Grid>}

       {open===true?<ChangeIconData open={open} setOpen={setOpen} type={changetype} />:null}
      </Container>

  );
}

