import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { useMockedUser } from 'src/hooks/use-mocked-user';

import { fData } from 'src/utils/format-number';

import { countries } from 'src/assets/data';

import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFSwitch,
  RHFTextField,
  RHFUploadAvatar,
  RHFAutocomplete,
} from 'src/components/hook-form';
import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';
import { toast } from 'react-toastify';
import { useParams } from 'src/routes/hooks';

// ----------------------------------------------------------------------

export default function KYCDetails() {
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [aadharfrontimg, setAadharfrontimg] = useState(null);
  const [aadharbackimg, setAadharbackimg] = useState(null);
  const [panimg, setPanimg] = useState(null);

  const [isDataLoading, setIsDataLoading] = useState(true);
  const methods = useForm({});
  const { id } = useParams();
  const {
    handleSubmit,
    register,
    setValue,
  } = methods;

  const getProfile = async (e) => {
    try {
      setIsSubmitting(true)
      await ApiService.post(Endpoints.memberprofile.kycdetailsget,{member_id:id}).then(async (response) => {
        const responseData = response.data
        if (responseData?.status === true) {
          setValue('id', responseData.data.id || '');
          setValue('shop_name', responseData.data.shop_name || '');
          setValue('gst_no', responseData.data.gst_no || '');
          setValue('pan_no', responseData.data.pan_no || '');
          setValue('aadhar_no', responseData.data.aadhar_no || '');
          setValue('pan_image', responseData.data.pan_image || '');
          setValue('aadhar_front_image', responseData.data.aadhar_front_image || '');
          setValue('aadhar_back_image', responseData.data.aadhar_back_image || '');
          // toast.success(responseData.message, {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
          setIsSubmitting(false)
          setIsDataLoading(false)

        } else {
          toast.error(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
        }
      });
    } catch (error) {
      setIsSubmitting(false)
      console.error("Error submitting form:", error);
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  useEffect(() => {
    getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const onSubmit = handleSubmit(async (data,e) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      const response = await ApiService.post(Endpoints.memberprofile.kycdetailsupdate,{member_id:id,data});


      const responseData = response.data;
      if (responseData?.status === true) {
        toast.success(responseData.message, { position: toast.POSITION.TOP_RIGHT });
      } else {
        toast.error(responseData.message, { position: toast.POSITION.TOP_RIGHT });
      }

      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      console.error("Error submitting form:", error);
      toast.error("Error submitting form", { position: toast.POSITION.TOP_RIGHT });
    }
  });


  const handleDropFront = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (!file) {
        console.error('No file selected');
        return;
      }

      // Check if the file format is allowed
      const allowedFormats = ['.jpeg', '.jpg', '.png'];
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (!allowedFormats.includes(`.${fileExtension}`)) {
        console.error(`Invalid file format. Allowed formats: ${allowedFormats.join(', ')}`);
        toast.error(`Invalid file format. Allowed formats: ${allowedFormats.join(', ')}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }



      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;

        if (!base64String) {
          console.error('Failed to read file');

          return;
        }

        // Update comment
        setAadharfrontimg(base64String);

        const newFile = Object.assign(file, {
          preview: URL.createObjectURL(file),
          base64: base64String,
        });

        setValue('frontimg', newFile, { shouldValidate: true });
      };

      reader.onerror = (error) => {
        console.error('Error reading the file:', error);
        // You can provide user feedback here, e.g., display an error message
      };

      reader.readAsDataURL(file);
    },
    [setValue, setAadharfrontimg]
  );

  const handleDropBack = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (!file) {
        console.error('No file selected');
        return;
      }

      // Check if the file format is allowed
      const allowedFormats = ['.jpeg', '.jpg', '.png'];
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (!allowedFormats.includes(`.${fileExtension}`)) {
        console.error(`Invalid file format. Allowed formats: ${allowedFormats.join(', ')}`);
        toast.error(`Invalid file format. Allowed formats: ${allowedFormats.join(', ')}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;

        if (!base64String) {
          console.error('Failed to read file');
          return;
        }
        // Update comment
        setAadharbackimg(base64String);
        const newFile = Object.assign(file, {
          preview: URL.createObjectURL(file),
          base64: base64String,
        });
        setValue('backimg', newFile, { shouldValidate: true });
      };

      reader.onerror = (error) => {
        console.error('Error reading the file:', error);
        // You can provide user feedback here, e.g., display an error message
      };

      reader.readAsDataURL(file);
    },
    [setValue, setAadharbackimg]
  );

  const handlePancard = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file) {
      console.error('No file selected');
      return;
    }

    const allowedFormats = ['.jpeg', '.jpg', '.png'];
    const fileExtension = file.name.split('.').pop().toLowerCase();

    if (!allowedFormats.includes(`.${fileExtension}`)) {
      toast.error(`Invalid file format. Allowed formats: ${allowedFormats.join(', ')}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;

      if (!base64String) {
        console.error('Failed to read file');
        return;
      }
      // Update state with base64 string
      setPanimg(base64String);

      // Update form value
      // Assuming you have a function setValue in your component to handle form state
      // setValue('panimg', base64String, { shouldValidate: true });
    };
    reader.onerror = (error) => {
      console.error('Error reading the file:', error);
      // You can provide user feedback here, e.g., display an error message
    };

    reader.readAsDataURL(file);
  }, [setPanimg]);
  const handleInputChange = (name) => (e) => {
    setValue(name, e.target.value);
  };

  return (
    <>
      {isDataLoading === false ?
        <FormProvider methods={methods} onSubmit={onSubmit} >
          <Grid container spacing={3}>
            <Grid xs={12} md={12}>
              <Card sx={{ p: 3 }}>
                <Box
                  rowGap={3}
                  columnGap={2}
                  display="grid"
                  gridTemplateColumns={{
                    xs: 'repeat(1, 2fr)',
                    sm: 'repeat(2, 1fr)',
                  }}
                >
                  <RHFTextField name="shop_name" label="Shop Name" onChange={handleInputChange('shop_name')} />
                  <RHFTextField name="gst_no" label="GST Number" onChange={handleInputChange('gst_no')}/>
                  <RHFTextField name="pan_no" label="Pancard Number" onChange={handleInputChange('pan_no')} />
                  <RHFTextField name="aadhar_no" label="Aadharcard Number" onChange={handleInputChange('aadhar_no')} />
                </Box>

                <Stack direction="row" sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                  <RHFUploadAvatar
                    name="pan_image"
                    maxSize={3145728}
                    onDrop={handlePancard}
                    onChange={handleInputChange('pan_image')}
                    helperText={
                      <Typography
                        variant="caption"
                        sx={{
                          mt: 3,
                          mx: 'auto',
                          display: 'block',
                          textAlign: 'center',
                          color: 'text.disabled',
                        }}
                      >
                        Pancard Image
                      </Typography>
                    }
                  />
                  <RHFUploadAvatar
                    name="aadhar_front_image"
                    maxSize={3145728}
                    onDrop={handleDropFront}
                    helperText={
                      <Typography
                        variant="caption"
                        sx={{
                          mt: 3,
                          mx: 'auto',
                          display: 'block',
                          textAlign: 'center',
                          color: 'text.disabled',
                        }}
                      >
                        Aadharcard Front Image
                      </Typography>
                    }
                  />
                  <RHFUploadAvatar
                    name="aadhar_back_image"
                    maxSize={3145728}
                    onDrop={handleDropBack}
                    helperText={
                      <Typography
                        variant="caption"
                        sx={{
                          mt: 3,
                          mx: 'auto',
                          display: 'block',
                          textAlign: 'center',
                          color: 'text.disabled',
                        }}
                      >
                        Aadharcard Back Image
                      </Typography>
                    }
                  />
                </Stack>
                <span style={{ display: 'block', textAlign: 'center' }}>
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting} sx={{ mt: 3 }}>
                    Update Profile
                  </LoadingButton>
                </span>

              </Card>
            </Grid>
          </Grid>
        </FormProvider>
        : null}
    </>
  );
}
