'use client';

/* eslint-disable import/no-extraneous-dependencies */
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import React, { useState, useCallback, useEffect } from 'react';

import { Stack } from '@mui/system';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import { DatePicker } from '@mui/x-date-pickers';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import { Grid, Dialog, Select, MenuItem, TextField, InputLabel, DialogTitle, FormControl, DialogActions, DialogContent, OutlinedInput, InputAdornment, CircularProgress, Autocomplete, CardHeader, CardContent, Tabs, tabsClasses, Tab, Typography } from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter, useSearchParams } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';

import { _userList } from 'src/_mock';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import FormProvider from 'src/components/hook-form/form-provider';
import {
  useTable,
  getComparator,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';
import { toast } from 'react-toastify';
import { RHFUploadAvatar } from 'src/components/hook-form';
// import { notFound } from 'next/navigation';

// const STATUS_OPTIONS = [{ value: 'all', label: 'All' }, ...USER_STATUS_OPTIONS];

// ----------------------------------------------------------------------

export default function PanMemberData({onNextButtonClick}) {
  const table = useTable();

  const settings = useSettingsContext();

  const router = useRouter();
  const [apitableData, setApiTableData] = useState([]);

  const confirm = useBoolean();

  const [tableData, setTableData] = useState(_userList);
  const [resT, setResT] = useState("");
  const [resPg, setResPg] = useState("");
  const [open, setOpen] = useState(false);
  const [panno, setPanno] = useState('');
  const [message, setMessage] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const prms = useSearchParams();
  const tname = prms.get('t');

  const [panimgbase64, setPanimgBase64] = useState(null);



  const methods = useForm();
  const {
    setValue,
  } = methods;


  const handleClose = () => {
    setOpen(false);
  };

  const [errors, setErrors] = useState({
    pan: '',
    image: '',
  });

  const handleNext = async (e) => {
    e.preventDefault();  //
    const requiredFields = ['pan', 'image'];
    const newErrors = {};

    // Check if any of the required fields are empty
    requiredFields.forEach((field) => {
      if (!panno) {
        newErrors.pan = 'PAN is required';
      } else {
        newErrors.pan = '';
      }

      if (!panimgbase64) {
        newErrors.image = 'Image is required';
      } else {
        newErrors.image = '';
      }
    });

    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error !== '')) {
      return;
    }

    try {
      setIsSubmitting(true)
      await ApiService.post(Endpoints.membercreate.pg2, {
        pan: panno,
        image: panimgbase64,
        t: tname

      }).then((response) => {
        setIsSubmitting(false)
        const responseData = response.data;
        console.log('responseData', responseData)
        setMessage(responseData ? responseData.message : 'No message available');

        if (responseData?.status === true) {
          console.log(response.data);

          if (responseData?.data?.show_model === 1) {
            setResPg(response.data?.data?.pg)
            setResT(response.data?.data?.t)
            setOpen(true);
          }

        } else {
          setIsSubmitting(false)
          toast.error(responseData ? responseData.message : 'No message available', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });

    } catch (error) {
      setIsSubmitting(false)
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });

    };

  };

  const handleSave = () => {
    setIsSubmitting(true)
    onNextButtonClick(resT);
    // console.log('resT', resT)
  };


  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (!file) {
        console.error('No file selected');
        return;
      }
      const allowedFormats = ['.jpeg', '.jpg', '.png'];
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (!allowedFormats.includes(`.${fileExtension}`)) {
        console.error(`Invalid file format. Allowed formats: ${allowedFormats.join(', ')}`);
        toast.error(`Invalid file format. Allowed formats: ${allowedFormats.join(', ')}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        if (!base64String) {
          console.error('Failed to read file');
          return;
        }
        setPanimgBase64(base64String);
        const newFile = Object.assign(file, {
          preview: URL.createObjectURL(file),
          base64: base64String,
        });
        setValue('image', newFile, { shouldValidate: true });
      };
      reader.onerror = (error) => {
        console.error('Error reading the file:', error);
      };
      reader.readAsDataURL(file);
    },
    [setValue, setPanimgBase64]
  );


  return (

    <Container maxWidth={settings.themeStretch ? false : 'lg'}>

        <FormProvider  methods={methods}>
          <Grid container spacing={2} alignItems="center">

            <Grid item xs={12} md={6} lg={4}>
              <TextField label="Pancard No" name='pan' onChange={(e) => { setPanno(e.target.value) }} value={panno || ''} sx={{ width:'100%' }}/>
              <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.pan}</p>

            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Stack direction="column" spacing={0.5} justifyContent="center" alignItems="center">
                <Typography variant="body2"  sx={{
                        mb: 2}}> Pancard Image </Typography>
                <RHFUploadAvatar
                  name="image"
                  maxSize={2097152}
                  onDrop={handleDrop}
                  helperText={
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 3,
                        mx: 'auto',
                        display: 'block',
                        textAlign: 'center',
                        color: 'text.disabled',
                      }}
                    >
                      Allowed *.jpeg, *.jpg, *.png
                    </Typography>
                  }
                />
            <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.image}</p>

              </Stack>
            </Grid>

            <Grid item xs={12} md={12} lg={12} textAlign='center' sx={{ mt: 3 }}>
              <LoadingButton variant="contained" type="submit" loading={isSubmitting} onClick={handleNext}>
                Next
              </LoadingButton>
            </Grid>
          </Grid>

        </FormProvider>
        <Dialog open={open} onClose={handleClose} className='add_dialog'>
        <form>
          <DialogTitle>Pancard Verification</DialogTitle>
          <DialogContent>
            <Typography
              variant="caption"
              sx={{
                mt: 0,
                mx: 'auto',
                fontSize: '18px',
                display: 'block',
                textAlign: 'center',
                color: '#00a76f',
                fontWeight: '600'
              }}
            >
              {message}
            </Typography>
          </DialogContent>
          <DialogActions>
            <LoadingButton onClick={handleSave} variant="contained" type="button" loading={isSubmitting} sx={{
              mx: 'auto'
            }}>
              OK
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </Container>


  );
}
PanMemberData.propTypes = {
  onNextButtonClick: PropTypes.func.isRequired,
};
