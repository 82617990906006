'use client'

 const operatorTypes = [
  // { value: "prepaid", label: "Prepaid" },
  // { value: "dth", label: "DTH" },
  // { value: "electricity", label: "Electricity" },
  // { value: "broadband", label: "Broadband" },
  // { value: "cable", label: "Cable" },
  // { value: "datacardpostpaid", label: "Datacard Postpaid" },
  // { value: "datacardprepaid", label: "Datacard Prepaid" },
  // { value: "emi", label: "EMI Payment" },
  // { value: "fasttag", label: "Fasttag" },
  // { value: "gas", label: "Gas" },
  // { value: "hospital", label: "Hospital" },
  // { value: "insurance", label: "Insurance" },
  // { value: "landline", label: "Landline" },
  // { value: "lpgbooking", label: "LPG Booking" },
  // { value: "municipality", label: "Municipality" },
  // { value: "postpaid", label: "Postpaid" },
  // { value: "water", label: "Water" },
  // { value: "pancard", label: "Pancard" },
  // { value: "dmt", label: "Dmt" },
  { value: "aeps", label: "Aeps" },
  // { value: "matm", label: "Matm" },
  // { value: "fund", label: "Fund" },
  // { value: "nsdlpan", label: "Nsdl Pancard" },
  // { value: "cash", label: "Cash Deposit" },
  // { value: "aadhar", label: "Aadhar Pay" },
  // { value: "fd", label: "Fixed Deposit" },
  // { value: "paytm", label: "Paytm Wallet" },
  // { value: "mobikwik", label: "Mobikwik Wallet" },
  // { value: "faeps", label: "FAeps" },
  // { value: "faadhar", label: "Fingpay Aadhar" },
  // { value: "travel", label: "Travel" },
  // { value: "airtelcms", label: "Airtel CMS" }
];

export default operatorTypes;
