'use client';

import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
import { useRouter, useSearchParams } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';

import { useAuthContext } from 'src/auth/hooks';
import { PATH_AFTER_LOGIN } from 'src/config-global';
// import { useAppDispatch, useAppSelector } from 'src/app/redux/reducers';
//
import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { useAppDispatch, useAppSelector } from 'src/app/redux/reducers';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';
import { toast } from 'react-toastify';
import axios from 'axios';

// ----------------------------------------------------------------------

export default function JwtLoginView() {
  const { login ,relogin} = useAuthContext();
  const isAuth = useAppSelector((state) => state.auth);
  const router = useRouter();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errorMsg, setErrorMsg] = useState('');

  const searchParams = useSearchParams();

  const returnTo = searchParams.get('returnTo');

  const password = useBoolean();
  const [open, setOpen] = useState(false);
  const [errorData, setErrorData] = useState(null);
  const [message, setMessage] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const LoginSchema = Yup.object().shape({
    mobile_no: Yup.string().required('Mobile No is required').matches(/^[0-9]{10}$/, 'Mobile No must be exactly digits'),
    password: Yup.string().required('Password is required'),
  });


  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          ApiService.defaults.headers.latitude = `${position.coords.latitude}`;
          ApiService.defaults.headers.longitude = `${position.coords.longitude}`;
        },
      );
    }
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get('https://api.ipify.org/?format=json');
        const ipAddress = response.data.ip;
        ApiService.defaults.headers.ipaddress = ipAddress;
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    fetchIpAddress();
  })
  const defaultValues = {
    mobile_no: '9723948029',
    password: 'niru2000',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    setValue,
    reset,
    handleSubmit,
  } = methods;

  const handleMobileNoKeyPress = (e) => {
    // Allow only numeric digits (0-9)
    if (e.key && !/[0-9]/.test(e.key)) {
      e.preventDefault();
    }

    // Limit input to 10 characters
    if (e.target.value.length >= 10) {
      e.preventDefault();
    }
  };
  const dispatch = useAppDispatch();
  const onSubmit = handleSubmit(async (data) => {
    try {
      setIsSubmitting(true)
      await login?.(Number(data.mobile_no), data.password);
      dispatch({ type: 'LOGIN_SUCCESS', payload: true });
      router.push(returnTo || PATH_AFTER_LOGIN);
    } catch (error) {
      if (error instanceof Error && error.data) {
        console.log("Custom error data:", error.data);
        if (error.data?.code === 400) {
          setErrorData(error.data.token);
          setOpen(true);
        }
        setIsSubmitting(false)
        reset();
        setErrorMsg(typeof error.data.message === 'string' ? error.data.message : '');
      } else {
        console.log("Standard error:", error.message);
        setIsSubmitting(false)
        reset();
        setErrorMsg(typeof error.message === 'string' ? error.message : '');
      }
      // setIsSubmitting(false)
      // console.log(error.message);
      // reset();
      // setErrorMsg(typeof error === 'string' ? error : error.message);
    }
  });
  console.log("isAuth", isAuth)

  const handleContinue =async (e) => {
    ApiService.defaults.headers.token = `${errorData}`;
    e.preventDefault();
    console.log('errorData', errorData)
    try {
      setIsSubmitting(true)
      await relogin?.();
      dispatch({ type: 'LOGIN_SUCCESS', payload: true });
      router.push(returnTo || PATH_AFTER_LOGIN);
    } catch (error) {
      if (error instanceof Error && error.data) {
        console.log("Custom error data:", error.data);
        if (error.data?.code === 400) {
          setErrorData(error.data.token);
          setOpen(true);
        }
        setIsSubmitting(false)
        reset();
        setErrorMsg(typeof error.data.message === 'string' ? error.data.message : '');
      } else {
        console.log("Standard error:", error.message);
        setIsSubmitting(false)
        reset();
        setErrorMsg(typeof error.message === 'string' ? error.message : '');
      }
      // setIsSubmitting(false)
      // console.log(error.message);
      // reset();
      // setErrorMsg(typeof error === 'string' ? error : error.message);
    }
    // try {
    //   ApiService.post(Endpoints.rolegetpermission).then((response) => {
    //     const responseData = response.data;
    //     if (responseData?.status === true) {
    //       setPermissionsData(responseData?.data);
    //     }
    //   });
    // } catch (error) {
    //   toast.error('Error submitting form', {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // }
  }


  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5 }}>
      <Typography variant="h4">Sign in to Hallipay</Typography>

      <Stack direction="row" spacing={0.5}>
        <Typography variant="body2">New user?</Typography>

        <Link component={RouterLink} href={paths.auth.jwt.register} variant="subtitle2">
          Create an account
        </Link>
      </Stack>
    </Stack>
  );

  const renderForm = (
    <Stack spacing={2.5}>
      {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
      <RHFTextField
        name="mobile_no"
        label="Mobile No"
        onKeyPress={handleMobileNoKeyPress}
        onInput={(e) => { setValue('mobile_no', e.target.value.replace(/[^0-9]/g, '').slice(0, 10)) }}
      // helperText={methods.formState.errors.mobile_no?.message}
      />

      <RHFTextField
        name="password"
        label="Password"
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Link variant="body2" color="inherit" underline="always" sx={{ alignSelf: 'flex-end' }}>
        Forgot password?
      </Link>

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Login
      </LoadingButton>
    </Stack>
  );

  return (
    <>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        {renderHead}
        {renderForm}

      </FormProvider>

      <Dialog open={open} onClose={handleClose} className="add_dialog">
        <form >
          <DialogTitle/>
          <DialogContent>
            <FormProvider {...methods}>
              <p style={{ color:'red' ,margin:0}}>  {errorMsg}</p>

                <br/>
                <p style={{ margin:0,textAlign:'center'}}>  You Want to Continue?</p>
            </FormProvider>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton variant="contained" type="submit" loading={isSubmitting} onClick={handleContinue}>
              Continue
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
