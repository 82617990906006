'use client'

/* eslint-disable import/no-extraneous-dependencies */

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { useBoolean } from 'src/hooks/use-boolean';


import moment from 'moment';
import Label from 'src/components/label';




// ----------------------------------------------------------------------

export default function CreditCardTable({ row, selected, onEditRow, getData }) {
  const { id, role_name, display_name, updatedAt } = row;

  const quickEdit = useBoolean();

  const format = moment('03-01-2024').format('D-MM-YYYY')
  // , h:mm:ss a

const name = 'pending';
  return (

      <TableRow hover selected={selected}>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          1<br/>
          {format}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          name<br />
          78596458798<br />
          retailer
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>PAY INDIA U (13868)
        <br/> 8695623790 <br/>
        Whitelabel</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }} >Ref No.-201872<br />
        Paydate - 1/1/2024</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }} >1391
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }} >Anil
        </TableCell>
        <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
        <Label

            variant="soft"
            color={
              (name === 'success' && 'success') ||
              (name === 'pending' && 'warning') ||
              'default'
            }
          >
            {name}
          </Label><br/>

        </TableCell>
      </TableRow>

  );
}

CreditCardTable.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  getData: PropTypes.func,
};
