import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Switch, Button, MenuItem, Popover, Drawer, Typography, Divider, IconButton } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { paths } from 'src/routes/paths';
import { useRouter, useSearchParams } from 'src/routes/hooks';
import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Stack } from '@mui/system';
import Label from 'src/components/label';
import Scrollbar from 'src/components/scrollbar';



// ----------------------------------------------------------------------

export default function UnRegisteredTable({ row, selected, onEditRow, permissionsData, onCloseDrawer }) {
  // const { id, name, } = row;
  const router = useRouter();

  const confirm = useBoolean();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fundTransfer = useBoolean();
  const fundEdit = useBoolean();
  const aepstransfer = useBoolean();
  const notification = useBoolean();

  const actionpopover = usePopover();
  const reportpopover = usePopover();

  const [isToggled, setToggled] = useState(true);
  const handleToggle = () => {
    setToggled(!isToggled);
  };
  const prms = useSearchParams();
  const tname = prms.get('t');
  const [resT, setResT] = useState("");
  const [resPg, setResPg] = useState("");

  const format = moment(row.createdAt).format('D-MM-YYYY, h:mm:ss a')

  const routerPush = useCallback(() => {
    try {
      ApiService.post(Endpoints.memberprofile.unregisterstatusget, { id: row?.id }).then((response) => {
        const responseData = response.data;
        console.log('responseData', responseData)
        if (responseData?.status === true) {


          // Pass resT and resPg as query parameters to the route

          const searchParams = new URLSearchParams({
            pg: responseData?.data?.pg,
            t: responseData?.data?.t
          }).toString();
          const href = `${paths.member.unregisterapplication}/${row?.id}?${searchParams}`;
          router.push(href);
          // router.push({
          //   pathname: `${paths.member.unregisterapplication}/${row?.id}`,
          //   query: { resT: responseData?.t, resPg: responseData?.pg },
          // });
        }

      });
    } catch (error) {
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [router, row]);



  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
       {row.id}<br />{format}

        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.first_name} {row.last_name}
          <br />{row.mobile_no}
        </TableCell>
        <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
          {row?.parentData?.first_name} {row?.parentData?.last_name} ({row?.parentData?.id})<br />
          {row?.parentData?.mobile_no}<br />
          Role : {row?.parentData?.Role?.display_name}
        </TableCell>
        <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
          Alternate Mobile No:{row?.mobile_no}<br />
          Company:Hallipay<br />
          APP Version:60
        </TableCell>
{/*
        <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>

        </TableCell> */}

        <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }} >
          <Button onClick={actionpopover.onOpen} variant="contained" sx={{ mb: 1 }} size='small'>
            <Iconify icon="gridicons:dropdown" />
            Action
          </Button><br />
        </TableCell>

      </TableRow >
{/*
      <FundTransfer currentUser={row} fundtransfer={fundTransfer.value} onClose={fundTransfer.onFalse} />
      <AepsTransfer currentUser={row} aepstransfer={aepstransfer.value} onClose={aepstransfer.onFalse} />
      <SendNotification currentUser={row} notification={notification.value} onClose={notification.onFalse} /> */}


      {/* action  */}
      <CustomPopover
        open={actionpopover.open}
        onClose={actionpopover.onClose}
        arrow="right-top"
        sx={{ width: 180 }}
      >
        <MenuItem onClick={routerPush} >
          <Iconify icon="bi:person-fill" />
          Go to Appilication
        </MenuItem>
        {/* <MenuItem>
          <Iconify icon="mdi:cogs" />
          Kyc Manager
        </MenuItem> */}
        {/* <MenuItem onClick={notification.onTrue}>
          <Iconify icon="clarity:notification-solid" />
          Send Notification
        </MenuItem> */}
      </CustomPopover >


    </>
  );
}

UnRegisteredTable.propTypes = {
  onEditRow: PropTypes.func,
  permissionsData: PropTypes.object,
  row: PropTypes.object,
  selected: PropTypes.bool,
  onCloseDrawer: PropTypes.func,
};
