import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';

import { LoadingScreen } from 'src/components/loading-screen';
import WhitelabelData from 'src/sections/member/whitelabel/whitelabel-data';
import MasterDistributorData from 'src/sections/member/master-distributor/mdistributor-data';
import DistributorData from 'src/sections/member/distributor/distributor-data';
import RetailerData from 'src/sections/member/retailer/retailer-data';
import ManagerData from 'src/sections/member/manager/manager-data';
import SubCategoriesData from 'src/sections/leads/sub-categories/subcategories-data';
import CategoriesData from 'src/sections/leads/lead-categories/categories-data';
import TransferReturnData from 'src/sections/fund/transfer-return/transfer-data';
import RequestData from 'src/sections/fund/request/request-data';
import RequestReportData from 'src/sections/fund/request-report/requestreport-data';
import FundReportData from 'src/sections/fund/all-fund-report/fundreport-data';
import CreditCardData from 'src/sections/credit-card/creditcard-data';
import AepsStatementData from 'src/sections/transaction-history/aeps-statement/aepsstatement-data';
import FAepsStatementData from 'src/sections/transaction-history/faeps-statement/faepsstatement-data';
import ApiManagerData from 'src/sections/setup-tools/api-manager/apimanager-data';
import MATMData from 'src/sections/transaction-history/matm-statement/matm-data';
import MoneyTransferData from 'src/sections/transaction-history/money-tranfer/money-data';
import AccountView from 'src/sections/account-settings/profile/profile';
import OperatorManagerData from 'src/sections/setup-tools/operator/operator-data';

import IAepsData from 'src/sections/agent-list/iaeps-data';
import PortalSettingData from 'src/sections/setup-tools/portal-setting/portalsetting-data';
import AepsRequestData from 'src/sections/aeps-fund/request/request-data';
import AepsPendingData from 'src/sections/aeps-fund/pending-request/pendingrequest-data';
import AllLeadData from 'src/sections/services/leads/alllead-data';
import CreateMemberData from 'src/sections/member/create/createmember';
import MemberProfile from 'src/sections/member/profile/profile';
import AepsWalletData from 'src/sections/account-statement/aeps-wallet/aepswallet-data';
import ChangeHistoryData from 'src/sections/transaction-history/change-history/changehistory-data';
import FAepsData from 'src/sections/bankervice/faeps/faeps_data';
import UnRegisteredData from 'src/sections/member/unregistered/unregistered-data';
import MemberApplicationData from 'src/sections/member/unregistered/application/createmember';
import LoadWalletData from 'src/sections/fund/loadwallet/loadwallet-data';

// ----------------------------------------------------------------------

// role
const RoleData = lazy(() => import('src/sections/role/role-data'));
// bank service
const AepsData = lazy(() => import('src/sections/bankervice/aeps/aeps_data'));
// resources
const Schemedata = lazy(() => import('src/sections/resources/scheme-manager/scheme-data'));
const CompanyData = lazy(() => import('src/sections/resources/company-manager/company-data'));

// ----------------------------------------------------------------------

export const adminRoutes = [
  {
    // path: 'role',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: 'role',
        children: [
          { element: <RoleData />, index: true },
          { path: 'role', element: <RoleData /> },
        ],
      },
      {
        path: 'bankservice',
        children: [
          { element: <AepsData />, index: true },
          { path: 'aeps', element: <AepsData /> },
          { path: 'faeps', element: <FAepsData /> },
        ],
      },
      {
        path: 'resources',
        children: [
          { element: <Schemedata />, index: true },
          { path: 'scheme-manager', element: <Schemedata /> },
          { path: 'company-manager', element: <CompanyData /> },
          // { path: 'schememanager', element: <Schemedata /> },
        ],
      },
      {
        path: 'member',
        children: [
          { element: <WhitelabelData />, index: true },
          { path: 'whitelabel', element: <WhitelabelData /> },
          { path: 'master-distributor', element: <MasterDistributorData /> },
          { path: 'distributor', element: <DistributorData /> },
          { path: 'retailer', element: <RetailerData /> },
          { path: 'manager', element: <ManagerData /> },
          { path: 'create', element: <CreateMemberData /> },
          { path: 'profile/:id', element: <MemberProfile /> },
          { path: 'unregistered/application/:id', element: <MemberApplicationData /> },
          { path: 'unregistered', element: <UnRegisteredData /> },
        ],
      },
      {
        path: 'fund',
        children: [
          { element: <TransferReturnData />, index: true },
          { path: 'loadwallet', element: <LoadWalletData /> },
          { path: 'transfer-return', element: <TransferReturnData /> },
          { path: 'request', element: <RequestData /> },
          { path: 'request-report', element: <RequestReportData /> },
          { path: 'all-fund-report', element: <FundReportData /> },
        ],
      },
      {
        path: 'leads',
        children: [
          { element: <CategoriesData />, index: true },
          { path: 'categories', element: <CategoriesData /> },
          { path: 'sub-categories', element: <SubCategoriesData /> },
        ],
      },
      {
        path: 'credit-card',
        children: [
          { element: <CreditCardData />, index: true },
        ],
      },
      {
        path: 'transaction-history',
        children: [
          { element: <AepsStatementData />, index: true },
          { path: 'aeps-statement', element: <AepsStatementData /> },
          { path: 'change-history', element: <ChangeHistoryData /> },
          { path: 'Faeps-statement', element: <FAepsStatementData /> },
          { path: 'MATM-statement', element: <MATMData /> },
          { path: 'money-transfer', element: <MoneyTransferData /> },
        ],
      },
      {
        path: 'setup-tools',
        children: [
          { element: <ApiManagerData />, index: true },
          { path: 'api-manager', element: <ApiManagerData /> },
          { path: 'operator', element: <OperatorManagerData /> },
          { path: 'portal-setting', element: <PortalSettingData /> },
        ],
      },
      {
        path: 'account-settings',
        children: [
          { element: <AccountView />, index: true },
          { path: 'profile', element: <AccountView /> },
          // { path: 'Faeps-statement', element: <FAepsStatementData /> },
        ],
      },
      {
        path: 'account-statement',
        children: [
          { element: <AepsWalletData />, index: true },
          { path: 'aeps-wallet', element: <AepsWalletData /> },
          // { path: 'Faeps-statement', element: <FAepsStatementData /> },
        ],
      },
      {
        path: 'agent-list',
        children: [
          { element: <IAepsData />, index: true },
          { path: 'iaeps', element: <IAepsData /> },
          // { path: 'Faeps-statement', element: <FAepsStatementData /> },
        ],
      },
      {
        path: 'aeps-fund',
        children: [
          { element: <AepsPendingData />, index: true },
          { path: 'pending-request', element: <AepsPendingData /> },
          { path: 'request', element: <AepsRequestData /> },
        ],
      },
      {
        path: 'services',
        children: [
          { element: <AllLeadData />, index: true },
          { path: 'leads', element: <AllLeadData /> },
          // { path: 'pending-request', element: <AepsPendingData /> },
          // { path: 'request-report', element: <AepsRequestReportData /> },
        ],
      },
    ],
  },
];
