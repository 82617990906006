import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { useMockedUser } from 'src/hooks/use-mocked-user';

import { fData } from 'src/utils/format-number';

import { countries } from 'src/assets/data';

import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFSwitch,
  RHFTextField,
  RHFUploadAvatar,
  RHFAutocomplete,
} from 'src/components/hook-form';
import { FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import Constants, { UsersPermissions } from 'src/common/constant';
import { useAuthContext } from 'src/auth/hooks';
import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import ChangeIconData from 'src/sections/setup-tools/portal-setting/changeicon-data';

// ----------------------------------------------------------------------

export default function MappingManager() {

  const methods = useForm({});
  // const [isDataLoading, setIsDataLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { user } = useAuthContext();
  const { id } = useParams();
  const {
    setValue,
    handleSubmit,
  } = methods;
  const [changetype, setSetChangetype] = useState([])
  const [open, setOpen] = useState(false);

  const handleOpen = (type) => {
    setOpen(true);

    setSetChangetype(type)

  };
  const [selectedMemberType, setSelectedMemberType] = useState(user?.member_type || '');

  const handleMemberTypeChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedMemberType(selectedValue);

  };

  const membertype = [
    { value: Constants.ROLE.RETAILER, name: 'Retailer' },
    { value: Constants.ROLE.DISTRIBUTOR, name: 'Distributor' },
    { value: Constants.ROLE.MASTER_DISTRIBUTOR, name: 'Master Distributor' },
    { value: Constants.ROLE.WHITELABEL, name: 'Whitelabel' }
  ]

  const onSubmit = handleSubmit(async (data) => {
    try {
      setIsSubmitting(true)
      await ApiService.post(Endpoints.memberprofile.roleupdate,{
        member_type:selectedMemberType,
        member_id:id,
      }).then((response) => {
        const responseData = response.data
        if (responseData?.status === true) {
          toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          setIsSubmitting(false)
        } else {
          setIsSubmitting(false)
          console.log('error', response.data);
        }
      });
    } catch (error) {
      setIsSubmitting(false)
      console.error("Error submitting form:", error);
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    // setIsDataLoading(false);
  });
  const getRoleData = async () => {
    try {
      ApiService.post(Endpoints.memberprofile.getmemberrole,{member_id:id}).then((response) => {
        const responseData = response.data;
        if (responseData?.status === true) {
          const apidata = responseData.data
          setSelectedMemberType(apidata?.member_type);

          // setRoleData(apidata);
        }
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Error submitting form', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  useEffect(() => {
    getRoleData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {/* {isDataLoading === false ? */}
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Grid container spacing={3}>
          <Grid xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Box
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <FormControl sx={{ flexShrink: 0, width: { xs: 5, md: '100%' } }}>
                  <InputLabel>Member Type</InputLabel>
                  <Select
                    name="member_type"
                    value={selectedMemberType}
                    onChange={handleMemberTypeChange}
                    input={<OutlinedInput label="Member Type" />}
                    renderValue={(selected) => {
                      const selectedMember = membertype.find((type) => type.value === selected);
                      return selectedMember ? selectedMember.name : '';
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 240,
                          maxWidth: '100%',
                        },
                      },
                    }}
                  >
                    {membertype.map((type) => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select>


                </FormControl>
                <IconButton style={{ color: '#000', marginLeft: 'auto' }} onClick={()=>{handleOpen('ROLE_MANAGER')}}>
                  <Iconify icon="typcn:info" />
                </IconButton>
              </Box>

              <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  Save Changes
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
      {open===true?<ChangeIconData open={open} setOpen={setOpen} type={changetype} member_id={id}/>:null}

      {/* : null} */}
    </>
  );
}
