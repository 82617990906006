'use client'


const Endpoints = {
  login:'/auth/login',
  relogin:'/auth/relogin',
  me:'/auth/me',
  dashboard: '/posts',
  register: 'auth/register/pg1',
  otpVerify: 'auth/otpverify',
  pancard: 'auth/register/pg2',
  aadharcard: 'auth/register/pg3',
  aadharotpverify: 'auth/aadharotpverify',
  pg4: 'auth/register/pg4',
  createrole: 'admin/role',
  getroles: 'admin/getrole',
  editrole: 'admin/editrole',
  getpermission: 'admin/role/getpermission',
  getuserpermission: 'admin/user/getpermission',
  editpermission: 'admin/role/editpermission',
  edituserpermission: 'admin/user/editpermission',
  getAllSchema:'schema/schema/getAll',
  rolegetpermission:`admin/permission/get`,

  changeprofile:{
    getprofile:'/auth/getprofile',
    passwordchange:'/auth/password/change',
    getpassword:'/auth/password/get'
  },

  updateSchemaName:'schema/schema/update/name',
  updateSchemaStatus:'schema/schema/update/status',
  updateSchemaAds:'schema/schema/update/ads',
  getComissionById:'schema/commission/getAll',

  createapimanager:'apimanager/apicreate',
  updateapimanager:'apimanager/apiupdate',
  statusupdateapimanager:'apimanager/apistatusupdate',
  getapimanager:'apimanager/apiget',
  aepsstatement:'transactionhistory/aepsstatement',
  changehistoryget:'transactionhistory/changehistory/get',

  getbanklist:'bankservice/getbanklist',
  balanceenquiry:'bankservice/balanceenquiry',
  cashwithdraw:'bankservice/cashwithdraw',
  ministatement:'bankservice/ministatement',
  aadharpay:'bankservice/aadharpay',
  twofactorregister:'bankservice/twofactor/register',
  twofactordaily:'bankservice/twofactor/dailyauthenticate',
  checkcashwithdraw:'bankservice/checkcashwithdrawauthenticate',
  cashwithdrawauthenticate:'bankservice/twofactor/authenticate/cashwithdraw',

  getretailers:'auth/user/getretailers',
  createretailers:'auth/user/retailer/create',
  createdistributor:'auth/user/distributor/create',
  createmasterdistributor:'auth/user/masterdistributor/create',
  createwhitelabel:'auth/user/whitelabel/create',

  getdistributors:'auth/user/getdistributors',
  getmasterdistributors:'auth/user/getmasterdistributors',
  getwhitelabel:'auth/user/getwhitelabel',

  getschemacommission:'schema/commission/getAll',
  createSchema:'schema/schema/create',
  updateschemacommission:'schema/schemacommission/update',

  providers:{
    create:'providers/create',
    update:'providers/update',
    get:'providers/get',
    updatestatus:'providers/update/status',
    updateapi:'providers/update/api',
  },

  portalsetting:{
    get:'portal/setting/get',
    update:'portal/setting/update',
    getstate:'portal/states/get',
    getdistrict:'portal/district/get',
  },

  profile:{
    bankaccountget:'userprofile/bankaccount/get',
    bankaccountcreate:'userprofile/bankaccount/create',
    bankaccountupdate:'userprofile/bankaccount/update',
    kycdetailsget:'userprofile/kycdetails/get',
    kycdetailsupdate:'userprofile/kycdetails/update',
    roleupdate:'userprofile/role/update',
    mappingmanagerupdate:'userprofile/mappingmanager/update',
    mappingmanagerget:'userprofile/mappingmanager/get',
  },

  memberprofile:{
    idstockget:'userprofile/member/idstock/get',
    idstockupdate:'userprofile/member/idstock/update',
    getprofile:'userprofile/member/getprofile',
    passwordchange:'userprofile/member/password/change',
    getpassword:'userprofile/member/password/get',
    bankaccountget:'userprofile/member/bankaccount/get',
    bankaccountcreate:'userprofile/member/bankaccount/create',
    bankaccountupdate:'userprofile/member/bankaccount/update',
    kycdetailsget:'userprofile/member/kycdetails/get',
    kycdetailsupdate:'userprofile/member/kycdetails/update',
    roleupdate:'userprofile/member/role/update',
    mappingmanagerupdate:'userprofile/member/mappingmanager/update',
    mappingmanagerget:'userprofile/member/mappingmanager/get',
    getmemberrole:'userprofile/member/role/get',
    getmembermanager:'userprofile/member/manager/get',
    schemaupdate:'userprofile/member/schema/update',
    unregisterget:'auth/member/unregister/get',
    unregisterstatusget:'auth/member/unregister/application/status/get'

  },


  aepsfund:{
    aepsfundrequestget:'transactionhistory/aepsfundrequest/get',
    aepsfundrequestcreate:'bankservice/fundtransaction',
    aepsfundrequestedit:'bankservice/fundtransaction/editfundrequest',
    aepsfundrequestcheckstatus:'bankservice/fundtransaction/checkfundpayoutstatus',
  },

  membercreate:{
    pg1:'auth/member/register/pg1',
    pg1update:'auth/member/register/update/pg1',
    pg2:'auth/member/register/pg2',
    pg3:'auth/member/register/pg3',
    pg4:'auth/member/register/pg4',
    otp:'auth/member/otp',
    otpverify:'auth/member/otpverify',
    aadharotpverify:'auth/member/aadharotpverify',
  },

  fundtransfer:{
    memberfundtransfer:'bankservice/fundtransfer',
    adminfundtransfer:'bankservice/fundtransferforadmin',
  }



};

export default Endpoints;
