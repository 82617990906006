import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { Autocomplete, FormControl, IconButton, TextField } from '@mui/material';
import ApiService from 'src/common';
import { toast } from 'react-toastify';
import Endpoints from 'src/common/endpoints';
import Iconify from 'src/components/iconify';
import ChangeIconData from 'src/sections/setup-tools/portal-setting/changeicon-data';

// ----------------------------------------------------------------------

export default function FundTransfer({ currentUser, open, onClose, fundtransfer }) {
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState();
  const [changetype, setSetChangetype] = useState([])
  const [open1, setOpen1] = useState(false);
  const handleOpen = (type) => {
    setOpen1(true);

    setSetChangetype(type)

  };
  const defaultValues = useMemo(
    () => ({
      name: currentUser?.name || '',
      webname: currentUser?.webname || '',
      senderid: currentUser?.senderid || '',
      smsuser: currentUser?.smsuser || '',
      smspwd: currentUser?.smspwd || '',
    }),
    [currentUser]
  );

    const methods = useForm({
      defaultValues,
    });

    const {
      reset,
      handleSubmit,
    } = methods;

  const [formData, setFormData] = useState({
    amount: '',
    remark: '',
    type: '',
  });

  const handleInputChange = (e, newInputValue) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      type: newInputValue,
    }));
  };

  const [selectedStatus, setSelectedStatus] = React.useState(null);

  const handleStatusChange = (event, newValue) => {
    setSelectedStatus(newValue);
    setFormData((prevData) => ({
      ...prevData,
      type: newValue ? newValue.value : '',
    }));
  };



  const onSubmit = handleSubmit(async (data) => {
    console.log('formData', formData)
    try {
      setIsSubmitting(true)
      await ApiService.post(Endpoints.fundtransfer.memberfundtransfer,{...formData,type:selectedStatus?.value,member_id:currentUser?.id}).then(async (response) => {
        const responseData = response.data
        if (responseData?.status === true) {
          toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
          onClose();
        } else {
          toast.error(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
        }
      });
    } catch (error) {
      setIsSubmitting(false)
      console.error("Error submitting form:", error);
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  });

  const options = [
    { value: 'transfer', label: 'Tranfer' },
    { value: 'return', label: 'Return' },
  ]


  return (
    <>
    <Dialog
      fullWidth
      maxWidth={false}
      open={fundtransfer}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>Fund Transfer / Return <IconButton style={{ color: '#000', marginLeft: 'auto' }} onClick={()=>{handleOpen('FUND_TRANSFER')}}>
                  <Iconify icon="typcn:info" />
                </IconButton></DialogTitle>

        <DialogContent className='mt-2'>

          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <FormControl
              sx={{
                flexShrink: 0,
              }}
            >
              <Autocomplete
                name="type"
                value={selectedStatus}
                onChange={handleStatusChange}
                options={options}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField {...params} label="Fund Action" variant="outlined" />
                )}
              />
            </FormControl>

            <RHFTextField name="amount" label="Amount" onChange={handleInputChange}
              value={formData.amount || ''} />
            <RHFTextField name="remark" label="Remark" onChange={handleInputChange}
              value={formData.remark || ''} />

          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Close
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Submit
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
    {open1===true?<ChangeIconData open={open1} setOpen={setOpen1} type={changetype} member_id={currentUser?.id}/>:null}

    </>
  );
}

FundTransfer.propTypes = {
  currentUser: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  fundtransfer: PropTypes.bool,
};
