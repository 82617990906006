import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { Autocomplete, FormControl, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import ApiService from 'src/common';
import { toast } from 'react-toastify';
import Endpoints from 'src/common/endpoints';
import Iconify from 'src/components/iconify';
import ChangeIconData from 'src/sections/setup-tools/portal-setting/changeicon-data';
import Constants from 'src/common/constant';
import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

export default function AddIdStock({ currentUser, open, onClose, addidstock }) {
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState();
  const [changetype, setSetChangetype] = useState([]);
  const { user } = useAuthContext();

  const [open1, setOpen1] = useState(false);
  const handleOpen = (type) => {
    setOpen1(true);
    setSetChangetype(type)

  };

  const methods = useForm({});

  const {
    reset,
  } = methods;

  const [rowData, setRowData] = useState({
    mdstock: 0,
    rstock: 0,
    dstock: 0
  });

  const IdStockGet = async () => {
    try {
      setIsSubmitting(true)
      await ApiService.post(Endpoints.memberprofile.idstockget, { member_id: currentUser?.id }).then(async (response) => {
        const responseData = response.data
        if (responseData?.status === true) {
          toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setRowData({
            mdstock: responseData?.data?.mdstock || 0,
            rstock: responseData?.data?.rstock || 0,
            dstock: responseData?.data?.dstock || 0
          });
          setIsSubmitting(false)
        } else {
          toast.error(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
        }
      });
    } catch (error) {
      setIsSubmitting(false)
      console.error("Error submitting form:", error);
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setRowData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleIdStockSubmit = async (e, fieldName) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      const response = await ApiService.post(Endpoints.memberprofile.idstockupdate, {
        member_id: currentUser?.id,
        [fieldName]: rowData[fieldName]
      });
      const responseData = response.data;
      if (responseData?.status === true) {
        toast.success(responseData.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(responseData.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (addidstock) {
      IdStockGet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={false}
        open={addidstock}
        onClose={onClose}
        PaperProps={{
          sx: { maxWidth: 720 },
        }}
      >
        <FormProvider methods={methods}>
          <DialogTitle>Ids Stock <IconButton style={{ color: '#000', marginLeft: 'auto' }} onClick={() => { handleOpen('MEMBER_ID_STOCK') }}>
            <Iconify icon="typcn:info" />
          </IconButton></DialogTitle>

          <DialogContent className='mt-2'>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: '100px', fontSize: '14px' }}>Stock Type</TableCell>
                    <TableCell sx={{ width: '150px', fontSize: '14px' }}>Value</TableCell>
                    <TableCell sx={{ width: '100px', fontSize: '14px' }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentUser?.member_type === Constants.ROLE.WHITELABEL && (
                    <TableRow>
                      <TableCell>
                        Master Id
                      </TableCell>
                      <TableCell>
                        <TextField
                          name="mdstock"
                          label="Enter Value"
                          type="number"
                          value={rowData.mdstock}
                          onChange={handleInputChange}
                        />
                      </TableCell>
                      <TableCell>
                        <LoadingButton type="submit" variant="contained" onClick={(e) => handleIdStockSubmit(e, "mdstock")}>
                          Submit
                        </LoadingButton>
                      </TableCell>
                    </TableRow>
                  )}

                  {(currentUser?.member_type === Constants.ROLE.WHITELABEL || currentUser?.member_type === Constants.ROLE.MASTER_DISTRIBUTOR) && (
                    <TableRow>
                      <TableCell>
                        Distributor Id
                      </TableCell>
                      <TableCell>
                        <TextField
                          name="dstock"
                          label="Enter Value"
                          type="number"
                          value={rowData.dstock}
                          onChange={handleInputChange}
                        />
                      </TableCell>
                      <TableCell>
                        <LoadingButton type="submit" variant="contained" onClick={(e) => handleIdStockSubmit(e, "dstock")}>
                          Submit
                        </LoadingButton>
                      </TableCell>
                    </TableRow>
                  )}

                  {(currentUser?.member_type === Constants.ROLE.WHITELABEL || currentUser?.member_type === Constants.ROLE.MASTER_DISTRIBUTOR || currentUser?.member_type === Constants.ROLE.DISTRIBUTOR) && (
                    <TableRow>
                      <TableCell>
                        Retailer Id
                      </TableCell>
                      <TableCell>
                        <TextField
                          name="rstock"
                          label="Enter Value"
                          type="number"
                          value={rowData.rstock}
                          onChange={handleInputChange}
                        />
                      </TableCell>
                      <TableCell>
                        <LoadingButton type="submit" variant="contained" onClick={(e) => handleIdStockSubmit(e, "rstock")}>
                          Submit
                        </LoadingButton>
                      </TableCell>
                    </TableRow>
                  )}

                </TableBody>
              </Table>
            </TableContainer>

          </DialogContent>

          <DialogActions>
            <Button variant="outlined" onClick={onClose}>
              Close
            </Button>

            {/* <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              Submit
            </LoadingButton> */}
          </DialogActions>
        </FormProvider>
      </Dialog>
      {open1 === true ? <ChangeIconData open={open1} setOpen={setOpen1} type={changetype} member_id={currentUser?.id} /> : null}

    </>
  );
}

AddIdStock.propTypes = {
  currentUser: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  addidstock: PropTypes.bool,
};
