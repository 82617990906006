'use client';

/* eslint-disable import/no-extraneous-dependencies */

import { useForm } from 'react-hook-form';
import React, { useState, useCallback } from 'react';

import { Stack } from '@mui/system';
import Container from '@mui/material/Container';
import {DialogTitle,  DialogActions, DialogContent,  Typography, Grid, TextField, Dialog, Button } from '@mui/material';

import { useRouter, useSearchParams } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';


import { useSettingsContext } from 'src/components/settings';
import FormProvider from 'src/components/hook-form/form-provider';
import {
  useTable,
  getComparator,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';
import { toast } from 'react-toastify';
import { RHFUploadAvatar } from 'src/components/hook-form';
import PropTypes from 'prop-types';
import OTPInput from 'react-otp-input';
import { LoadingButton } from '@mui/lab';

// import { notFound } from 'next/navigation';

// const STATUS_OPTIONS = [{ value: 'all', label: 'All' }, ...USER_STATUS_OPTIONS];

// ----------------------------------------------------------------------

export default function AadharMemberData({ onNextButtonClick }) {
  const prms = useSearchParams();
  const tname = prms.get('t');
  console.log('tname', tname)
  const settings = useSettingsContext();

  const router = useRouter();

  const confirm = useBoolean();
  const [resT, setResT] = useState("");
  const [resPg, setResPg] = useState("");
  const [aadharotp, setAadharotp] = useState("");
  const [aadharfrontimg, setAadharfrontimg] = useState(null);
  const [aadharbackimg, setAadharbackimg] = useState(null);
  const [aadharno, setAadharno] = useState(null);
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [panimgbase64, setPanimgBase64] = useState(null);

  const [errors, setErrors] = useState({
    frontimg: '',
    backimg: '',
    aadhar: '',
  });
  const methods = useForm();
  const {
    setValue,
  } = methods;

  const handleNext = async (e) => {
    e.preventDefault();
    // setOpen(true);
    const requiredFields = ['aadhar', 'frontimg', 'backimg'];
    const newErrors = {};

    // Check if any of the required fields are empty
    requiredFields.forEach((field) => {
        if (field === 'aadhar' && !aadharno) {
            newErrors.aadhar = 'Aadharno is required';
        } else if (field === 'frontimg' && !aadharfrontimg) {
            newErrors.frontimg = 'Frontimg is required';
        } else if (field === 'backimg' && !aadharbackimg) {
            newErrors.backimg = 'Backimg is required';
        }
    });

    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error !== '')) {
        return;
    }
    try {
      setIsSubmitting(true)
      await ApiService.post(Endpoints.membercreate.pg3, {
        aadhar_no: aadharno,
        front_image: aadharfrontimg,
        back_image: aadharbackimg,
        t: tname,
      }).then((response) => {
        setIsSubmitting(false)
        const responseData = response.data;
        setMessage(responseData ? responseData.message : 'No message available');
        console.log(responseData);

        if (responseData?.status === true) {
          console.log(response.data);

          if (responseData?.data?.show_model === 1) {
            setResPg(response.data?.data?.pg)
            setResT(response.data?.data?.t)
            setOpen(true);
          }

        } else {
          setIsSubmitting(false)
          toast.error(responseData ? responseData.message : 'No message available', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });

    } catch (error) {
      setIsSubmitting(false)
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    };


  };


  const handleAadharOtpChange = (value) => {
    setAadharotp(value);

  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = () => {
    const sendObj = {
      t: resT,
      otp: aadharotp
    }
    // onNextButtonClick();

    // handleClose();

    try {
      setIsSubmitting(true)
      ApiService.post(Endpoints.membercreate.aadharotpverify, sendObj).then((response) => {
        const responseData = response.data;
        console.log(response.data);

        if (responseData?.status === true) {
          onNextButtonClick(response.data?.data?.t)
           toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          handleClose();
        } else {
          setIsSubmitting(false)
          toast.error( responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setIsSubmitting(false)
      });
    } catch (error) {
      setIsSubmitting(false)
      console.error("Error submitting form:", error);
    }
  };



  const handleDropFront = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (!file) {
        console.error('No file selected');
        return;
      }

      // Check if the file format is allowed
      const allowedFormats = ['.jpeg', '.jpg', '.png'];
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (!allowedFormats.includes(`.${fileExtension}`)) {
        console.error(`Invalid file format. Allowed formats: ${allowedFormats.join(', ')}`);
        toast.error(`Invalid file format. Allowed formats: ${allowedFormats.join(', ')}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }



      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;

        if (!base64String) {
          console.error('Failed to read file');

          return;
        }

        // Update comment
        setAadharfrontimg(base64String);

        const newFile = Object.assign(file, {
          preview: URL.createObjectURL(file),
          base64: base64String,
        });

        setValue('frontimg', newFile, { shouldValidate: true });
      };

      reader.onerror = (error) => {
        console.error('Error reading the file:', error);
        // You can provide user feedback here, e.g., display an error message
      };

      reader.readAsDataURL(file);
    },
    [setValue, setAadharfrontimg]
  );
  const handleDropBack = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (!file) {
        console.error('No file selected');
        return;
      }

      // Check if the file format is allowed
      const allowedFormats = ['.jpeg', '.jpg', '.png'];
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (!allowedFormats.includes(`.${fileExtension}`)) {
        console.error(`Invalid file format. Allowed formats: ${allowedFormats.join(', ')}`);
        toast.error(`Invalid file format. Allowed formats: ${allowedFormats.join(', ')}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }



      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;

        if (!base64String) {
          console.error('Failed to read file');

          return;
        }

        // Update comment
        setAadharbackimg(base64String);

        const newFile = Object.assign(file, {
          preview: URL.createObjectURL(file),
          base64: base64String,
        });

        setValue('backimg', newFile, { shouldValidate: true });
      };

      reader.onerror = (error) => {
        console.error('Error reading the file:', error);
        // You can provide user feedback here, e.g., display an error message
      };

      reader.readAsDataURL(file);
    },
    [setValue, setAadharbackimg]
  );

  const handleAadharNoKeyPress = (e) => {
    // Allow only numeric digits (0-9)
    if (e.key && !/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
    if (e.target.value.length >= 12) {
      e.preventDefault();
    }
  };


  return (

    <Container maxWidth={settings.themeStretch ? false : 'lg'}>

      <FormProvider methods={methods} >
        <Grid container spacing={2} alignItems="center">

          <Grid item xs={12} md={6} lg={4}>
            <TextField label="Aadharcard No" name='aadharno' onChange={(e) => { setAadharno(e.target.value) }} value={aadharno || ''} sx={{ width:'100%' }}  onKeyPress={handleAadharNoKeyPress}
              onInput={(e) => { setValue('aadharno', e.target.value.replace(/[^0-9]/g, '').slice(0, 12)) }}/>
                  <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.aadhar}</p>

          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography variant="body2"> Aadharcard Front Image </Typography>
              <RHFUploadAvatar
                name="frontimg"
                maxSize={2097152}
                onDrop={handleDropFront}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 3,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.disabled',
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png
                  </Typography>
                }
              />
                <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.frontimg}</p>

            </Stack>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Stack direction="row" spacing={0.5} alignItems="center" >
              <Typography variant="body2"> Aadharcard Back Image </Typography>
              <RHFUploadAvatar
                name="backimg"
                maxSize={2097152}
                onDrop={handleDropBack}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 3,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.disabled',
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png
                  </Typography>
                }
              />
                <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.backimg}</p>

            </Stack>
          </Grid>

          <Grid item xs={12} md={12} lg={12} textAlign='center' sx={{ mt: 3 }}>
            <LoadingButton variant="contained" type="submit" loading={isSubmitting} onClick={handleNext}>
              Submit
            </LoadingButton>
          </Grid>
        </Grid>

      </FormProvider>
      <Dialog open={open} onClose={handleClose} className='add_dialog'>
        <form>
          <DialogTitle>Aadharcard Verification</DialogTitle>
          <DialogContent>
            <FormProvider {...methods}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>

                  <Typography
                    variant="caption"
                    sx={{
                      mb: 1,
                      mx: 'auto',
                      fontSize: '18px',
                      display: 'block',
                      textAlign: 'center',
                      color: '#00a76f',
                      fontWeight: '600'
                    }}
                  >
                    {message}
                  </Typography>
                  <div className='otp_input'>
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 0,
                        mx: 'auto',
                        display: 'block',
                        color: 'text.disabled',
                      }}
                    >
                      Aadharcard OTP
                    </Typography>
                    <OTPInput
                      value={aadharotp}
                      onChange={handleAadharOtpChange}
                      numInputs={6}
                      renderInput={(props) => <input {...props} />}
                    />
                  </div>
                </Grid>
              </Grid>
            </FormProvider>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>Cancel</Button>
            <LoadingButton onClick={handleSave} variant="contained" type="button" loading={isSubmitting}
            >
              Verify
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </Container>


  );
}

AadharMemberData.propTypes = {
  onNextButtonClick: PropTypes.func.isRequired,
};
