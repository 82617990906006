/* eslint-disable import/no-extraneous-dependencies */
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import React, { useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { TextField, FormControl, Autocomplete } from '@mui/material';

import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';

import FormProvider, { RHFTextField } from 'src/components/hook-form';

// ----------------------------------------------------------------------

export default function RequestEdit({ currentUser, roleopen, onClose, getData }) {
  const [isSubmitting, setIsSubmitting] = useState();
  const [tableData, setTableData] = useState([]);

  const defaultValues = useMemo(
    () => ({
      role_name: currentUser?.role_name || '',
      display_name: currentUser?.display_name || '',
    }),
    [currentUser]
  );

  const methods = useForm({
    defaultValues,
  });


  const [selectedStatus, setSelectedStatus] = React.useState(null);

  const handleStatusChange = (event, newValue) => {
    setSelectedStatus(newValue);
  };
  const {
    handleSubmit,
  } = methods;


  const onSubmit = handleSubmit(async (data) => {
    try {
      setIsSubmitting(true)
      await ApiService.post(Endpoints.editrole, { ...data, role_id: currentUser?.id }).then(async (response) => {
        const responseData = response.data
        if (responseData?.status === true) {
          await getData();
          toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
          onClose();
        } else {
          toast.error(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
        }

      });
    } catch (error) {
      setIsSubmitting(false)
      console.error("Error submitting form:", error);
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

  });
  const options = [
    { value: '1', label: 'Pending' },
    { value: '2', label: 'Approved' },
    { value: '3', label: 'Rejected' },
  ];

  return (

    <Dialog
      maxWidth={false}
      open={roleopen}
      onClose={onClose}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>Fund Request Udate</DialogTitle>

        <DialogContent className='mt-2'>

          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <FormControl
              sx={{
                flexShrink: 0,
                width: { xs: 5, md: 250 },
              }}
            >
              <Autocomplete
                value={selectedStatus}
                onChange={handleStatusChange}
                options={options}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField {...params} label="select Action" variant="outlined" />
                )}
              />
            </FormControl>

            <RHFTextField name="remark" label="Remark" />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Close
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Submit
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

RequestEdit.propTypes = {
  currentUser: PropTypes.object,
  onClose: PropTypes.func,
  getData: PropTypes.func,
  roleopen: PropTypes.bool,
};
