import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';

import FormProvider from 'src/components/hook-form';
import { useSnackbar } from 'src/components/snackbar';
import { IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { useAuthContext } from 'src/auth/hooks';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';
import { useParams } from 'src/routes/hooks';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import ChangeIconData from 'src/sections/setup-tools/portal-setting/changeicon-data';


// ----------------------------------------------------------------------

export default function PWDManager({ id }) {
  const { user } = useAuthContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [passwrod, setPassword] = useState('');
  const [open, setOpen] = useState(false);
  const [changetype, setSetChangetype] = useState()

  const methods = useForm({
    defaultValues: {
      selected: ['activity_comments', 'application_product'],
    },
  });
  const password = useBoolean();
  const password1 = useBoolean();

  const {
    watch,
    setValue,
    handleSubmit,
  } = methods;

  // const { id } = useParams();

  const [formData, setFormData] = useState({
    confirm_password: '',
    new_password: '',
    // old_password: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData, ...formData,
      [name]: value,
    }));
  };

  const values = watch();

  const onSubmit = handleSubmit(async (data) => {
    try {
      setIsSubmitting(true)
      await ApiService.post(Endpoints.memberprofile.passwordchange, { member_id: id, ...formData }).then(async (response) => {
        const responseData = response.data
        if (responseData?.status === true) {
          toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
        } else {
          toast.error(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
        }
      });
    } catch (error) {
      setIsSubmitting(false)
      console.error("Error submitting form:", error);
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  });

  const getPassword = async () => {
    try {
      // setIsSubmitting(true)
      await ApiService.post(Endpoints.memberprofile.getpassword, { member_id: id, formData }).then(async (response) => {
        const responseData = response.data
        console.log('responseData', responseData)
        if (responseData?.status === true) {
          setPassword(responseData?.data?.password)

        } else {
          toast.error(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
        }
      });
    } catch (error) {
      setIsSubmitting(false)
      console.error("Error submitting form:", error);
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  useEffect(() => {
    getPassword();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleOpen = (type) => {
    setOpen(true);
    setSetChangetype(type)

  };
  return (
    <>
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack spacing={3} sx={{ p: 3 }}>
        <Typography variant="h6" sx={{ textAlign: 'end' }}><IconButton style={{ color: '#000', marginLeft: 'auto' }} onClick={()=>{handleOpen('PASSWORD_CHANGE')}}>
                  <Iconify icon="typcn:info" />
                </IconButton>Current Password : {passwrod}</Typography>
      </Stack>
      <Stack component={Card} spacing={3} sx={{ p: 3 }}>
        <Grid container spacing={3}>
          {/* <Grid xs={12} md={6}>
              <TextField label="Old Password" name='old_password' sx={{ width:'100%' }} onChange={handleInputChange}
                    value={formData.old_password || ''}/>
            </Grid> */}
          <Grid xs={12} md={6}>
            <TextField label="New Password" name='new_password' sx={{ width: '100%' }} onChange={handleInputChange}  type={password.value ? 'text' : 'password'}
              value={formData.new_password || ''} InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={password.onToggle} edge="end">
                      <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}/>
          </Grid>
          <Grid xs={12} md={6}>
            <TextField label="Confirmed Password" name='confirm_password' sx={{ width: '100%' }} onChange={handleInputChange}
              value={formData.confirm_password || ''} type={password1.value ? 'text' : 'password'} InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={password1.onToggle} edge="end">
                      <Iconify icon={password1.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              />
          </Grid>
        </Grid>
        <LoadingButton type="submit" variant="contained" loading={isSubmitting} sx={{ ml: 'auto' }}>
          Reset
        </LoadingButton>
      </Stack>
    </FormProvider>

{open===true?<ChangeIconData open={open} setOpen={setOpen} type={changetype} member_id={id} />:null}
</>
  );
}
PWDManager.propTypes = {
  id: PropTypes.string
};
