import { useMemo } from 'react';

import { paths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
import { useAuthContext } from 'src/auth/hooks';
import Constants, { UsersPermissions } from 'src/common/constant';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);
const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  wallet: icon('ic_wallet'),
  invoice: icon('ic_invoice'),
  resource: icon('ic_resource'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  history: icon('ic_transactionhistory'),
  statement: icon('ic_statement'),
  notification: icon('ic_notification'),
  setting: icon('ic_setting'),
  setup: icon('ic_tools'),
  card: icon('ic_credit'),
  suser: icon('ic_suser'),
  muser: icon('ic_muser'),
  wrench: icon('ic_wrench'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useTranslate();

  const { user } = useAuthContext();
  const permissions = user?.permissions;
  // console.log('permissions', permissions)
  const membertype = user?.member_type;
  // console.log('membertype', membertype)


  const data = useMemo(() => {
    const mainData = [
      {
        subheader: t('navigation'),
        items: [
          {
            title: t('dashborad'),
            path: paths.dashboard.root,
            icon: ICONS.dashboard,
          },
        ],
      },
      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: t('management'),
        items: [
          // resoureces
          // {
          //   title: t('resources'),
          //   path: paths.resources.root,
          //   icon: ICONS.wrench,
          //   children: [
          //     { title: t('Scheme Manager'), path: paths.resources.schememanager },
          //     // { title: t('company manager'), path: paths.resources.companymanager },

          //   ],
          // },
          // {
          //   title: t('member'),
          //   path: paths.member.root,
          //   icon: ICONS.user,
          //   children: [
          //     { title: t('whitelabel'), path: paths.member.whitelabel },
          //     { title: t('master distributor'), path: paths.member.masterdistributor },
          //     { title: t('distributor'), path: paths.member.distributor },
          //     { title: t('retailer'), path: paths.member.retailer },
          //     { title: t('unRegister'), path: paths.member.unregistered },
          //     // { title: t('manager'), path: paths.member.manager },

          //   ],
          // },

          // bankservice
          // {
          //   title: t('bank service'),
          //   path: paths.bankservice.root,
          //   icon: ICONS.user,
          //   children: [
          //     { title: t('aeps'), path: paths.bankservice.aeps },
          //     // { title: t('fAeps'), path: paths.bankservice.faeps },

          //   ],
          // },
          //  // fund
           {
            title: t('fund'),
            path: paths.fund.root,
            icon: ICONS.wallet,
            children: [
              { title: t('load wallet'), path: paths.fund.loadwallet },
              // { title: t('transfer/Return'), path: paths.fund.transferreturn },
              // { title: t('request'), path: paths.fund.request },
              // { title: t('request report'), path: paths.fund.requestreport },
              // { title: t('all fund report'), path: paths.fund.fundreport },
            ],
          },
          // aeps-fund
          // {
          //   title: t('aeps fund'),
          //   path: paths.aepsfund.root,
          //   icon: ICONS.wallet,
          //   children: [
          //     { title: t('request'), path: paths.aepsfund.request },
          //     { title: t('pending request'), path: paths.aepsfund.pendingrequest },
          //     // { title: t('request report'), path: paths.aepsfund.requestreport },
          //   ],
          // },
          // // matm-fund
          // {
          //   title: t('MATM fund'),
          //   path: paths.matmfund.root,
          //   icon: ICONS.wallet,
          //   children: [
          //     { title: t('request'), path: paths.matmfund.request },
          //     { title: t('pending request'), path: paths.matmfund.pendingrequest },
          //     { title: t('request report'), path: paths.matmfund.requestreport },
          //   ],
          // },
          // // htm-fund
          // {
          //   title: t('HMT fund'),
          //   path: paths.hmtfund.root,
          //   icon: ICONS.wallet,
          //   children: [
          //     { title: t('pending request'), path: paths.hmtfund.pendingrequest },
          //     { title: t('request report'), path: paths.hmtfund.requestreport },
          //   ],
          // },
          // // iaeps
          // {
          //   title: t('Agent list'),
          //   path: paths.agentlist.root,
          //   icon: ICONS.user,
          //   children: [
          //     { title: t('IAeps'), path: paths.agentlist.iaeps },
          //   ],
          // },
          // // transaction history
          // {
          //   title: t('transaction history'),
          //   path: paths.transactionhistory.root,
          //   icon: ICONS.history,
          //   children: [
          //     { title: t('Aeps statement'), path: paths.transactionhistory.aepsstatement },
          //     { title: t('change history'), path: paths.transactionhistory.changehistory },
          //     // { title: t('FAeps statement'), path: paths.transactionhistory.faeps },
          //     // { title: t('MATM statement'), path: paths.transactionhistory.matm },
          //     //     { title: t('Billpay statement'), path: paths.transactionhistory.billpay },
          //     // { title: t('Money Tranfer statement'), path: paths.transactionhistory.moneytranfer },
          //     //     { title: t('Recharge statement'), path: paths.transactionhistory.recharge },
          //     //     { title: t('uti pancard statement'), path: paths.transactionhistory.utipancard },
          //     //     { title: t('deposit statement'), path: paths.transactionhistory.deposit },
          //   ],
          // },
          // account statement
          // {
          //   title: t('account statement'),
          //   path: paths.accountstatement.root,
          //   icon: ICONS.statement,
          //   children: [
          //     { title: t('aeps wallet'), path: paths.accountstatement.aepswallet },
          //     // { title: t('Aeps wallet'), path: paths.accountstatement.aepswallet },
          //     // { title: t('MATM wallet'), path: paths.accountstatement.matmwallet },
          //     // { title: t('Aeps ledger'), path: paths.accountstatement.aepsledger },
          //   ],
          // },
          // // complaints
          // {
          //   title: t('complaints'),
          //   path: paths.complaints.root,
          //   icon: ICONS.setting,
          // },
          // // banners
          // {
          //   title: t('banners'),
          //   path: paths.banners.root,
          //   icon: ICONS.wrench,
          //   children: [
          //     { title: t('add banners'), path: paths.banners.addbanners },
          //     { title: t('view banners'), path: paths.banners.viewbanners },
          //   ],
          // },
          // // affiliats
          // {
          //   title: t('affiliate'),
          //   path: paths.affiliate.root,
          //   icon: ICONS.wrench,
          //   children: [
          //     { title: t('categories'), path: paths.affiliate.categories },
          //     { title: t('links'), path: paths.affiliate.links },
          //   ],
          // },
          // leads
          // {
          //   title: t('leads'),
          //   path: paths.leads.root,
          //   icon: ICONS.suser,
          //   children: [
          //     { title: t('categories'), path: paths.leads.categories },
          //     { title: t('sub categories'), path: paths.leads.subcategories },
          //   ],
          // },
          // services
          // {
          //   title: t('services'),
          //   path: paths.services.root,
          //   icon: ICONS.muser,
          //   children: [
          //     // { title: t('new leads'), path: paths.services.newleads },
          //     // { title: t('pending leads'), path: paths.services.pendingleads },
          //     { title: t('all leads'), path: paths.services.allleads },
          //   ],
          // },
          // setup tools
          // {
          //   title: t('setup tools'),
          //   path: paths.setuptools.root,
          //   icon: ICONS.setup,
          //   children: [
          //     { title: t('api manager'), path: paths.setuptools.apimanager },
          //     // { title: t('bank account'), path: paths.setuptools.bankaccount },
          //     // { title: t('complaint subject'), path: paths.setuptools.complaintsubject },
          //     { title: t('operator manager'), path: paths.setuptools.operator },
          //     { title: t('portal setting'), path: paths.setuptools.portal },
          //   ],
          // },
          // // reports
          // {
          //   title: t('Reports'),
          //   path: paths.reports.root,
          //   icon: ICONS.statement,
          //   children: [
          //     { title: t('user contacs'), path: paths.reports.contacts },
          //     { title: t('user installed apps'), path: paths.reports.installedapps },
          //     { title: t('transaction report'), path: paths.reports.transactionreport },
          //     { title: t('yesterday transaction'), path: paths.reports.yesterdaytransaction },
          //     { title: t('monthly report'), path: paths.reports.monthlyreport },
          //     { title: t('balance enquiry report'), path: paths.reports.balanceenquiryreport },
          //     { title: t('not transaction report'), path: paths.reports.nottransactionreport },
          //   ],
          // },
          //  account settings
          {
            title: t('account settings'),
            path: paths.accountsetting.root,
            icon: ICONS.setting,
            children: [
              { title: t('Profile setting'), path: paths.accountsetting.profile },
              // { title: t('certificate'), path: paths.accountsetting.certificate },
              // { title: t('TDS exemption certificate'), path: paths.accountsetting.tdscertificate },
            ],
          },
          // credit card
          // {
          //   title: t('credit card'),
          //   path: paths.creditcard.root,
          //   icon: ICONS.card,
          // },
          // // notification
          // {
          //   title: t('notification'),
          //   path: paths.notification.root,
          //   icon: ICONS.notification,
          // },
          // //  driver links
          // {
          //   title: t('driver links'),
          //   path: paths.driverlinks.root,
          //   icon: ICONS.setting,
          //   children: [
          //     { title: t('mantra'), path: paths.driverlinks.mantra },
          //     { title: t('morpho'), path: paths.driverlinks.morpho },
          //     { title: t('secugen'), path: paths.driverlinks.secugen },
          //     { title: t('precision'), path: paths.driverlinks.precision },
          //     { title: t('NEXT'), path: paths.driverlinks.next },
          //     { title: t('Startek'), path: paths.driverlinks.startek },
          //     { title: t('Tatvik TMF20'), path: paths.driverlinks.tatviktmf20 },
          //   ],
          // },
          // role
          // {
          //   title: t('role & Permission'),
          //   path: paths.role.root,
          //   icon: ICONS.lock,
          //   children: [
          //     { title: t('role'), path: paths.role.root },
          //   ],
          // },


        ],
      },
    ];
    // ----------------------------------------------------------------------------------------------------------------------------------
    // role & permission
    if (membertype === Constants.ROLE.ADMIN) {
      mainData[1].items.unshift({
        title: t('role & Permission'),
        path: paths.role.root,
        icon: ICONS.lock,
        children: [
          { title: t('role'), path: paths.role.root },
        ],
      });
    }

    // setup tools
    if (
      permissions?.includes(UsersPermissions.setuptools.BankAccountSetup) ||
      permissions?.includes(UsersPermissions.setuptools.ApiManager) ||
      permissions?.includes(UsersPermissions.setuptools.OperatorManager)
    ) {
      const children = [];

      if (permissions?.includes(UsersPermissions.setuptools.ApiManager)) {
        children.push({ title: t('api manager'), path: paths.setuptools.apimanager });
      }
      // if (permissions?.includes(UsersPermissions.setuptools.BankAccountSetup)) {
      //   children.push({ title: t('bank account'), path: paths.setuptools.bankaccount });
      // }
      // if (permissions?.includes(UsersPermissions.complaints.ComplaintsSubjectManager)) {
      //   children.push({ title: t('complaint subject'), path: paths.setuptools.complaintsubject });
      // }
      if (permissions?.includes(UsersPermissions.setuptools.OperatorManager)) {
        children.push({ title: t('operator manager'), path: paths.setuptools.operator });
      }
      if (membertype === Constants.ROLE.ADMIN) {
        children.push({ title: t('portal setting'), path: paths.setuptools.portal });
      }
      mainData[1].items.unshift({
        title: t('setup tools'),
        path: paths.setuptools.root,
        icon: ICONS.setup,
        children
      })
    }
    // account statement
    if (
      permissions?.includes(UsersPermissions.usersetting.AccountStatementView) ||
      permissions?.includes(UsersPermissions.usersetting.AepsWalletStatement) ||
      permissions?.includes(UsersPermissions.usersetting.MatmWalletStatement)
    ) {
      const children = [];

      if (permissions?.includes(UsersPermissions.usersetting.AepsWalletStatement)) {
        children.push({ title: t('aeps wallet'), path: paths.accountstatement.aepswallet });
      }
      mainData[1].items.unshift({
        title: t('account statement'),
        path: paths.accountstatement.root,
        icon: ICONS.statement,
        children
      })
    }
    // transaction history
    if (
      permissions?.includes(UsersPermissions.usersetting.AccountStatementView) ||
      permissions?.includes(UsersPermissions.agentsList.UtiAgentList) ||
      permissions?.includes(UsersPermissions.memberreport.UtiPancardReport) ||
      permissions?.includes(UsersPermissions.transactions.RechargeStatement) ||
      permissions?.includes(UsersPermissions.transactions.BillPaymentStatement)
    ) {
      const children = [];

      if (permissions?.includes(UsersPermissions.transactions.AepsStatement)) {
        children.push({ title: t('Aeps statement'), path: paths.transactionhistory.aepsstatement });
      }
      if (membertype === Constants.ROLE.ADMIN) {
        children.push({ title: t('change history'), path: paths.transactionhistory.changehistory });
      }
      mainData[1].items.unshift({
        title: t('transaction history'),
        path: paths.transactionhistory.root,
        icon: ICONS.history,
        children
      });
    }


    // aeps fund
    if (
      permissions?.includes(UsersPermissions.aepsfund.AepsSettlementRequest) ||
      permissions?.includes(UsersPermissions.aepsfundreport.AepsSettlementRequest) ||
      permissions?.includes(UsersPermissions.aepsfundreport.AepsSettlementReport)
    ) {
      const children = [];

      if (permissions?.includes(UsersPermissions.aepsfund.AepsSettlementRequest)) {
        children.push({ title: t('request'), path: paths.aepsfund.pendingrequest });
      }

      if (
        permissions?.includes(UsersPermissions.aepsfundreport.AepsSettlementRequest) ||
        permissions?.includes(UsersPermissions.aepsfundreport.AepsSettlementReport)
      ) {
        children.push({ title: t('all request'), path: paths.aepsfund.request });
      }

      mainData[1].items.unshift({
        title: t('aeps fund'),
        path: paths.aepsfund.root,
        icon: ICONS.wallet,
        children
      });
    }

    // bank service
    if (membertype !== Constants.ROLE.ADMIN &&
      (permissions?.includes(UsersPermissions.portalservices.IAepsService) ||
        permissions?.includes(UsersPermissions.portalservices.DmtBank1) ||
        permissions?.includes(UsersPermissions.portalservices.CashDeposit) ||
        permissions?.includes(UsersPermissions.portalservices.AadharpayService) ||
        permissions?.includes(UsersPermissions.portalservices.PaytmDeposit) ||
        permissions?.includes(UsersPermissions.portalservices.MobikwikDeposit)
      )
    ) {
      const children = [];
      if (permissions?.includes(UsersPermissions.portalservices.IAepsService)) {
        children.push({ title: t('aeps'), path: paths.bankservice.aeps })
      }
      // if (permissions?.includes(UsersPermissions.portalservices.FAepsService) ) {
      //   children.push( { title: t('FAeps'), path: paths.bankservice.faeps })
      // }
      mainData[1].items.unshift({
        title: t('bank service'),
        path: paths.bankservice.root,
        icon: ICONS.user,
        children
      })
    }
    // member
    if ((
      membertype === Constants.ROLE.ADMIN ||
      permissions?.includes(UsersPermissions.member.ManageDistributor) ||
      permissions?.includes(UsersPermissions.member.ManageMasterDistributor) ||
      permissions?.includes(UsersPermissions.member.ManageRetailer)
    )) {
      const children = [];
      if (membertype === Constants.ROLE.ADMIN) {
        children.push({ title: t('whitelabel'), path: paths.member.whitelabel })
      }
      if (membertype === Constants.ROLE.ADMIN || permissions?.includes(UsersPermissions.member.ManageMasterDistributor)) {
        children.push({ title: t('master distributor'), path: paths.member.masterdistributor })
      }
      if (membertype === Constants.ROLE.ADMIN || permissions?.includes(UsersPermissions.member.ManageDistributor)) {
        children.push({ title: t('distributor'), path: paths.member.distributor })
      }

      if (membertype === Constants.ROLE.ADMIN || permissions?.includes(UsersPermissions.member.ManageRetailer)) {
        children.push({ title: t('retailer'), path: paths.member.retailer })
      }
      if (membertype === Constants.ROLE.ADMIN) {
        children.push({ title: t('unRegister'), path: paths.member.unregistered })
      }
      mainData[1].items.unshift({
        title: t('member'),
        path: paths.member.root,
        icon: ICONS.user,
        children
      })
    }

    // resources
    if (membertype === Constants.ROLE.ADMIN && (
      permissions?.includes(UsersPermissions.resources.ChangeCompanyProfile)
    ) && membertype !== Constants.ROLE.RETAILER) {

      const children = [];
      if (membertype !== Constants.ROLE.RETAILER || membertype === Constants.ROLE.ADMIN) {
        children.push({ title: t('Scheme Manager'), path: paths.resources.schememanager })
      }
      if (permissions?.includes(UsersPermissions.resources.ChangeCompanyProfile) || membertype === Constants.ROLE.ADMIN) {
        children.push({ title: t('company manager'), path: paths.resources.companymanager },)
      }
      mainData[1].items.unshift({
        title: t('resources'),
        path: paths.resources.root,
        icon: ICONS.wrench,
        children,
      });
    }
    return mainData;
  }, [t, membertype, permissions]
    // () => [
    //   {
    //     subheader: t('navigation'),
    //     items: [
    //       {
    //         title: t('dashborad'),
    //         path: paths.dashboard.root,
    //         icon: ICONS.dashboard,
    //       },
    //     ],
    //   },
    //   // MANAGEMENT
    //   // ----------------------------------------------------------------------
    //   {
    //     subheader: t('management'),
    //     items: [
    //       // resoureces
    //       {
    //         title: t('resources'),
    //         path: paths.resources.root,
    //         icon: ICONS.wrench,
    //         children: [
    //            { title: t('Scheme Manager'), path: paths.resources.schememanager },
    //           // { title: t('company manager'), path: paths.resources.companymanager },

    //         ],
    //       },
    //       {
    //         title: t('member'),
    //         path: paths.member.root,
    //         icon: ICONS.user,
    //         children: [
    //           { title: t('whitelabel'), path: paths.member.whitelabel },
    //           { title: t('master distributor'), path: paths.member.masterdistributor },
    //           { title: t('distributor'), path: paths.member.distributor },
    //           { title: t('retailer'), path: paths.member.retailer },
    //           { title: t('unRegister'), path: paths.member.unregistered },
    //           // { title: t('manager'), path: paths.member.manager },

    //         ],
    //       },

    //       // bankservice
    //       {
    //         title: t('bank service'),
    //         path: paths.bankservice.root,
    //         icon: ICONS.user,
    //         children: [
    //           { title: t('aeps'), path: paths.bankservice.aeps },
    //           // { title: t('fAeps'), path: paths.bankservice.faeps },

    //         ],
    //       },
    //       //  // fund
    //       //  {
    //       //   title: t('fund'),
    //       //   path: paths.fund.root,
    //       //   icon: ICONS.wallet,
    //       //   children: [
    //       //     { title: t('transfer/Return'), path: paths.fund.transferreturn },
    //       //     { title: t('request'), path: paths.fund.request },
    //       //     { title: t('request report'), path: paths.fund.requestreport },
    //       //     { title: t('all fund report'), path: paths.fund.fundreport },
    //       //   ],
    //       // },
    //       // aeps-fund
    //       {
    //         title: t('aeps fund'),
    //         path: paths.aepsfund.root,
    //         icon: ICONS.wallet,
    //         children: [
    //           { title: t('request'), path: paths.aepsfund.request },
    //           { title: t('pending request'), path: paths.aepsfund.pendingrequest },
    //           // { title: t('request report'), path: paths.aepsfund.requestreport },
    //         ],
    //       },
    //       // // matm-fund
    //       // {
    //       //   title: t('MATM fund'),
    //       //   path: paths.matmfund.root,
    //       //   icon: ICONS.wallet,
    //       //   children: [
    //       //     { title: t('request'), path: paths.matmfund.request },
    //       //     { title: t('pending request'), path: paths.matmfund.pendingrequest },
    //       //     { title: t('request report'), path: paths.matmfund.requestreport },
    //       //   ],
    //       // },
    //       // // htm-fund
    //       // {
    //       //   title: t('HMT fund'),
    //       //   path: paths.hmtfund.root,
    //       //   icon: ICONS.wallet,
    //       //   children: [
    //       //     { title: t('pending request'), path: paths.hmtfund.pendingrequest },
    //       //     { title: t('request report'), path: paths.hmtfund.requestreport },
    //       //   ],
    //       // },
    //       // // iaeps
    //       // {
    //       //   title: t('Agent list'),
    //       //   path: paths.agentlist.root,
    //       //   icon: ICONS.user,
    //       //   children: [
    //       //     { title: t('IAeps'), path: paths.agentlist.iaeps },
    //       //   ],
    //       // },
    //       // // transaction history
    //       {
    //         title: t('transaction history'),
    //         path: paths.transactionhistory.root,
    //         icon: ICONS.history,
    //         children: [
    //           { title: t('Aeps statement'), path: paths.transactionhistory.aepsstatement },
    //           { title: t('change history'), path: paths.transactionhistory.changehistory },
    //           // { title: t('FAeps statement'), path: paths.transactionhistory.faeps },
    //           // { title: t('MATM statement'), path: paths.transactionhistory.matm },
    //       //     { title: t('Billpay statement'), path: paths.transactionhistory.billpay },
    //           // { title: t('Money Tranfer statement'), path: paths.transactionhistory.moneytranfer },
    //       //     { title: t('Recharge statement'), path: paths.transactionhistory.recharge },
    //       //     { title: t('uti pancard statement'), path: paths.transactionhistory.utipancard },
    //       //     { title: t('deposit statement'), path: paths.transactionhistory.deposit },
    //         ],
    //       },
    //       // account statement
    //       {
    //         title: t('account statement'),
    //         path: paths.accountstatement.root,
    //         icon: ICONS.statement,
    //         children: [
    //           { title: t('aeps wallet'), path: paths.accountstatement.aepswallet },
    //           // { title: t('Aeps wallet'), path: paths.accountstatement.aepswallet },
    //           // { title: t('MATM wallet'), path: paths.accountstatement.matmwallet },
    //           // { title: t('Aeps ledger'), path: paths.accountstatement.aepsledger },
    //         ],
    //       },
    //       // // complaints
    //       // {
    //       //   title: t('complaints'),
    //       //   path: paths.complaints.root,
    //       //   icon: ICONS.setting,
    //       // },
    //       // // banners
    //       // {
    //       //   title: t('banners'),
    //       //   path: paths.banners.root,
    //       //   icon: ICONS.wrench,
    //       //   children: [
    //       //     { title: t('add banners'), path: paths.banners.addbanners },
    //       //     { title: t('view banners'), path: paths.banners.viewbanners },
    //       //   ],
    //       // },
    //       // // affiliats
    //       // {
    //       //   title: t('affiliate'),
    //       //   path: paths.affiliate.root,
    //       //   icon: ICONS.wrench,
    //       //   children: [
    //       //     { title: t('categories'), path: paths.affiliate.categories },
    //       //     { title: t('links'), path: paths.affiliate.links },
    //       //   ],
    //       // },
    //       // leads
    //       // {
    //       //   title: t('leads'),
    //       //   path: paths.leads.root,
    //       //   icon: ICONS.suser,
    //       //   children: [
    //       //     { title: t('categories'), path: paths.leads.categories },
    //       //     { title: t('sub categories'), path: paths.leads.subcategories },
    //       //   ],
    //       // },
    //       // services
    //       // {
    //       //   title: t('services'),
    //       //   path: paths.services.root,
    //       //   icon: ICONS.muser,
    //       //   children: [
    //       //     // { title: t('new leads'), path: paths.services.newleads },
    //       //     // { title: t('pending leads'), path: paths.services.pendingleads },
    //       //     { title: t('all leads'), path: paths.services.allleads },
    //       //   ],
    //       // },
    //       // setup tools
    //       {
    //         title: t('setup tools'),
    //         path: paths.setuptools.root,
    //         icon: ICONS.setup,
    //         children: [
    //           { title: t('api manager'), path: paths.setuptools.apimanager },
    //           // { title: t('bank account'), path: paths.setuptools.bankaccount },
    //           // { title: t('complaint subject'), path: paths.setuptools.complaintsubject },
    //           { title: t('operator manager'), path: paths.setuptools.operator },
    //           { title: t('portal setting'), path: paths.setuptools.portal },
    //         ],
    //       },
    //       // // reports
    //       // {
    //       //   title: t('Reports'),
    //       //   path: paths.reports.root,
    //       //   icon: ICONS.statement,
    //       //   children: [
    //       //     { title: t('user contacs'), path: paths.reports.contacts },
    //       //     { title: t('user installed apps'), path: paths.reports.installedapps },
    //       //     { title: t('transaction report'), path: paths.reports.transactionreport },
    //       //     { title: t('yesterday transaction'), path: paths.reports.yesterdaytransaction },
    //       //     { title: t('monthly report'), path: paths.reports.monthlyreport },
    //       //     { title: t('balance enquiry report'), path: paths.reports.balanceenquiryreport },
    //       //     { title: t('not transaction report'), path: paths.reports.nottransactionreport },
    //       //   ],
    //       // },
    //       //  account settings
    //       {
    //         title: t('account settings'),
    //         path: paths.accountsetting.root,
    //         icon: ICONS.setting,
    //         children: [
    //           { title: t('Profile setting'), path: paths.accountsetting.profile },
    //           // { title: t('certificate'), path: paths.accountsetting.certificate },
    //           // { title: t('TDS exemption certificate'), path: paths.accountsetting.tdscertificate },
    //         ],
    //       },
    //       // credit card
    //       // {
    //       //   title: t('credit card'),
    //       //   path: paths.creditcard.root,
    //       //   icon: ICONS.card,
    //       // },
    //       // // notification
    //       // {
    //       //   title: t('notification'),
    //       //   path: paths.notification.root,
    //       //   icon: ICONS.notification,
    //       // },
    //       // //  driver links
    //       // {
    //       //   title: t('driver links'),
    //       //   path: paths.driverlinks.root,
    //       //   icon: ICONS.setting,
    //       //   children: [
    //       //     { title: t('mantra'), path: paths.driverlinks.mantra },
    //       //     { title: t('morpho'), path: paths.driverlinks.morpho },
    //       //     { title: t('secugen'), path: paths.driverlinks.secugen },
    //       //     { title: t('precision'), path: paths.driverlinks.precision },
    //       //     { title: t('NEXT'), path: paths.driverlinks.next },
    //       //     { title: t('Startek'), path: paths.driverlinks.startek },
    //       //     { title: t('Tatvik TMF20'), path: paths.driverlinks.tatviktmf20 },
    //       //   ],
    //       // },
    //       // role
    //       {
    //         title: t('role & Permission'),
    //         path: paths.role.root,
    //         icon: ICONS.lock,
    //         children: [
    //           { title: t('role'), path: paths.role.root },
    //         ],
    //       },


    //     ],
    //   },
    // ],

  );

  return data;
}
