'use client'

/* eslint-disable import/no-extraneous-dependencies */


// ----------------------------------------------------------------------

import { toast } from "react-toastify";
import React, { useMemo, useState, useEffect, useCallback } from "react";

import { Box } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { Grid, Table, TableRow, Checkbox, TableBody, TableCell, TableHead, TableContainer, FormControlLabel } from "@mui/material";

import ApiService from "src/common";
import Endpoints from "src/common/endpoints";


export default function PermissionItem({...prop}) {
  const apiResponse = useMemo(() => prop.permissionsData, [prop.permissionsData]);

  const [checkboxState, setCheckboxState] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const data =apiResponse;

      const initialState = {};
      data.forEach((section) => {
        initialState[section.typeLabel] = {};
        section.permissions.forEach((permission) => {
          initialState[section.typeLabel][permission.id] = prop?.selectedPermissions?.includes(permission.id);
        });
      });
      setCheckboxState(initialState);
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle errors here, e.g., show an error message to the user
    }
  }, [apiResponse, prop?.selectedPermissions]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);


  const handleCheckboxChange = (section, id) => (event) => {
    if (id === section) {
      const isChecked = event.target.checked;

      setCheckboxState((prevState) => ({
        ...prevState,
        [section]: Object.keys(prevState[section]).reduce((acc, permId) => {
          acc[permId] = isChecked;
          return acc;
        }, {}),
      }));
    } else {
      setCheckboxState((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          [id]: event.target.checked,
        },
      }));
    }
  };

  const handleSubmit = async () => {
    const selectedCheckboxIds = [];
    Object.keys(checkboxState).forEach((section) => {
      Object.keys(checkboxState[section]).forEach((id) => {
        if (checkboxState[section][id]) {
          selectedCheckboxIds.push(parseInt(id, 10));
        }
      });
    });

    try {
      setIsSubmitting(true)
      const response = await ApiService.post(Endpoints.edituserpermission, { user_id: prop?.user_id, permissions: selectedCheckboxIds });
      const responseData = response.data;
      if (responseData?.status === true) {
        prop?.onCloseDrawer();
        setIsSubmitting(false)
      } else {
        toast.error(responseData.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      setIsSubmitting(false)
      toast.error('Error submitting form', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

  };

  return (
    <>
      <TableContainer>
        <Table className="role_table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "19%" }}>Section Category</TableCell>
              <TableCell >Permissions</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(checkboxState).map((section) => (
              <TableRow key={section}>
                <TableCell component="th" scope="row">
                  <FormControlLabel
                    sx={{ fontSize: '14px' }}
                    control={
                      <Checkbox
                        id={section}
                        checked={Object.values(checkboxState[section]).every(Boolean)}
                        onChange={handleCheckboxChange(section, section)}
                      />
                    }
                    label={section}
                  />
                </TableCell>
                <TableCell>
                  <Grid container>
                    {Object.keys(checkboxState[section]).map((id) => (
                      <Grid lg={4} md={6} key={id}>
                        <FormControlLabel
                          sx={{ fontSize: '14px' }}
                          control={
                            <Checkbox
                              id={id}
                              checked={checkboxState[section][id]}
                              onChange={handleCheckboxChange(section, id)}
                            />
                          }
                          label={apiResponse
                            .find(item => item.typeLabel === section)
                            ?.permissions.find(permission => permission.id === parseInt(id, 10))?.label || 'Label not found'}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

      </TableContainer>
      <Box sx={{ mt: 5, textAlign: 'center', mr: 2 }}>
        <LoadingButton onClick={handleSubmit} variant="contained" loading={isSubmitting}>
          Submit
        </LoadingButton>
      </Box>

    </>
  );
}

// ----------------------------------------------------------------------



