/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { useMockedUser } from 'src/hooks/use-mocked-user';

import { fData } from 'src/utils/format-number';

import { countries } from 'src/assets/data';

import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFSwitch,
  RHFTextField,
  RHFUploadAvatar,
  RHFAutocomplete,
} from 'src/components/hook-form';
import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

export default function ProfileSettings() {
  const { enqueueSnackbar } = useSnackbar();
  const [userData, setUserData] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);




  const methods = useForm({
    // defaultValues,
  });

  const {
    setValue,
  } = methods;

  const getProfile = async (e) => {
    try {
      setIsSubmitting(true)
      await ApiService.post(Endpoints.changeprofile.getprofile).then(async (response) => {
        const responseData = response.data
        if (responseData?.status === true) {
          // setUserData(responseData.data);

          // Set values in the form fields using setValue
          setValue('id', responseData.data.id || '');
          setValue('first_name', responseData.data.first_name || '');
          setValue('last_name', responseData.data.last_name || '');
          setValue('mobile_no', responseData.data.mobile_no || '');
          setValue('email', responseData.data.email || '');
          setValue('country', responseData.data.country || '');
          setValue('state', responseData.data.state || '');
          setValue('address', responseData.data.address || '');
          setValue('city', responseData.data.city || '');
          setValue('pincode', responseData.data.pincode || '');
          toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
          setIsDataLoading(false)
        } else {
          toast.error(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
        }
      });
    } catch (error) {
      setIsSubmitting(false)
      console.error("Error submitting form:", error);
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  useEffect(() => {
       getProfile();
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();
    // try {
    //   setIsSubmitting(true)
    //   await ApiService.post(Endpoints.changeprofile.getprofile).then(async (response) => {
    //     const responseData = response.data
    //     if (responseData?.status === true) {
    //       setUserData(responseData.data);
    //       toast.success(responseData.message, {
    //         position: toast.POSITION.TOP_RIGHT,
    //       });
    //       setIsSubmitting(false)
    //     } else {
    //       toast.error(responseData.message, {
    //         position: toast.POSITION.TOP_RIGHT,
    //       });
    //       setIsSubmitting(false)
    //     }
    //   });
    // } catch (error) {
    //   setIsSubmitting(false)
    //   console.error("Error submitting form:", error);
    //   toast.error("Error submitting form", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // }
  };

  const handleMobileNoKeyPress = (e) => {
    // Allow only numeric digits (0-9)
    if (e.key && !/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
    if (e.target.value.length >= 10) {
      e.preventDefault();
    }
  };


  return (
    <>
   {isDataLoading===false  ?
     <FormProvider methods={methods} onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 2fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField name="first_name" label="First Name" autoComplete="off" />
              <RHFTextField name="last_name" label="Last Name" autoComplete="off" />
              <RHFTextField name="mobile_no" label="Phone Number"onKeyPress={handleMobileNoKeyPress}
              onInput={(e) => { setValue('mobile_no', e.target.value.replace(/[^0-9]/g, '').slice(0, 10)) }} />
              <RHFTextField name="email" label="Email Address" />
              <RHFAutocomplete
                name="country"
                label="Country"
                autoComplete="off"
                options={countries.map((country) => country.label)}
                getOptionLabel={(option) => option}
                renderOption={(props, option) => {
                  const { code, label, phone } = countries.filter(
                    (country) => country.label === option
                  )[0];

                  if (!label) {
                    return null;
                  }

                  return (
                    <li {...props} key={label}>
                      <Iconify
                        key={label}
                        icon={`circle-flags:${code.toLowerCase()}`}
                        width={28}
                        sx={{ mr: 1 }}
                      />
                      {label} ({code}) +{phone}
                    </li>
                  );
                }}
              />

              <RHFTextField name="state" label="State/Region" autoComplete="off" />
              <RHFTextField name="city" label="City" autoComplete="off" />
              <RHFTextField name="pincode" label="Zip/Code" autoComplete="off" />
              <RHFTextField name="address" multiline label="Address" />
            </Box>


            <span style={{ display: 'block', textAlign: 'center' }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting} sx={{ mt: 3 }}>
                Save Changes
              </LoadingButton>
            </span>

          </Card>
        </Grid>
      </Grid>
    </FormProvider>
    :null}
    </>
  );
}
