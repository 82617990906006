import { useState, useCallback } from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Container from '@mui/material/Container';

import { paths } from 'src/routes/paths';


import { Card, Grid, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import Iconify from 'src/components/iconify';
import { useParams } from 'src/routes/hooks';
import { useAuthContext } from 'src/auth/hooks';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import Constants from 'src/common/constant';
import ProfileSettings from './profile-setting';
import KYCDetails from './kyc-details';
import PWDManager from './pwd-manager';
import BankDetails from './bank-details';
import RoleManager from './role-manager';
import MappingManager from './mapping-manager';


// ----------------------------------------------------------------------

const TABS = [
  {
    value: 'profile',
    label: 'Profile Details',
    icon: <Iconify icon="solar:user-id-bold" width={24} />,
  },
  {
    value: 'kyc',
    label: 'Kyc Details',
    icon: <Iconify icon="solar:bill-list-bold" width={24} />,
  },
  {
    value: 'pwd',
    label: 'Password Manager',
    icon: <Iconify icon="ic:round-vpn-key" width={24} />,
  },
  // <Iconify icon="solar:bell-bing-bold" width={24} />
  // <Iconify icon="solar:share-bold" width={24} />
  {
    value: 'bank',
    label: 'Bank Details',
    icon: <Iconify icon="solar:bill-list-bold" width={24} />,
  },


];

// ----------------------------------------------------------------------

export default function MemberProfile() {
  const settings = useSettingsContext();
  const { user } = useAuthContext();

  const roleExists = TABS.some(tab => tab.value === 'role');

  if (user?.member_type !== Constants.ROLE.RETAILER) {
    if (!roleExists) {
      TABS.push({
        value: 'role',
        label: 'Role Manager',
        icon: <Iconify icon="solar:share-bold" width={24} />,
      });
    }

    // Check if the 'mapping' tab exists, if not, add it
    if (!TABS.some(tab => tab.value === 'mapping')) {
      TABS.push({
        value: 'mapping',
        label: 'Mapping Manager',
        icon: <Iconify icon="solar:share-bold" width={24} />,
      });
    }
  }


  const [currentTab, setCurrentTab] = useState('profile');
  const { id } = useParams();
  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading="Profile"
        links={[
          { name: 'Dashboard', href: paths.dashboard.root },
          { name: 'User' },
          { name: 'Profile' },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <Grid container spacing={3}>
        {/* Left Sidebar (Tabs) */}
        <Grid xs={12} md={4} item >
          <Card sx={{ py: 5, px: 3 }}>
            <Tabs
              value={currentTab}
              onChange={handleChangeTab}
              orientation="vertical"
              sx={{
                borderRight: 1,
                borderColor: 'divider',

              }}
            // sx={{
            //   mb: { xs: 3, md: 5 },
            // }}
            >
              {TABS.map((tab) => (
                <Tab key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} sx={{ justifyContent: 'flex-start' }} />
              ))}
            </Tabs>
          </Card>
        </Grid>

        {/* Right Content Area (Based on selected tab) */}
        <Grid xs={12} md={8} item >
          {/* Render content based on the selected tab */}
          {currentTab === 'profile' && <ProfileSettings />}
          {currentTab === 'kyc' && <KYCDetails />}
          {currentTab === 'pwd' && <PWDManager id={id} />}
          {currentTab === 'bank' && <BankDetails />}
          {
            (user?.member_type !== Constants.ROLE.RETAILER) && (
              <>
                {currentTab === 'role' && <RoleManager />}
                {currentTab === 'mapping' && <MappingManager />}
              </>
            )
          }


        </Grid>
      </Grid>


    </Container>
  );
}
