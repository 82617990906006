'use client';

/* eslint-disable import/no-extraneous-dependencies */

import { useForm } from 'react-hook-form';
import React, { useState, useCallback, useEffect } from 'react';

import { Stack } from '@mui/system';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import { DatePicker } from '@mui/x-date-pickers';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import {
  Grid,
  Dialog,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  DialogTitle,
  FormControl,
  DialogActions,
  DialogContent,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
  Autocomplete,
} from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';

import { _userList } from 'src/_mock';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import FormProvider from 'src/components/hook-form/form-provider';
import {
  useTable,
  getComparator,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';
import { toast } from 'react-toastify';
import WhiteLabelTable from './whitelabel-table';
import RetailerTable from '../retailer/retailer-table';
// import { notFound } from 'next/navigation';

// const STATUS_OPTIONS = [{ value: 'all', label: 'All' }, ...USER_STATUS_OPTIONS];

const TABLE_HEAD = [
  { id: 'id', label: 'Id', width: 120 },
  { id: 'name', label: 'Name' }, // Set an appropriate width value
  { id: 'parent', label: 'Parent Details' }, // Set an appropriate width value
  { id: 'other', label: 'Other Info' }, // Set an appropriate width value
  { id: 'wallet', label: 'Wallet Details' }, // Set an appropriate width value
  { id: 'business', label: 'Total Business' }, // Set an appropriate width value
  // { id: 'status', label: 'Payment Status' }, // Optionally set a width value
  { id: 'action', label: 'Action' }, // Set an appropriate width value
];

// ----------------------------------------------------------------------

export default function WhitelabelData() {
  const table = useTable({ defaultRowsPerPage: 10 });

  const settings = useSettingsContext();

  const router = useRouter();

  const confirm = useBoolean();

  const [tableData, setTableData] = useState([]);
  const [apiLimitAndSkip, setApiLimitAndSkip] = useState({ limit: 10, skip: 0 });
  const [totalrecored, setTotalrecored] = useState([]);

  const [open, setOpen] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [permissionsData, setPermissionsData] = useState([]);

  const [apitableData, setApiTableData] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const methods = useForm();

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    mobile_no: '',
    address: '',
    state: '',
    city: '',
    pincode: '',
    pan_no: '',
    aadhar_no: '',
    schema_id: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      ...formData,
      [name]: value,
    }));
  };

  // const onSubmit = async (data) => {
  //   try {
  //     setIsSubmitting(true);
  //     await ApiService.post(Endpoints.createretailers, formData).then(async (response) => {
  //       const responseData = response.data;
  //       if (responseData?.status === true) {
  //         console.log(response.data);
  //         await getTableData();
  //         handleClose();
  //         toast.success(responseData.message, {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //         handleClose();
  //         setIsSubmitting(false);
  //       } else {
  //         toast.error(responseData.message, {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //         setIsSubmitting(false);
  //       }
  //     });
  //   } catch (error) {
  //     setIsSubmitting(false);
  //     console.error('Error submitting form:', error);
  //     toast.error('Error submitting form', {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //   }
  // };

  // const dataFiltered = applyFilter({
  //   inputData: tableData,
  //   comparator: getComparator(table.order, table.orderBy),
  // });

  const [selectedStatus, setSelectedStatus] = useState('');

    const handleStatusChange = (event) => {
  // const statusValue = event.target.value;
  setSelectedStatus(event.target.value);
  // setFilters((prevFilters) => ({
  //   ...prevFilters,
  //   status: statusValue,
  // }));
};
  const [filters, setFilters] = useState({
    start_date: new Date(),  // You may set the initial start date based on your requirement
    end_date: new Date(),    // You may set the initial end date based on your requirement
  });

  const handleFilterStartDate = (date) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      start_date: date,
    }));
  };

  const handleFilterEndDate = (date) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      end_date: date,
    }));
  };

  const handleFilterName = (value) => {
    // Update the filters state with the new value
    setFilters((prevFilters) => ({ ...prevFilters, search: value }));
  };
  const handleFilterId = (value) => {
    // Update the filters state with the new value
    setFilters((prevFilters) => ({ ...prevFilters, user_id: value }));
  };


  const handleEditRow = useCallback(
    (id) => {
      router.push(`${paths.member.whitelabel}`);
    },
    [router]
  );

  const getTableData = async (limit, page, start_date, end_date, search, user_id) => {
    setIsSubmitting(true);

    try {
        const response = await ApiService.post(Endpoints.getwhitelabel,
            {
                start_date: start_date instanceof Date ? start_date.toISOString().split('T')[0] : null,
                end_date: end_date instanceof Date ? end_date.toISOString().split('T')[0] : null,
                search,
                user_id,
            },
            {
                params: {
                    limit: Number(limit),
                    page: Number(page),
                },
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        const responseData = response.data;
        console.log(responseData);

        table.setPage(page);
        table.setRowsPerPage(limit);

        setTotalrecored(responseData?.totalrecored);
        setTableData(responseData.data);
        setIsSubmitting(false);
    } catch (error) {
        setIsSubmitting(false);
        console.error('Error submitting form:', error);
        toast.error('Error submitting form', {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
};


  const handleSearchClick = () => {
    getTableData(
      table.rowsPerPage,
      0,
      filters.start_date,
      filters.end_date,
      filters.search,
      filters.user_id
    );
  };

  const getPermissionData = async () => {
    try {
      ApiService.post(Endpoints.rolegetpermission).then((response) => {
        const responseData = response.data;
        if (responseData?.status === true) {
          setPermissionsData(responseData?.data);
        }
      });
    } catch (error) {
      toast.error('Error submitting form', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const getApiData = async () => {
    try {
      ApiService.post(Endpoints.getAllSchema).then((response) => {
        const responseData = response.data;
        console.log('responseData', responseData);
        if (responseData?.status === true) {
          const apidata = responseData.data.map((element) => ({
            value: element.id,
            label: element.schema_name,
          }));
          console.log('responseData.data', apidata);
          setApiTableData(apidata);
        }
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Error submitting form', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const [selectedApi, setSelectedApi] = React.useState(null);

  const handleApiChange = (event, newValue) => {
    console.log('event, newValue', event, newValue);
    if (newValue?.value) {
      setSelectedApi(newValue);
      setFormData({ ...formData, schema_id: newValue.value ? newValue.value : null });
    }
  };
  const RefreshData = () =>{
    try {
      ApiService.post(Endpoints.getwhitelabel, null, { params: { limit:10, page:0 } }).then(
        (response) => {
          const responseData = response.data;
          console.log(response.data);
          setTotalrecored(responseData?.totalrecored);
          setTableData(responseData.data);
          setIsSubmitting(false);
        }
      );
    } catch (error) {
      setIsSubmitting(false);
      console.error('Error submitting form:', error);
      toast.error('Error submitting form', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  useEffect(() => {
    getApiData();
    // getTableData();
    RefreshData();
    getPermissionData();
  }, []);
  const handleRefreshClick = () => {
    const clearedFilters = {
        start_date: null,
        end_date: null,
        search: '',
        user_id: '',
        status:'',
    };

    setFilters(clearedFilters);

    RefreshData();
};
const routerPush = useCallback(
  () => {
    router.push(`${paths.member.createmember}`);
  },
  [router]
);
  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Whitelabel"
          links={[
            { name: 'Dashboard', href: paths.dashboard.root },
            { name: 'Member' },
            { name: 'Whitelabel' },
          ]}
          action={
            <>
              <LoadingButton
                // onClick={handleOpen}
                variant="contained"
                startIcon={<Iconify icon="mdi:refresh" />}
                sx={{ mr: 1 }}
                loading={isSubmitting}
                onClick={handleRefreshClick}
              >
                Refresh
              </LoadingButton>
              <Button
                onClick={routerPush}
                variant="contained"
                startIcon={<Iconify icon="mingcute:add-line" />}
                sx={{ mr: 1 }}
              >
                Add New
              </Button>
              <LoadingButton
                variant="contained"
                startIcon={<Iconify icon="mdi:file-excel" />}
                loading={isSubmitting}
                // onClick={getTableData}
              >
                Export
              </LoadingButton>
            </>
          }
          sx={{ mb: { xs: 3, md: 5 } }}
        />

        <Card>
          <Stack
            spacing={2}
            alignItems={{ xs: 'flex-end', md: 'center' }}
            direction={{
              xs: 'column',
              md: 'row',
            }}
            sx={{
              p: 2.5,
            }}
          >
            <DatePicker
              label="Start date"
              format="dd/MM/yyyy"
              value={filters.start_date}
              onChange={handleFilterStartDate}
              slotProps={{ textField: { fullWidth: true } }}
              sx={{
                maxWidth: { md: 150 },
              }}
            />

            <DatePicker
              label="End date"
              format="dd/MM/yyyy"
              value={filters.end_date}
              onChange={handleFilterEndDate}
              slotProps={{
                textField: {
                  fullWidth: true,
                  // error: dateError,
                },
              }}
              sx={{
                maxWidth: { md: 150 },
              }}
            />

            <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
              <TextField
                fullWidth
                value={filters.search}
                onChange={(e) => handleFilterName(e.target.value)}
                placeholder="Search..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                value={filters.user_id}
                onChange={(e) => handleFilterId(e.target.value)}
                placeholder="Agent Id"
              />
            </Stack>

            <FormControl
              sx={{
                flexShrink: 0,
                width: { xs: 5, md: 130 },
              }}
            >
              <InputLabel>Status</InputLabel>

              <Select
                value={selectedStatus}
                onChange={handleStatusChange}
                input={<OutlinedInput label="Name" />}
                renderValue={(selected) => selected}
                MenuProps={{
                  PaperProps: {
                    sx: { maxHeight: 240 },
                  },
                }}
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="block">Block</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              sx={{
                flexShrink: 0,
                width: { xs: 5, md: 130 },
              }}
            >
              <Button
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:search-fill" />}
                sx={{ height: 50 }}
                onClick={handleSearchClick}
              >
                Search
              </Button>
            </FormControl>
          </Stack>

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              numSelected={table.selected.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) =>
                table.onSelectAllRows(
                  checked,
                  tableData?.map((row) => row.id)
                )
              }
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={confirm.onTrue}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={table.selected.length}
                  // onSort={table.onSort}
                />
                {isSubmitting ? (
                  <CircularProgress
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      margin: 'auto',
                    }}
                    size={30}
                  />
                ) : (
                  <TableBody>
                    {tableData?.map((row) => (
                        <RetailerTable
                          key={row.id}
                          row={row}
                          permissionsData={permissionsData}
                          selected={table.selected.includes(row.id)}
                          onSelectRow={() => table.onSelectRow(row.id)}
                          onEditRow={() => handleEditRow(row.id)}
                        />
                      ))}

                    {/* <TableNoData notFound={notFound} /> */}
                  </TableBody>
                )}
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={totalrecored}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            // onPageChange={table.onChangePage}
            rowsPerPageOptions={[10, 25, 50]}
            onRowsPerPageChange={(e) => {
              getTableData(e.target.value,0);
            }}
            onPageChange={(a,b)=>{getTableData(table.rowsPerPage,b);}}
          />
        </Card>
      </Container>

      {/* <Dialog open={open} onClose={handleClose} className="add_dialog">
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle>Create Retailer</DialogTitle>
          <DialogContent>
            <FormProvider {...methods}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="First Name"
                    name="first_name"
                    onChange={handleInputChange}
                    value={formData.first_name || ''}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Last Name"
                    name="last_name"
                    onChange={handleInputChange}
                    value={formData.last_name || ''}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Mobile"
                    name="mobile_no"
                    onChange={handleInputChange}
                    value={formData.mobile_no || ''}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Email"
                    name="email"
                    onChange={handleInputChange}
                    value={formData.email || ''}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Address"
                    name="address"
                    onChange={handleInputChange}
                    value={formData.address || ''}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="State"
                    name="state"
                    onChange={handleInputChange}
                    value={formData.state || ''}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="City"
                    name="city"
                    onChange={handleInputChange}
                    value={formData.city || ''}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Pincode"
                    name="pincode"
                    onChange={handleInputChange}
                    value={formData.pincode || ''}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Pancard No"
                    name="pan_no"
                    onChange={handleInputChange}
                    value={formData.pan_no || ''}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Aadharcard No"
                    name="aadhar_no"
                    onChange={handleInputChange}
                    value={formData.aadhar_no || ''}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl
                    sx={{
                      flexShrink: 0,
                      width: { xs: 5, md: 140 },
                    }}
                  >
                    <Autocomplete
                      value={selectedApi}
                      onChange={handleApiChange}
                      options={apitableData}
                      getOptionLabel={(option) => (option.label !== undefined ? option.label : '')}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Scheme" variant="outlined" />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </FormProvider>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
              Submit
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog> */}
    </>
  );
}

// ----------------------------------------------------------------------

// function applyFilter({ inputData, comparator, filters }) {
//   // const { name, status, role } = filters;

//   const stabilizedThis = inputData.map((el, index) => [el, index]);

//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });

//   inputData = stabilizedThis.map((el) => el[0]);

//   return inputData;
// }
