/* eslint-disable import/no-extraneous-dependencies */
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import React, { useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { TextField, FormControl, Autocomplete, Select, InputLabel, OutlinedInput, MenuItem } from '@mui/material';

import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';

import FormProvider, { RHFTextField } from 'src/components/hook-form';

// ----------------------------------------------------------------------

export default function LoadWalletEdit({ currentUser, aeps, onClose, getData }) {
  const [isSubmitting, setIsSubmitting] = useState();
  const [tableData, setTableData] = useState([]);

  const defaultValues = useMemo(
    () => ({
      id: currentUser?.id || '',
      remark: '',
      refno:  '',
      status: '',

    }),
    [currentUser]
  );

  const methods = useForm({
    defaultValues,
  });


  const [selectedStatus, setSelectedStatus] = React.useState(null);

  const handleStatusChange = (event, newValue) => {
    setSelectedStatus(newValue);
  };
  const {
    handleSubmit,
  } = methods;


  const [selectedType, setSelectedType] = useState('');

  const handleTypeChange = (event) => {
    const selectedValue = event.target.value;
    console.log('selectedValue', selectedValue)
    setSelectedType(selectedValue);

  };


  const Types = [
    {
      value: 'approved',
      name: 'Approved',
    },
    {
      value: 'rejected',
      name: 'Rejected',
    }
  ]

  const onSubmit = handleSubmit(async (data) => {
    // console.log('data', data)
    try {
      setIsSubmitting(true)
      await ApiService.post(Endpoints.aepsfund.aepsfundrequestedit, { ...data, id: currentUser?.id,status:selectedType }).then(async (response) => {
        const responseData = response.data
        if (responseData?.status === true) {
          await getData();
          toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
          onClose();
        } else {
          toast.error(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
        }

      });
    } catch (error) {
      setIsSubmitting(false)
      console.error("Error submitting form:", error);
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

  });



  return (

    <Dialog
      maxWidth={false}
      open={aeps}
      onClose={onClose}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>Fund Request From {currentUser?.User?.first_name} {currentUser?.User?.last_name}</DialogTitle>

        <DialogContent className='mt-2'>

          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
             <FormControl fullWidth >
                <InputLabel>Action Type</InputLabel>

                <Select
                  name="status"
                  value={selectedType}
                  onChange={handleTypeChange}
                  input={<OutlinedInput label="Action Type" />}
                  renderValue={(selected) => {
                    const selectedtype = Types.find((type) => type.value === selected);
                    return selectedtype ? selectedtype.name : '';
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 240,
                        maxWidth: '100%',
                      },
                    },
                  }}
                >
                  {Types.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

            <RHFTextField name="refno" label="Ref No" />
            <RHFTextField name="remark" label="Remark" />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Close
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Submit
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>

  );
}

LoadWalletEdit.propTypes = {
  currentUser: PropTypes.object,
  onClose: PropTypes.func,
  getData: PropTypes.func,
  aeps: PropTypes.bool,
};
