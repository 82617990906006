'use client';

/* eslint-disable import/no-extraneous-dependencies */

// import * as XLSX from 'xlsx';
// import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes, { number } from 'prop-types';
import { Stack } from '@mui/system';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import { DatePicker } from '@mui/x-date-pickers';
import TableContainer from '@mui/material/TableContainer';
import { Grid, Dialog, TextField, DialogTitle, FormControl, DialogActions, DialogContent, InputAdornment, Autocomplete, CircularProgress, IconButton } from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import FormProvider from 'src/components/hook-form/form-provider';
import {
  useTable,
  getComparator,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';
import ChangeIconTable from './changeicon-table';

// import { notFound } from 'next/navigation';

// const STATUS_OPTIONS = [{ value: 'all', label: 'All' }, ...USER_STATUS_OPTIONS];

const TABLE_HEAD = [
  { id: 'id', label: 'Id', width: '100px' },
  { id: 'user', label: 'User Details' },
  { id: 'updatedby', label: 'Updated By' },
  { id: 'type', label: 'Type' },
  { id: 'local', label: 'Local Details' },
  { id: 'data', label: 'Data' }
];


// ----------------------------------------------------------------------

export default function ChangeIconData({setOpen,open,type,member_id=null}) {
  const table = useTable();

  const settings = useSettingsContext();

  const router = useRouter();

  const [tableData, setTableData] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [open, setOpen] = useState(isopen);
  const handleOpen = () => {
    setOpen(true);
  };


  const handleClose = () => {
    setOpen(false);
  };

  const methods = useForm();


  const getTableData = async () => {
    setIsSubmitting(true)

    console.log('type',type )
    try {
      let where={type}
      if (member_id){
        where={
          ...where,
          member_id
        }
      }
      ApiService.post(Endpoints.changehistoryget, where).then((response) => {
        const responseData = response.data
        console.log(response.data);

        setTableData(responseData.data);
        setIsSubmitting(false)

      });
    } catch (error) {
      setIsSubmitting(false)
      console.error("Error submitting form:", error);
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  useEffect(() => {


      getTableData()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
  });



  const handleEditRow = useCallback(
    (id) => {
      // router.push(paths.dashboard.user.edit(id));
      router.push(`${paths.fund.transferreturn}`);
    },
    [router]
  );





  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <Dialog open={open} onClose={handleClose} className='add_dialog' fullWidth
        maxWidth="1000px">
        <form >
          <DialogTitle sx={{ display: 'flex' }}>
            Change History
            <IconButton aria-label="close" onClick={handleClose} style={{ marginLeft: 'auto' }}>
              <Iconify icon="material-symbols:close" />

            </IconButton>
          </DialogTitle>
          <DialogContent>

            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <Scrollbar>
                <Table>
                  <TableHeadCustom
                    order={table.order}
                    orderBy={table.orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData.length}
                    numSelected={table.selected.length}
                    onSort={table.onSort}
                  />
                  {isSubmitting ? (<CircularProgress style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto' }} size={30} />
                  ) : (
                    <TableBody>
                      {tableData
                        .slice(
                          table.page * table.rowsPerPage,
                          table.page * table.rowsPerPage + table.rowsPerPage
                        )
                        .map((row) => (
                          <ChangeIconTable
                            key={row.id}
                            row={row}
                            selected={table.selected.includes(row.id)}
                            onSelectRow={() => table.onSelectRow(row.id)}
                            onEditRow={() => handleEditRow(row.id)}
                            getData={getTableData}
                          />
                        ))}
                    </TableBody>
                  )}
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePaginationCustom
              count={dataFiltered.length}
              page={table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={table.onChangePage}
              onRowsPerPageChange={table.onChangeRowsPerPage}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>Cancel</Button>

          </DialogActions>
        </form>
      </Dialog>
    </Container >

  );
}

// ----------------------------------------------------------------------
ChangeIconData.propTypes = {

  type: PropTypes.string,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  member_id:number

};
function applyFilter({ inputData, comparator, filters }) {
  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);


  return inputData;
}
