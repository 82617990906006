'use client';

/* eslint-disable import/no-extraneous-dependencies */

import { useForm } from 'react-hook-form';
import React, { useState, useCallback, useEffect } from 'react';

import { Stack } from '@mui/system';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import { DatePicker } from '@mui/x-date-pickers';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import { Grid, Dialog, Select, MenuItem, TextField, InputLabel, DialogTitle, FormControl, DialogActions, DialogContent, OutlinedInput, InputAdornment, CircularProgress, Autocomplete, CardHeader, CardContent, Tabs, tabsClasses, Tab } from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter, useSearchParams } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';

import { _userList } from 'src/_mock';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import FormProvider from 'src/components/hook-form/form-provider';
import {
  useTable,
  getComparator,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';
import { toast } from 'react-toastify';
import RegisterMemberData from './registermember';
import PanMemberData from './panmember';
import AadharMemberData from './aadharmember';
import AddressMemberData from './addressmember';
// import { notFound } from 'next/navigation';

// const STATUS_OPTIONS = [{ value: 'all', label: 'All' }, ...USER_STATUS_OPTIONS];

const TABS = [
  {
    value: 'register',
    label: 'Register',
    icon: <Iconify icon="mdi:credit-card-outline" width={24} />,
  },
  {
    value: 'pancard',
    label: 'Pancard',
    icon: <Iconify icon="mdi:cash-multiple" width={24} />,
  },
  {
    value: 'aadharcard',
    label: 'AadharCard',
    icon: <Iconify icon="mdi:file-document-outline" width={24} />,
  },
  {
    value: 'address',
    label: 'Address',
    icon: <Iconify icon="mdi:credit-card-multiple-outline" width={24} />,
  },
];
// ----------------------------------------------------------------------

export default function CreateMemberData() {
  const table = useTable();

  const settings = useSettingsContext();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [currentTab, setCurrentTab] = useState('register');


  const router = useRouter();
  const prms = useSearchParams();
  // const pagename = prms.get('PG') || '1';
  const tname = prms.get('t');


  const handleNextButtonClick = (t) => {
    console.log('t', t)
    const currentIndex = TABS.findIndex((tab) => tab.value === currentTab);
    const nextIndex = (currentIndex + 1) % TABS.length;
    setCurrentTab(TABS[nextIndex].value);

    const searchParams = new URLSearchParams({
      t
    }).toString();
    const href = `${paths.member.createmember}?${searchParams}`;
    console.log('searchParams', searchParams)
    router.push(href);
  };

  const handleChangeTab = ((event, newValue) => {
    setCurrentTab(newValue);

  });


  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Create Member"
          links={[
            { name: 'Dashboard', href: paths.dashboard.root },
            { name: 'Member' },
            { name: 'Create Member' },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />


      </Container>

      <Grid container>
        <Grid item xs={12} md={12} sx={{
          mx: 'auto',
          position: 'relative'

        }}>
          <Card sx={{ p: 2 }} >
            {/* <CardHeader title="AEPS" /> */}
            <CardContent sx={{ p: 0 }}>
              <Tabs
                value={currentTab}
                // onChange={handleChangeTab}
                sx={{
                  width: 1,
                  bottom: 0,
                  zIndex: 9,
                  // position: 'absolute',
                  bgcolor: 'background.paper',
                  [`& .${tabsClasses.flexContainer}`]: {
                    pr: { md: 3 },
                  },
                  p: 3
                }}
              >
                {TABS.map((tab) => (
                  <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
                ))}
              {/* <Button onClick={handleNextButtonClick}>Next</Button> */}
              </Tabs>
              {currentTab === 'register' && <RegisterMemberData  onNextButtonClick={handleNextButtonClick} />}

              {currentTab === 'pancard' &&  <PanMemberData onNextButtonClick={handleNextButtonClick}/> }

              {currentTab === 'aadharcard' && (
                <AadharMemberData onNextButtonClick={handleNextButtonClick}/>
              )}

              {currentTab === 'address' && <AddressMemberData />}

            </CardContent>
          </Card>
        </Grid>
      </Grid>


    </>
  );
}
