/* eslint-disable import/no-extraneous-dependencies */
// import { configureStore } from '@reduxjs/toolkit';

import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';

const store = configureStore({
  reducer: rootReducer,
});



export { store };
