'use client'

/* eslint-disable import/no-extraneous-dependencies */

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { useBoolean } from 'src/hooks/use-boolean';


import moment from 'moment';
import Label from 'src/components/label';
import { IconButton, MenuItem } from '@mui/material';
import Iconify from 'src/components/iconify';
import CustomPopover from 'src/components/custom-popover/custom-popover';
import { usePopover } from 'src/components/custom-popover';
import AepsStatementEdit from './aepsstatement-edit';
import AepsStatementComplaints from './aepsstatement-complaints';




// ----------------------------------------------------------------------

export default function AepsStatementTable({ row, selected, onEditRow, getData }) {
  // const { id, mobile, display_name, updatedAt } = row;

  const quickCompalints = useBoolean();
  const quickEdit = useBoolean();
  const popover = usePopover();

  const format = moment(row.createdAt).format('D-MM-YYYY')
  // , h:mm:ss a


  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {row.id}<br />
          {format}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {row.User.first_name} {row.User.last_name}({row.User.id})({row.User.Role.display_name})<br />
          {row.User.mobile_no}<br />
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}> {row.cust_aadhar}
          <br /> Mobile:{row.mobile} <br />
          Reason:{row.remark}</TableCell>


        <TableCell sx={{ whiteSpace: 'nowrap' }} >
          {(() => {
            if (row?.type === 'debit' && row?.transtype === 'fund' && (row.fund_request_mod === 'auto' || row.fund_request_mod === 'manual')) {
              return (
                <>
                  Ref No: {row.razorpay_payid}<br />
                  Bank:{row.bank}<br />
                </>
              )
            }
            return (
              <>
                Ref No: {row.refno}<br />
                Bank:{row.cust_bank}<br />

              </>
            )
          })()}
          Cust Name:{row.cust_name}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }} >Cust Balance: {row.cust_balance}

          <br />
          Amount:{row.amount}<br />
          Commission:{row.charge}


        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }} >
          {(() => {
            if (row?.type === 'debit' && row?.transtype === 'fund' && (row.fund_request_mod === 'auto' || row.fund_request_mod === 'manual')) {
              return (
                <>
                  Fund
                </>
              )
            }
            return (<>
              {row.aepstype}
            </>)
          })()}
          <br />
          {row.pipe_type}
        </TableCell>
        <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
          {(() => {
            if (row?.type === 'debit' && row?.transtype === 'fund' && (row.fund_request_mod === 'auto' || row.fund_request_mod === 'manual')) {
              return (

                <Label

                  variant="soft"
                  color={
                    (row?.fund_request_status === 'pending' && 'warning') ||
                    (row?.fund_request_status === 'rejected' && 'error') ||
                    (row?.fund_request_status === 'approved' && 'success') ||
                    (row?.fund_request_status === 'queued' && 'primary') ||
                    (row?.fund_request_status === 'processing' && 'primary') ||
                    (row?.fund_request_status === 'processed' && 'success') ||
                    (row?.fund_request_status === 'reversed' && 'error') ||
                    (row?.fund_request_status === 'cancelled' && 'error') ||
                    (row?.fund_request_status === 'failed' && 'error') ||
                    'default'
                  }
                >
                  {row.fund_request_status}
                </Label>

              )
            }
            return (
              <Label

                variant="soft"
                color={
                  (row.status === 'initiated' && 'warning') ||
                  (row.status === 'success' && 'success') ||
                  (row.status === 'pending' && 'warning') ||
                  (row.status === 'failed' && 'error') ||
                  (row.status === 'complete' && 'success') ||
                  'default'
                }
              >
                {row.status}
              </Label>
            )
          })()}
          <br />
          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="radix-icons:dropdown-menu" />
          </IconButton>
        </TableCell>
      </TableRow>
      <AepsStatementEdit currentUser={row} getData={getData} aepsopen={quickEdit.value} onClose={quickEdit.onFalse} />
      <AepsStatementComplaints currentUser={row} getData={getData} compalints={quickCompalints.value} onClose={quickCompalints.onFalse} />


      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        {(() => {
          if (row?.type === 'debit' && row?.transtype === 'fund' && (row.fund_request_mod === 'auto' || row.fund_request_mod === 'manual')) {
            if (row?.fund_request_status === 'pending') {
              if (row?.fund_request_mod === 'auto') {
                return (<MenuItem >
                  <Iconify icon="clarity:info-solid" />
                  Check Status
                </MenuItem>)
              }
              return (<MenuItem
                onClick={() => {
                  // onEditRow();
                  quickEdit.onTrue();
                }}

              >
                <Iconify icon="solar:pen-bold" />
                Edit
              </MenuItem>)
            }
          }
          return null
        })()}
        <MenuItem
          onClick={() => {
            // onEditRow();
            quickCompalints.onTrue();
          }}

        >
          <Iconify icon="mdi:cogs" />
          Compalints
        </MenuItem>


      </CustomPopover >
    </>
  );
}

AepsStatementTable.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  getData: PropTypes.func,
};
