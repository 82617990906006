/* eslint-disable import/no-extraneous-dependencies */
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import React, { useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { TextField, FormControl, Autocomplete } from '@mui/material';

import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';

import FormProvider, { RHFTextField } from 'src/components/hook-form';

// ----------------------------------------------------------------------

export default function FAepsStatementComplaints({ currentUser, compalints, onClose, getData }) {
  const [isSubmitting, setIsSubmitting] = useState();
  const [tableData, setTableData] = useState([]);

  const defaultValues = useMemo(
    () => ({
      role_name: currentUser?.role_name || '',
      display_name: currentUser?.display_name || '',
    }),
    [currentUser]
  );

  const methods = useForm({
    defaultValues,
  });


  const {
    handleSubmit,
  } = methods;


  const onSubmit = handleSubmit(async (data) => {
    try {
      setIsSubmitting(true)
      await ApiService.post(Endpoints.editrole, { ...data, role_id: currentUser?.id }).then(async (response) => {
        const responseData = response.data
        if (responseData?.status === true) {
          await getData();
          toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
          onClose();
        } else {
          toast.error(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
        }

      });
    } catch (error) {
      setIsSubmitting(false)
      console.error("Error submitting form:", error);
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

  });

const type = [
  { value: 1, label: 'AEPS' },
  { value: 2, label: 'Money Transfer' },
  { value: 3, label: 'Onboarding' },
  { value: 4, label: 'BBPS' },
  { value: 5, label: 'Recharge' },
]

const [selectedStatus, setSelectedStatus] = useState(['']);

const handleStatusChange = (event, newValue) => {
  setSelectedStatus(newValue);
};


  return (

      <Dialog
        maxWidth={false}
        open={compalints}
        onClose={onClose}
      >
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <DialogTitle>Edit Report</DialogTitle>

          <DialogContent className='mt-2'>

            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
               <FormControl
              sx={{
                flexShrink: 0,
              }}
            >

              <Autocomplete
                value={selectedStatus}
                onChange={handleStatusChange}
                options={type}
                getOptionLabel={(option) => (option.label !== undefined ? option.label : '')}
                renderInput={(params) => (
                  <TextField {...params} label="Select Subject" variant="outlined" />
                )}
              />
            </FormControl>
              <RHFTextField name="description" label="Description" />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" onClick={onClose}>
              Close
            </Button>

            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              Submit
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </Dialog>



  );
}

FAepsStatementComplaints.propTypes = {
  currentUser: PropTypes.object,
  onClose: PropTypes.func,
  getData: PropTypes.func,
  compalints: PropTypes.bool,
};
