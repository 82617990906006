import { paramCase } from 'src/utils/change-case';

import { _id, _postTitles } from 'src/_mock/assets';
// import App from 'src/app';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  docs: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
  zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
  figma:
    'https://www.figma.com/file/hjxMnGUJCjY7pX8lQbS7kn/%5BPreview%5D-Minimal-Web.v5.4.0?type=design&node-id=0-1&mode=design&t=2fxnS70DuiTLGzND-0',
  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id) => `/product/${id}`,
    demo: {
      details: `/product/${MOCK_ID}`,
    },
  },
  post: {
    root: `/post`,
    details: (title) => `/post/${paramCase(title)}`,
    demo: {
      details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },
  // dashboard: {
  //   root: `/dashboard`
  // },
  resources:{
    root: `/resources`,
    schememanager: '/resources/scheme-manager',
    companymanager: '/resources/company-manager',
  },
  member:{
    root: `/member`,
    whitelabel: '/member/whitelabel',
    masterdistributor: '/member/master-distributor',
    distributor: '/member/distributor',
    retailer: '/member/retailer',
    manager: '/member/manager',
    createmember:'/member/create',
    memberprofile:'/member/profile',
    unregistered:'/member/unregistered',
    unregisterapplication:'/member/unregistered/application'
  },
  fund:{
    root: `/fund`,
    loadwallet:`/fund/loadWallet`,
    transferreturn: '/fund/transfer-return',
    request: '/fund/request',
    requestreport: '/fund/request-report',
    fundreport: '/fund/all-fund-report',
  },
  aepsfund:{
    root: `/aeps-fund`,
    pendingrequest:'aeps-fund/pending-request',
    request: '/aeps-fund/request',
    // requestreport:'aeps-fund/request'
  },
  leads: {
    root: `/leads`,
    categories: `/leads/categories`,
    subcategories: `/leads/sub-categories`,
  },
  creditcard: {
    root: `/credit-card`,
    addnew:`/credit-card/addcredit`
  },
  role: {
    root: `/role`
  },
  bankservice:{
    root: `/bankservice`,
    aeps: '/bankservice/aeps',
    faeps: '/bankservice/faeps',
  },
  transactionhistory:{
    root:`/transaction-history`,
    aepsstatement:`/transaction-history/aeps-statement`,
    changehistory:'/transaction-history/change-history',
    faeps:`/transaction-history/faeps-statement`,
    matm:`/transaction-history/matm-statement`,
    moneytranfer:`/transaction-history/money-transfer`
  },
  setuptools:{
    root:'/setup-tools',
    apimanager:'/setup-tools/api-manager',
    operator:`/setup-tools/operator`,
    portal:`/setup-tools/portal-setting`,
  },
  accountsetting:{
    root:'/account-settings',
    profile:'/account-settings/profile',
  },
  accountstatement:{
    root:'/account-statement',
    aepswallet:'/account-statement/aeps-wallet',
  },
  agentlist:{
    root:'/agent-list',
    iaeps:'/agent-list'
  },
  services:{
    root:"/services",
    allleads:"/services/leads"
  },


  // AUTH
  auth: {
    login: `/login`,
    amplify: {
      login: `${ROOTS.AUTH}/amplify/login`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      register: `${ROOTS.AUTH}/amplify/register`,
      newPassword: `${ROOTS.AUTH}/amplify/new-password`,
      forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
    },
    jwt: {
      login: `/login`,
      register: `/register`,
    },
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
    auth0: {
      login: `${ROOTS.AUTH}/auth0/login`,
    },
  },
  authDemo: {
    classic: {
      login: `${ROOTS.AUTH_DEMO}/classic/login`,
      register: `${ROOTS.AUTH_DEMO}/classic/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/classic/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/classic/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/classic/verify`,
    },
    modern: {
      login: `${ROOTS.AUTH_DEMO}/modern/login`,
      register: `${ROOTS.AUTH_DEMO}/modern/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/modern/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/modern/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/modern/verify`,
    },
  },

  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    app:`${ROOTS.DASHBOARD}/app`,
    blank: `${ROOTS.DASHBOARD}/blank`,
    fileManager: `${ROOTS.DASHBOARD}/file-manager`,
    permission: `${ROOTS.DASHBOARD}/permission`,

  },
};
