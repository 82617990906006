'use client'

/* eslint-disable import/no-extraneous-dependencies */

import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';

import { Stack } from '@mui/system';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import { Drawer, Divider, Typography, Switch, Autocomplete, TextField } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';

import { LoadingButton } from '@mui/lab';
import OperatorManagerEdit from './operator-edit';
import operatorTypes from './opraterTypes';



// ----------------------------------------------------------------------

export default function OperatorManagerTable({ row, selected, onEditRow, getData, apitableData }) {
  const { id, name, type, api_id, reacharge1, recharge2 } = row;

  const quickEdit = useBoolean();


  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const [isToggled, setToggled] = useState(row.status);
  const handleToggle = (apiId) => {
    try {
      ApiService.post(Endpoints.providers.updatestatus, { id: apiId, status: !isToggled }).then((response) => {
        const responseData = response.data
        if (responseData?.status === true) {
          // getData();
          setToggled(!isToggled);
          toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

      });
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleApiChange = (event, newValue) => {

    try {
      ApiService.post(Endpoints.providers.updateapi, { id, api_id: newValue?.value }).then((response) => {
        const responseData = response.data
        if (responseData?.status === true) {
          getData();
          // setToggled(!isToggled);
          toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

      });
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{id}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{name}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{(operatorTypes.find((option) => option.value === type) || null)?.label}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }} >
          <Switch
            checked={isToggled}
            onChange={(e) => { handleToggle(id) }}
            color="primary"
            inputProps={{ 'aria-label': 'toggle switch' }}
          />
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }} >
          <Autocomplete
            name="api_id"
            value={apitableData.find((option) => option.value === api_id) || null}
            onChange={handleApiChange}
            options={apitableData}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField {...params} label="Api" variant="outlined" />
            )}
          /></TableCell>
        <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <LoadingButton variant='contained' onClick={() => {
            // onEditRow();
            quickEdit.onTrue();
          }}>
            Edit
          </LoadingButton>

        </TableCell>
      </TableRow>

      <OperatorManagerEdit apitableData={apitableData} currentUser={row} getData={getData} apimanager={quickEdit.value} onClose={quickEdit.onFalse} />





    </>
  );
}

OperatorManagerTable.propTypes = {
  row: PropTypes.object,
  apitableData: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  getData: PropTypes.func,
};
