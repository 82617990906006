import { useState } from 'react';
import PropTypes from 'prop-types';

import { LoadingButton } from '@mui/lab';
import Tooltip from '@mui/material/Tooltip';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { useBoolean } from 'src/hooks/use-boolean';

import Iconify from 'src/components/iconify';
import { usePopover } from 'src/components/custom-popover';

import SubCategoriesEdit from './subcategories-edit';



// ----------------------------------------------------------------------

export default function SubCategoriesTable({ row, selected, onEditRow }) {
  const { id, name, createdon } = row;

  const confirm = useBoolean();

  const quickEdit = useBoolean();

  const popover = usePopover();

  const [isToggled, setToggled] = useState(true);
  const handleToggle = () => {
    setToggled(!isToggled);
  };
  return (
    <>
      <TableRow hover selected={selected}>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{id}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{name}</TableCell>
        <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
          01-01-2024
        </TableCell>

        <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title="Edit" placement="top" arrow>
            <LoadingButton
              onClick={() => {
                quickEdit.onTrue();
                onEditRow();
              }}
              variant='contained'
              sx={{ m: 0, p: 0, minHeight: '35px', minWidth: '35px' }}
            >
              <Iconify icon="solar:pen-bold" />
            </LoadingButton>


          </Tooltip>


        </TableCell>

      </TableRow >

      <SubCategoriesEdit currentUser={row} open={quickEdit.value} onClose={quickEdit.onFalse} />



    </>
  );
}

SubCategoriesTable.propTypes = {
  onEditRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};
