import { useState } from 'react';
import PropTypes from 'prop-types';

import { Switch } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Tooltip from '@mui/material/Tooltip';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { useBoolean } from 'src/hooks/use-boolean';

import Iconify from 'src/components/iconify';
import { usePopover } from 'src/components/custom-popover';

import ManagerEdit from './manager-edit';



// ----------------------------------------------------------------------

export default function ManagerTable({ row, selected, onEditRow }) {
  const { id, name, } = row;

  const confirm = useBoolean();

  const quickEdit = useBoolean();

  const popover = usePopover();


  return (
    <>
      <TableRow hover selected={selected}>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>1</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{name}</TableCell>
        <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
          Login.Payindiau.Co.In
        </TableCell>
        <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
        KARNATAKA
        </TableCell>
        <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
        200
        </TableCell>
        <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title="Edit" placement="top" arrow>
            <LoadingButton
              onClick={() => {
                quickEdit.onTrue();
                onEditRow();
              }}
              variant='contained'
              sx={{ m: 0, minHeight: '35px', minWidth: '35px' }}
            >
             Edit
            </LoadingButton>


          </Tooltip>


        </TableCell>

      </TableRow >

      <ManagerEdit currentUser={row} open={quickEdit.value} onClose={quickEdit.onFalse} />



    </>
  );
}

ManagerTable.propTypes = {
  onEditRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};
