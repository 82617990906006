'use client'

/* eslint-disable import/no-extraneous-dependencies */

import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useBoolean } from 'src/hooks/use-boolean';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import Label from 'src/components/label';
import { IconButton, MenuItem } from '@mui/material';
import Iconify from 'src/components/iconify';
import CustomPopover from 'src/components/custom-popover/custom-popover';
import { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export default function IAepsTable({ row, selected, onEditRow, getData }) {
  // const { id, mobile, display_name, updatedAt } = row;

  const quickCompalints = useBoolean();
  const quickEdit = useBoolean();
  const popover = usePopover();

  const format = moment(row.createdAt).format('D-MM-YYYY')
  // , h:mm:ss a


  return (
    <>
      <TableRow hover selected={selected}>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {row.id}<br />
          {format}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {row.id}<br />
          {format}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {row.User.first_name} {row.User.last_name}({row.User.id})({row.User.Role.display_name})<br />
          {row.User.mobile_no}<br />
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}> {row.cust_aadhar}
          <br /> Mobile:{row.mobile} <br />
          Reason:{row.remark}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }} >Ref No: {row.refno}<br />
          Bank:{row.cust_bank}<br />
          Cust Name:{row.cust_name}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }} >Cust Balance: {row.cust_balance}
          <br />
          Amount:{row.amount}<br />
          Commission:{row.charge}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }} >{row.biometric}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }} >{row.aepstype}
        </TableCell>
        <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="radix-icons:dropdown-menu" />
          </IconButton>
        </TableCell>
      </TableRow>
      {/* <AepsStatementEdit currentUser={row} getData={getData} aepsopen={quickEdit.value} onClose={quickEdit.onFalse} />
      <AepsStatementComplaints currentUser={row} getData={getData} compalints={quickCompalints.value} onClose={quickCompalints.onFalse} /> */}


      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem >
          <Iconify icon="clarity:info-solid" />
          Check Status
        </MenuItem>

      </CustomPopover >
    </>
  );
}

IAepsTable.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  getData: PropTypes.func,
};
