'use client';

import { useForm } from 'react-hook-form';
import { useState, useCallback } from 'react';

import { Stack } from '@mui/system';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import { DatePicker } from '@mui/x-date-pickers';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import { Grid, Dialog, Select, MenuItem, TextField, InputLabel, DialogTitle, FormControl, DialogActions, DialogContent, OutlinedInput, InputAdornment, CircularProgress } from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';

import { _userList } from 'src/_mock';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import FormProvider from 'src/components/hook-form/form-provider';
import {
  useTable,
  getComparator,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';
import SubCategoriesTable from './subcategories-table';

// import { notFound } from 'next/navigation';

// const STATUS_OPTIONS = [{ value: 'all', label: 'All' }, ...USER_STATUS_OPTIONS];

const TABLE_HEAD = [
  { id: 'id', label: 'Id' },
  { id: 'subcategory', label: 'Subcategory Name' },
  { id: 'category', label: 'Category Name' },
  { id: 'action', label: 'Action' },
];


// ----------------------------------------------------------------------

export default function SubCategoriesData() {
  const table = useTable();

  const settings = useSettingsContext();

  const router = useRouter();

  const confirm = useBoolean();

  const [tableData, setTableData] = useState(_userList);

  const [open, setOpen] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    handleClose();
  };

  const methods = useForm();

  const onSubmit = async (data) => {
    console.log(data);
  };

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
  });


  const [selectedStatus, setSelectedStatus] = useState('');

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };
  const [filters, setFilters] = useState({ name: '' });

  const handleFilterName = (value) => {
    // Update the filters state with the new value
    setFilters((prevFilters) => ({ ...prevFilters, name: value }));
  };




  const handleEditRow = useCallback(
    (id) => {
      router.push(`${paths.leads.subcategories}`);
    },
    [router]
  );



  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Sub-Categories"
          links={[
            { name: 'Dashboard', href: paths.dashboard.root },
            { name: 'Sub-Categories' },
            { name: 'Sub-Categories' },
          ]}
          action={
            <>
              <LoadingButton
                // onClick={handleOpen}
                variant="contained"
                startIcon={<Iconify icon="mdi:refresh" />}
                sx={{ mr: 1 }}
                loading={isSubmitting}
              // onClick={getTableData}
              >
                Refresh
              </LoadingButton>
              <Button
                onClick={handleOpen}
                variant="contained"
                startIcon={<Iconify icon="mingcute:add-line" />}
              >
                Add New
              </Button>
            </>
          }
          sx={{ mb: { xs: 3, md: 5 } }}
        />

        <Card>


          <Stack
            spacing={2}
            alignItems={{ xs: 'flex-end', md: 'center' }}
            direction={{
              xs: 'column',
              md: 'row',
            }}
            sx={{
              p: 2.5,
            }}
          >
            <DatePicker
              label="Start date"
              format="dd/MM/yyyy"
              value={filters.startDate}
              // onChange={handleFilterStartDate}
              slotProps={{ textField: { fullWidth: true } }}
              sx={{
                maxWidth: { md: 150 },
              }}
            />

            <DatePicker
              label="End date"
              format="dd/MM/yyyy"
              value={filters.endDate}
              // onChange={handleFilterEndDate}
              slotProps={{
                textField: {
                  fullWidth: true,
                  // error: dateError,
                },
              }}
              sx={{
                maxWidth: { md: 150 },
              }}
            />

            <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
              <TextField
                fullWidth
                value={filters.name}
                onChange={(e) => handleFilterName(e.target.value)}
                placeholder="Search..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>


            <FormControl
              sx={{
                flexShrink: 0,
                width: { xs: 5, md: 130 },
              }}
            >
              <Button onClick={handleSave} variant="contained" type="submit" startIcon={<Iconify icon="eva:search-fill" />} sx={{ height: 50 }} >
                Search
              </Button>
            </FormControl>
          </Stack>



          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction

              numSelected={table.selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) =>
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                )
              }
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={confirm.onTrue}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>

              <Table >
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}

                />
                {isSubmitting ? (<CircularProgress style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto' }} size={30} />
                ) : (
                  <TableBody>
                    {dataFiltered
                      .slice(
                        table.page * table.rowsPerPage,
                        table.page * table.rowsPerPage + table.rowsPerPage
                      )
                      .map((row) => (
                        <SubCategoriesTable
                          key={row.id}
                          row={row}
                          selected={table.selected.includes(row.id)}
                          onSelectRow={() => table.onSelectRow(row.id)}
                          onEditRow={() => handleEditRow(row.id)}
                        />

                      ))}

                    {/* <TableNoData notFound={notFound} /> */}
                  </TableBody>
                )}
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={dataFiltered.length}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}

          />
        </Card>
      </Container>

      <Dialog open={open} onClose={handleClose} className='add_dialog'>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle>Add Categories</DialogTitle>
          <DialogContent>
            <FormProvider {...methods}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField label="Sub Category Name" name='name' />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField label="select box add" name='name' />
                </Grid>

              </Grid>

            </FormProvider>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>Cancel</Button>
            <LoadingButton onClick={handleSave} variant="contained" type="submit" loading={isSubmitting}>
              Submit
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>


    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator, filters }) {
  // const { name, status, role } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);


  return inputData;
}
