'use client'

/* eslint-disable import/no-extraneous-dependencies */

import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';

import { Stack } from '@mui/system';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import { Drawer, Divider, Typography, Switch } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';

import { LoadingButton } from '@mui/lab';
import ApiMangaerEdit from './apimanager-edit';



// ----------------------------------------------------------------------

export default function ApiManagerTable({ row, selected, onEditRow, getData }) {
  const { id, product_name, display_name,api_code,url,optional1,type,username,password, status } = row;

  const quickEdit = useBoolean();


  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const [isToggled, setToggled] = useState(status);
  const handleToggle = (apiId) => {
    try {
      ApiService.post(Endpoints.statusupdateapimanager,{id:apiId,status:!isToggled}).then((response) => {
        const responseData = response.data
        if (responseData?.status === true) {
          // getData();
          setToggled(!isToggled);
          toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

      });
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };


  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{id}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{product_name}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{display_name}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }} >{api_code}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }} >{url}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }} >
        <Switch
            checked={isToggled}
            onChange={(e)=>{handleToggle(id)}}
            color="primary"
            inputProps={{ 'aria-label': 'toggle switch' }}
          />
        </TableCell>
        <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <LoadingButton variant='contained' onClick={() => {
            onEditRow();
            quickEdit.onTrue();}}>
              Edit
            </LoadingButton>

        </TableCell>
      </TableRow>

      <ApiMangaerEdit currentUser={row} getData={getData} apimanager={quickEdit.value} onClose={quickEdit.onFalse} />





    </>
  );
}

ApiManagerTable.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  getData: PropTypes.func,
};
