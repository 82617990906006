import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Switch, Button, MenuItem, Popover, Drawer, Typography, Divider, IconButton } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { paths } from 'src/routes/paths';
import { useRouter, useSearchParams } from 'src/routes/hooks';
import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Stack } from '@mui/system';
import ChangeIconData from 'src/sections/setup-tools/portal-setting/changeicon-data';
import Label from 'src/components/label';
import Scrollbar from 'src/components/scrollbar';
import Constants from 'src/common/constant';
import { useAuthContext } from 'src/auth/hooks';
import AepsTransfer from './aepstransfer';
import PermissionItem from './permission-item';
import FundTransfer from './retailer-fundtranfer';
import SendNotification from './notification';
import SchemeDetails from './scheme-details';
import AddIdStock from './add-id-stock';



// ----------------------------------------------------------------------

export default function RetailerTable({ row, selected, onEditRow, permissionsData, onCloseDrawer }) {
  // const { id, name, } = row;
  const router = useRouter();

  const confirm = useBoolean();
  const [open, setOpen] = useState(false);
  const { user } = useAuthContext();

  const fundTransfer = useBoolean();
  const addidstock = useBoolean();
  const fundEdit = useBoolean();
  const aepstransfer = useBoolean();
  const notification = useBoolean();

  const actionpopover = usePopover();
  const reportpopover = usePopover();

  const [isToggled, setToggled] = useState(true);
  const handleToggle = () => {
    setToggled(!isToggled);
  };
  const [changetype, setSetChangetype] = useState([])

  const handleOpen = (type) => {
    setOpen(true);
    setSetChangetype(type)

  };

  const format = moment(row.createdAt).format('D-MM-YYYY, h:mm:ss a')

  const [selectedPermissions, setSelectedPermissions] = useState([]);
  // const drawer = useBoolean();
  // const schemedrawer = useBoolean();
  const [drawerState, setDrawerState] = useState({ drawer: false, schemedrawer: false });

  const handleDrawerToggle = (drawerName) => {
    setDrawerState((prevState) => ({
      ...prevState,
      [drawerName]: !prevState[drawerName],
    }));
  };
  const closeDrawer = () => {
    setDrawerState((prevState) => ({
      ...prevState,
      drawer: false,
      schemedrawer: false
    }));
  };
  const onclickPermissionhandler = async () => {
    // drawer.onTrue();
    // actionpopover.onClose();
    try {
      // setIsSubmitting(true);
      const response = await ApiService.post(Endpoints.getuserpermission, { user_id: row.id });
      const responseData = response.data;
      // setSelectedPermissions(responseData.id);
      if (responseData?.status === true) {
        console.log(responseData);
        setSelectedPermissions(responseData.data)
        handleDrawerToggle('drawer');
        actionpopover.onClose();
      } else {
        toast.error(responseData.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        handleDrawerToggle('drawer'); // Close drawer if error
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Error submitting form', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
  const onclickSchemehandler = async () => {
    handleDrawerToggle('schemedrawer');
    actionpopover.onClose();
    // try {
    //   // setIsSubmitting(true);
    //   const response = await ApiService.post(Endpoints.getuserpermission, { user_id: row.id});
    //   const responseData = response.data;
    //   // setSelectedPermissions(responseData.id);
    //   if (responseData?.status === true) {
    //     console.log(responseData);
    //     setSelectedPermissions(responseData.data)
    //     drawer.onTrue();
    //     actionpopover.onClose();
    //   } else {
    //     toast.error(responseData.message, {
    //       position: toast.POSITION.TOP_RIGHT,
    //     });
    //     drawer.onFalse();
    //   }
    // } catch (error) {
    //   console.error('Error submitting form:', error);
    //   toast.error('Error submitting form', {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // }
  }

  const routerPush = useCallback(
    () => {
      router.push(`${paths.member.memberprofile}/${row?.id}`);
    },
    [router, row]
  );


  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Switch
            checked={isToggled}
            onChange={handleToggle}
            color="primary"
            inputProps={{ 'aria-label': 'toggle switch' }}
          />{row.id}<br />{format}
          {/* <Label

            variant="soft"
            color={
              (row.status === 'initiated' && 'warning') ||
              (row.status === 'success' && 'success') ||
              (row.status === 'pending' && 'warning') ||
              (row.status === 'failed' && 'error') ||
              (row.status === 'complete' && 'success') ||
              'default'
            }
          >
            {row.status}
          </Label> */}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.first_name} {row.last_name}
          <br />{row.mobile_no}
        </TableCell>
        <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
          {row?.parentData?.first_name} {row?.parentData?.last_name} ({row?.parentData?.id})<br />
          {row?.parentData?.mobile_no}<br />
          {row?.parentData?.Role?.display_name}
        </TableCell>
        <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
          Alternate Mobile No:{row?.mobile_no}<br />
          Scheme:{row?.Schema?.schema_name}<br />
          Company:Hallipay<br />
          APP Version:60
        </TableCell>
        <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
          Main:{row.main_wallet}<br />
        </TableCell>
        <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
          Today - {row?.Aeps_Report[0]?.totalAmount || 0}<br />
          Date - {row?.Aeps_Report[0]?.createdAt || ''}
        </TableCell>
        {/* <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Switch
            checked={isToggled}
            onChange={handleToggle}
            color="primary"
            inputProps={{ 'aria-label': 'toggle switch' }}
          />
        </TableCell> */}
        <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }} >
          <Button onClick={actionpopover.onOpen} variant="contained" sx={{ mb: 1 }} size='small'>
            <Iconify icon="gridicons:dropdown" />
            Action
          </Button><br />
          <Button onClick={reportpopover.onOpen} variant="contained" size='small'>
            <Iconify icon="gridicons:dropdown" />
            Reports
          </Button>
        </TableCell>

      </TableRow >

      <FundTransfer currentUser={row} fundtransfer={fundTransfer.value} onClose={fundTransfer.onFalse} />
      {addidstock.value===true ?<AddIdStock currentUser={row} addidstock={addidstock.value} onClose={addidstock.onFalse} />:''}
      <AepsTransfer currentUser={row} aepstransfer={aepstransfer.value} onClose={aepstransfer.onFalse} />
      <SendNotification currentUser={row} notification={notification.value} onClose={notification.onFalse} />


      {/* action  */}
      <CustomPopover
        open={actionpopover.open}
        onClose={actionpopover.onClose}
        arrow="right-top"
        sx={{ width: 180 }}
      >
        <MenuItem onClick={fundTransfer.onTrue}>
          <Iconify icon="solar:wallet-2-bold" />
          Fund Transfer
        </MenuItem>
        <MenuItem onClick={onclickSchemehandler}>
          <Iconify icon="mdi:cogs" />
          Scheme
        </MenuItem>
        {/* <MenuItem onClick={aepstransfer.onTrue}>
          <Iconify icon="solar:wallet-2-bold" />
          AEPs Transfer
        </MenuItem> */}
        {/* <MenuItem>
          <Iconify icon="solar:wallet-2-bold" />
          Onboarding Check
        </MenuItem> */}
        <MenuItem onClick={onclickPermissionhandler}>
          <Iconify icon="mdi:cogs" />
          Permission
        </MenuItem>
        <MenuItem onClick={routerPush}>
          <Iconify icon="bi:person-fill" />
          View Profile
        </MenuItem >
        {
          (row?.member_type !== Constants.ROLE.RETAILER) && (
            <MenuItem onClick={addidstock.onTrue}>
              <Iconify icon="solar:user-plus-bold" />
              Add Id Stock
            </MenuItem>
          )}
        {/* <MenuItem onClick={notification.onTrue}>
          <Iconify icon="clarity:notification-solid" />
          Send Notification
        </MenuItem> */}
      </CustomPopover >
      {/* reports  */}
      <CustomPopover
        open={reportpopover.open}
        onClose={reportpopover.onClose}
        arrow="right-top"
      // sx={{ width: 140 }}
      >
        <MenuItem>
          <Iconify icon="solar:pen-bold" />
          Aeps Statement
        </MenuItem>


      </CustomPopover >


      <Drawer
        open={drawerState.drawer}
        onClose={() => handleDrawerToggle('drawer')}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: '60%' },
        }}
      >

        <Scrollbar>
          <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Member Permission
              <IconButton style={{ color: '#000', marginLeft: 'auto' }} onClick={() => { handleOpen('PERMISSION_EDIT') }}>
                <Iconify icon="typcn:info" />
              </IconButton>
            </Typography>

            <IconButton onClick={() => handleDrawerToggle('drawer')}>
              <Iconify icon="mingcute:close-line" />
            </IconButton>

          </Stack>
          <Divider />
          <PermissionItem permissionsData={permissionsData} selectedPermissions={selectedPermissions} user_id={row.id} drawer={drawerState.drawer} onCloseDrawer={closeDrawer} />
        </Scrollbar>


      </Drawer>
      <Drawer
        open={drawerState.schemedrawer}
        onClose={() => handleDrawerToggle('schemedrawer')}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: '60%' },
        }}
      >

        <Scrollbar>
          <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Scheme Manager<IconButton style={{ color: '#000', marginLeft: 'auto' }} onClick={() => { handleOpen('MEMBER_SCHEME_EDIT') }}>
                <Iconify icon="typcn:info" />
              </IconButton>
            </Typography>

            <IconButton onClick={() => handleDrawerToggle('schemedrawer')}>
              <Iconify icon="mingcute:close-line" />
            </IconButton>

          </Stack>
          <Divider />
          <SchemeDetails schemeId={row?.schema_id} user_id={row.id} schemedrawer={drawerState.schemedrawer} onCloseDrawer={closeDrawer} />
          {/* <PermissionItem permissionsData={permissionsData} selectedPermissions={selectedPermissions} user_id={row.id} drawer={drawer} /> */}
        </Scrollbar>


      </Drawer>

      {open === true ? <ChangeIconData open={open} setOpen={setOpen} type={changetype} member_id={row?.id} /> : null}

    </>
  );
}

RetailerTable.propTypes = {
  onEditRow: PropTypes.func,
  permissionsData: PropTypes.object,
  row: PropTypes.object,
  selected: PropTypes.bool,
  onCloseDrawer: PropTypes.func,
};
