'use client';

/* eslint-disable import/no-extraneous-dependencies */

import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useState, useEffect, useCallback } from 'react';

import { Stack } from '@mui/system';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import { DatePicker } from '@mui/x-date-pickers';
import TableContainer from '@mui/material/TableContainer';
import { Grid, Dialog, TextField, DialogTitle, FormControl, DialogActions, DialogContent, InputAdornment, CircularProgress, Paper, TableHead, TableRow, TableCell, RadioGroup, FormControlLabel, Radio, InputLabel, Select, OutlinedInput, MenuItem, FormGroup } from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import FormProvider from 'src/components/hook-form/form-provider';
import {
  useTable,
  getComparator,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';
import AepsRequestTable from './request-table';

// import { notFound } from 'next/navigation';

// const STATUS_OPTIONS = [{ value: 'all', label: 'All' }, ...USER_STATUS_OPTIONS];

const TABLE_HEAD = [
  { id: 'id', label: 'Id', width: '150px' },
  { id: 'user', label: 'User Details' },
  { id: 'bank', label: 'Bank Details' },
  { id: 'discription', label: 'Description' },
  { id: 'remark', label: 'Remark' ,width:'20%'},
  { id: 'action', label: 'Action' },
];


// ----------------------------------------------------------------------

export default function AepsRequestData() {
  const table = useTable();

  const settings = useSettingsContext();
  const [permissionsData, setPermissionsData] = useState([]);

  const [bankData, setBankData] = useState([]);

  const router = useRouter();

  const [tableData, setTableData] = useState([]);

  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);



  const methods = useForm();

  const getTableData = async () => {
    setIsSubmitting(true)
    try {
      ApiService.post(Endpoints.aepsfund.aepsfundrequestget).then((response) => {
        const responseData = response.data
        if (responseData?.status === true) {
          setTableData(responseData.data);
        }
        setIsSubmitting(false)

      });
    } catch (error) {
      setIsSubmitting(false)
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }


  useEffect(() => {
    getTableData();
  }, []);




  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
  });

  const handleEditRow = useCallback(
    (id) => {
      router.push(`${paths.rolepermission.roles}`);
    },
    [router]
  );
  const [data, setData] = useState([]); // State to store the fetched data
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    name: '',
    agentId: '',
  });


  const handleSearch = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const handleSearchButtonClick = () => {
    getTableData(filters).then((response) => {
      setData(response.data);
    });
  };

  return (
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>

        <CustomBreadcrumbs
          heading="All Request"
          links={[
            { name: 'Dashboard', href: paths.dashboard.root },
            { name: 'Aeps Fund' },
            { name: 'All Request' },
          ]}
          action={
              <LoadingButton
                // onClick={handleOpen}
                variant="contained"
                startIcon={<Iconify icon="mdi:refresh" />}
                sx={{ mr: 1 }}
                loading={isSubmitting}
                onClick={getTableData}
              >
                Refresh
              </LoadingButton>

          }
          sx={{ mb: { xs: 3, md: 5 } }}
        />
        <Card>
          <Stack
            spacing={2}
            alignItems={{ xs: 'flex-end', md: 'center' }}
            direction={{
              xs: 'column',
              md: 'row',
            }}
            sx={{
              p: 2.5,
              // pr: { xs: 2.5, md: 1 },
            }}
          >
            <DatePicker
              label="Start date"
              value={filters.startDate}
              onChange={(date) => handleSearch('startDate', date)}
              format="dd/MM/yyyy"
              // onChange={handleFilterStartDate}
              slotProps={{ textField: { fullWidth: true } }}
              sx={{
                maxWidth: { md: 150 },
              }}
            />

            <DatePicker
              label="End date"
              format="dd/MM/yyyy"
              value={filters.endDate}
              onChange={(date) => handleSearch('endDate', date)}
              // onChange={handleFilterEndDate}
              slotProps={{
                textField: {
                  fullWidth: true,
                  // error: dateError,
                },
              }}
              sx={{
                maxWidth: { md: 150 },
              }}
            />

            <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
              <TextField
                sx={{ width: { xs: 5, md: 180 }, }}
                fullWidth
                value={filters.name}
                onChange={(e) => handleSearch('name', e.target.value)}
                // onChange={(e) => handleFilterName(e.target.value)}
                placeholder="Search..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>


            <FormControl
              sx={{
                flexShrink: 0,
                width: { xs: 5, md: 130 },
              }}
            >
              <Button onClick={handleSearchButtonClick}
                variant="contained" type="submit" startIcon={<Iconify icon="eva:search-fill" />} sx={{ height: 50 }} >
                Search
              </Button>
            </FormControl>
          </Stack>



          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>


            <Scrollbar>

              <Table>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                  sx={{
                    width: 'auto'
                  }}
                />
                {isSubmitting ? (<CircularProgress style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto' }} size={30} />
                ) : (
                  <TableBody>
                    {dataFiltered
                      .slice(
                        table.page * table.rowsPerPage,
                        table.page * table.rowsPerPage + table.rowsPerPage
                      )
                      .map((row) => (
                        <AepsRequestTable
                          key={row.id}
                          row={row}
                          permissionsData={permissionsData}
                          selected={table.selected.includes(row.id)}
                          onSelectRow={() => table.onSelectRow(row.id)}
                          onEditRow={() => handleEditRow(row.id)}
                          getData={getTableData}
                        />

                      ))}

                    {/* <TableNoData notFound={notFound} /> */}
                  </TableBody>
                )}
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={dataFiltered.length}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}

          />
        </Card>
      </Container>

  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator, filters }) {
  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);


  return inputData;
}
