
import React, { useRef, useState } from 'react';

import { LoadingButton } from '@mui/lab';

import FormProvider from 'src/components/hook-form/form-provider';

function Biometric(prop) {
  const [methodUrl, setMethodUrl] = useState('');
  const [infoUrl, setInfoUrl] = useState('');
  const [deviceInfo, setDeviceInfo] = useState('');

  const [httpStatus, setHttpStatus] = useState(false);
  const [xmlString, setXmlString] = useState('');
  const [error, setError] = useState(null);

  // Function to handle changes in pidData
  const [pidData, setPidData] = useState('');

  const [customDomName] = useState('127.0.0.1');
  const [successFlag, setSuccessFlag] = useState(0);
  // const [primaryUrl, setPrimaryUrl] = useState(`http://${customDomName}:`);
  const [url, setUrl] = useState('');
  const [finUrl, setFinUrl] = useState('');
  const [err, setErr] = useState('');


  const [finalUrl, setFinalUrl] = useState('');
  const [res, setRes] = useState({});
  const getCustomDomName = '127.0.0.1';
  const primaryUrl = window.location.protocol === 'https:' ? `https://${getCustomDomName}:` : `http://${getCustomDomName}:`;
  let OldPort = true;
  const myButtonRef = useRef(null);
  // useEffect(() => {
  //   try {
  //     const protocol = window.location.href;
  //     if (protocol.indexOf('https') >= 0) {
  //       setPrimaryUrl(`https://${customDomName}:`);
  //     }
  //   } catch (e) {}
  // }, [customDomName]);

  const discoverAvdm = async () => {
    setSuccessFlag(0);

    // eslint-disable-next-line no-plusplus
    for (let i = 11100; i <= 11112; i++) {
      console.log(`Discovering RD service on port : ${i}`);
      const apiUrl = `${primaryUrl}${i}`;
      setMethodUrl(apiUrl)
      try {
        // eslint-disable-next-line no-await-in-loop
        const response = await fetch(apiUrl, {
          method: 'RDSERVICE',
          headers: {
            'Content-Type': 'text/xml; charset=utf-8',
          },
        });

        // eslint-disable-next-line no-await-in-loop
        const data = await response.text();
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(data, 'text/xml');
        setDeviceInfo(data)
        const CmbData1 = xmlDoc.querySelector('RDService').getAttribute('status');
        const CmbData2 = xmlDoc.querySelector('RDService').getAttribute('info');

        if (/\bMantra\b/.test(CmbData2)) {
          const interfacePath0 = xmlDoc.querySelector('Interface').getAttribute('path');
          const interfacePath1 = xmlDoc.querySelector('Interface:nth-child(2)').getAttribute('path');
          if (interfacePath0 === '/rd/capture' || interfacePath1 === '/rd/capture') {
            setFinalUrl(apiUrl);
            const methodCapture = interfacePath0 === '/rd/capture' ? interfacePath0 : interfacePath1;
            const methodInfo = interfacePath0 === '/rd/info' ? interfacePath0 : interfacePath1;

            if (CmbData1 === 'READY' || CmbData1 === 'USED') {
              // Set state and break the loop if successful
              setSuccessFlag(1);
              captureAvdm(apiUrl)
              // myButtonRef.current.click();
              return;
            } if (CmbData1 === 'NOTREADY') {
              alert('Device Not Discover');
              return;
            }
          }
        }
        // eslint-disable-next-line no-shadow
      } catch (error) {
        if (i === 8005 && OldPort === true) {
          OldPort = false;
          i = 11099;
        }
      }
    }

    if (successFlag === 0) {
      alert('Connection failed. Please try again.');
    }
  };



  const captureAvdm = async (finalUrls) => {

    const DString = '';
    const device = 'mantra';

    const strWadh = '';
    const strOtp = '';

    const XML = `<?xml version="1.0"?>
      <PidOptions ver="1.0">
        <Opts fCount="1" fType="0" iCount="0" pCount="0" format="0" pidVer="2.0" timeout="10000" posh="UNKNOWN" env="P" />
        ${DString}
        <CustOpts>
          <Param name="mantrakey" value="" />
        </CustOpts>
      </PidOptions>`;

    // eslint-disable-next-line no-shadow
    const finUrl = document.getElementById('method').value;
    console.log('finUrl', finUrl);
    const verb = 'CAPTURE';

    const requestOptions = {
      method: 'CAPTURE',
      headers: {
        'Content-Type': 'text/xml; charset=utf-8',
      },
      body: XML,
    };

    fetch(finalUrls, requestOptions)
      .then((response) => response.text())
      .then((data) => {
        // eslint-disable-next-line no-shadow
        let xmlString;
        if (device === 'mantra') {
          xmlString = data;
        } else {
          xmlString = new XMLSerializer().serializeToString(data);
        }

        setHttpStatus(true);
        setXmlString(xmlString);
        prop?.capturData(xmlString)
        console.log('xmlString', xmlString)

        const $doc = new DOMParser().parseFromString(data, 'text/xml');
        const Message = $doc.querySelector('Resp').getAttribute('errInfo');
        const errorCode = $doc.querySelector('Resp').getAttribute('errCode');

        if (errorCode === '0') {
          alert(Message);
        } else {
          document.getElementById('loaderbala').style.display = 'none';
          alert('Capture Failed');
          window.location.reload();
        }
      })
      // eslint-disable-next-line no-shadow
      .catch((error) => {
        alert(error);
        setError(error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add any additional form submission logic here
    discoverAvdm();
  };

  return (
    <FormProvider onSubmit={handleSubmit}>
      <input type="hidden" name="method" id="method" value={methodUrl} />
      <input type="hidden" name="info" id="info" value={primaryUrl} />
      <LoadingButton

        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        onClick={handleSubmit}

      >
        Capture Biomatric
      </LoadingButton>


      <button style={{ display: 'none' }} type="button" ref={myButtonRef} className="btn btn-warning" onClick={captureAvdm}>
        SCAN
      </button>




      <textarea
        style={{ display: 'none' }}
        id="txtDeviceInfo"
        rows="10"
        cols="50"
        height="1000"
        value={deviceInfo}
        readOnly
      />


      <textarea
        style={{ display: 'none' }}
        id="txtPidData"
        rows="20"
        cols="100"
        height="1000"
        value={pidData}
        readOnly
      />
    </FormProvider>
  );
}

export default Biometric;
