'use client'

/* eslint-disable import/no-extraneous-dependencies */

import PropTypes from 'prop-types';
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import moment from 'moment';
import Label from 'src/components/label';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent } from '@mui/material';
import { useBoolean } from 'src/hooks/use-boolean';
import Iconify from 'src/components/iconify';

import { toast } from 'react-toastify';
import ApiService from 'src/common';
import { Box } from '@mui/system';
import Endpoints from 'src/common/endpoints';
import Constants from 'src/common/constant';
import { useAuthContext } from 'src/auth/hooks';
import LoadWalletEdit from './loadwallet-edit';



// ----------------------------------------------------------------------

export default function LoadWalletTable({ row, selected, onEditRow, getData, permissionsData }) {
  // const { id, role_name, display_name, updatedAt } = row;

  const format = moment(row?.createdAt).format('D-MM-YYYY, h:mm:ss a')
  // , h:mm:ss a

  const aepsedit = useBoolean();
  const [open, setOpen] = useState(false);
  const { user } = useAuthContext();
const [message,setMessage] =useState();

  const handleCheckstatus = async () => {
    try {
      // setIsSubmitting(true)
      await ApiService.post(Endpoints.aepsfund.aepsfundrequestcheckstatus, { id: row?.id }).then((response) => {
        const responseData = response.data
        if (responseData?.status === true) {
          setMessage(responseData?.data)
          setOpen(true);
          // setTimeout(() => {
          //   setOpen(false);
          // }, 2000);
          toast.success(response.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
    } catch (error) {
      // setIsSubmitting(false)
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

const handleClose=()=>{
  setOpen(false);

}

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.id}<br />{format}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.User?.first_name} {row?.User?.last_name}<br />
          {row?.User?.mobile_no}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.bank_account} {row?.bank} <br />{row?.bank_ifsc}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }} >₹{row?.amount} / Bank + {row?.charge} <br />Charges</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }} >
          {(() => {
            if (row?.fund_request_mod === 'auto') {
              return row?.razorpay_remark;
            }
            return row?.remark;
          })()}
        </TableCell>
        <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Label
            variant="soft"
            color={
              (row?.fund_request_status === 'pending' && 'warning') ||
              (row?.fund_request_status === 'rejected' && 'error') ||
              (row?.fund_request_status === 'approved' && 'success') ||
              (row?.fund_request_status === 'queued' && 'primary') ||
              (row?.fund_request_status === 'processing' && 'primary') ||
              (row?.fund_request_status === 'processed' && 'success') ||
              (row?.fund_request_status === 'reversed' && 'error') ||
              (row?.fund_request_status === 'cancelled' && 'error') ||
              (row?.fund_request_status === 'failed' && 'error') ||
              'default'
            }
          >
            {row?.fund_request_status}
          </Label>
          <br />
          {(() => {
            if (user?.member_type === Constants.ROLE.ADMIN) {
              if (row?.fund_request_status === 'pending') {
                if (row?.fund_request_mod === 'auto') {
                  return (
                    <Button variant="contained" size='small' sx={{ mt: 1 }} onClick={handleCheckstatus}>
                      Check Status
                    </Button>
                  );
                }

                if (row?.fund_request_mod === 'manual') {
                  return (
                    <Button onClick={aepsedit.onTrue} variant="contained" size='small' sx={{ mt: 1 }}>
                      Edit
                    </Button>
                  );
                }
              }
            }

            return null;
          })()}

        </TableCell>
      </TableRow>
      <LoadWalletEdit getData={getData} currentUser={row} aeps={aepsedit.value} onClose={aepsedit.onFalse} />

      <Dialog
        open={open}
        maxWidth="500px"
      >

        <DialogContent sx={{  padding:'50px 50px 20px',margin:'0 auto' ,textAlign:'center'}}>
            {/* <CircularProgress style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto' }} size={30} /> */}
            {message}
        </DialogContent>
        <DialogActions>
            <Button variant="outlined" onClick={handleClose}>Cancel</Button>

          </DialogActions>

      </Dialog>

    </>
  );
}

LoadWalletTable.propTypes = {
  row: PropTypes.object,
  permissionsData: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  getData: PropTypes.func,
};
