'use client';

/* eslint-disable import/no-extraneous-dependencies */
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import React, { useState, useCallback, useEffect } from 'react';

import { Stack } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import {
  Grid,
  Dialog,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  DialogTitle,
  FormControl,
  DialogActions,
  DialogContent,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
  Autocomplete,
  CardHeader,
  CardContent,
  Tabs,
  tabsClasses,
  Tab,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  Typography,
} from '@mui/material';

import { useRouter, useSearchParams } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';

import { _userList } from 'src/_mock';

import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import FormProvider from 'src/components/hook-form/form-provider';
import {
  useTable,
  getComparator,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';
import { toast } from 'react-toastify';
import OTPInput from 'react-otp-input';
import { useAuthContext } from 'src/auth/hooks';
import Constants, { UsersPermissions } from 'src/common/constant';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

export default function RegisterMemberData({ onNextButtonClick }) {
  const table = useTable();

  const settings = useSettingsContext();

  const router = useRouter();
  const [apitableData, setApiTableData] = useState([]);

  const confirm = useBoolean();

  const [open, setOpen] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [checked, setChecked] = useState(false);
  const [mobileotp, setMobileotp] = useState('');
  const [emailotp, setEmailotp] = useState('');
  const [resT, setResT] = useState("");

  const { user } = useAuthContext();
  const handleClose = () => {
    setOpen(false);
  };
  const prms = useSearchParams();
  // const pagename = prms.get('PG') || '1';
  const tname = prms.get('t');

  const password = useBoolean();

  const methods = useForm();
  const { setValue } = methods;
  const [errors, setErrors] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    mobile_no: '',
    gender: '',
    whatsapp_no: '',
    schema_id: '',
  });

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    mobile_no: '',
    gender: '',
    whatsapp_no: '',
    schema_id: '',
    membar_type:'',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Check if the changed input is related to membar_type
    const updatedFormData =
      name === 'membar_type' ? { ...formData, membar_type: value } : { ...formData, [name]: value };

    setFormData(updatedFormData);
  };
  const handleCheckboxChange = () => {
    setChecked(!checked);
    if (!checked) {
      // If the checkbox is checked, set mobile_no to whatsapp_no value
      setFormData((prevData) => ({
        ...prevData,
        whatsapp_no: prevData.mobile_no,

      }));
    }
  };

  const handleNext = async (e) => {
    e.preventDefault();

    const requiredFields = ['first_name', 'last_name', 'email', 'password', 'mobile_no', 'gender','schema_id','membar_type','whatsapp_no'];
    const newErrors = {};

    // Check if any of the required fields are empty
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
      } else {
        newErrors[field] = '';
      }
    });
    setErrors(newErrors);
    if (Object.values(newErrors).some((error) => error !== '')) {
      return;
    }
    try {
      setIsSubmitting(true)
      await ApiService.post(Endpoints.membercreate.pg1, formData).then((response) => {
        const responseData = response.data
        if (responseData?.status === true) {
          // console.log(response.data);
          toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          if (responseData?.data?.show_otp) {
            setResT(responseData?.data?.t)
            setOpen(true);
          }
          setIsSubmitting(false)
        } else {
          setIsSubmitting(false)
          console.log('error', response.data);
        }
        if (responseData?.status === false) {
          // console.log(response.data);
          toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    } catch (error) {
      setIsSubmitting(false)
      console.error("Error submitting form:", error);
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }


  };
  const handleMobileOtpChange = (value) => {
    setMobileotp(value);
  };
  const handleEmailOtpChange = (value) => {
    setEmailotp(value);
  };
  const handleSave = async (e) => {
    e.preventDefault();
    // onNextButtonClick();
    // handleClose();
    const sendObj = {
      t: resT,
      mobile_code: mobileotp,
      email_code: emailotp
    }
    try {
      setIsSubmitting(true)
      ApiService.post(Endpoints.membercreate.otpverify, sendObj).then((response) => {
        const responseData = response.data;
        console.log(response.data);

        if (responseData?.status === true) {
          onNextButtonClick( response.data?.data?.t);
          handleClose();
          toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          console.log('error', response.data);
        }
        setIsSubmitting(false)
      });

    } catch (error) {
      setIsSubmitting(false)
      console.error("Error submitting form:", error);
    }
  };

  const getApiData = async () => {
    try {
      ApiService.post(Endpoints.getAllSchema).then((response) => {
        const responseData = response.data;
        if (responseData?.status === true) {
          // console.log('responseData.data', responseData?.data[0]?.status);

          const apidata = responseData.data
            .filter((element) => element.status === true)
            .map((element) => ({
              value: element.id,
              label: element.schema_name,
              status: element.status,
            }));
          setApiTableData(apidata);
        }
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Error submitting form', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const [selectedApi, setSelectedApi] = React.useState(null);

  const handleApiChange = (event, newValue) => {
    if (newValue?.value) {
      setSelectedApi(newValue);
      setFormData({ ...formData, schema_id: newValue.value ? newValue.value : null });
    }
  };
  useEffect(() => {
    getApiData();
    // getPermissionData();
  }, []);

  const membertype = checkMemberPermission(user?.permissions,(user.member_type===Constants.ROLE.ADMIN));
  const handleMobileNoKeyPress = (e) => {
    // Allow only numeric digits (0-9)
    if (e.key && !/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
    if (e.target.value.length >= 10) {
      e.preventDefault();
    }
  };

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <FormProvider {...methods}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4} sx={{ width: '100%' }}>
            <TextField
              label="First Name"
              name="first_name"
              onChange={handleInputChange}
              value={formData.first_name || ''}
              sx={{ width:'100%' }}
            />
             <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.first_name}</p>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Last Name"
              name="last_name"
              onChange={handleInputChange}
              value={formData.last_name || ''}
              sx={{ width:'100%' }}
            />
             <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.last_name}</p>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Mobile"
              name="mobile_no"
              onChange={handleInputChange}
              value={formData.mobile_no || ''}
              onKeyPress={handleMobileNoKeyPress}
              onInput={(e) => { setValue('mobile_no', e.target.value.replace(/[^0-9]/g, '').slice(0, 10)) }}
              sx={{ width:'100%' }}
            />
             <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.mobile_no}</p>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Email"
              name="email"
              type='email'
              onChange={handleInputChange}
              value={formData.email || ''}
              sx={{ width:'100%' }}
            />
             <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.email}</p>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              sx={{ width: '100%' }}
              name="password"
              label="Password"
              type={password.value ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={password.onToggle} edge="end">
                      <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleInputChange}
              value={formData.password || ''}
            />
             <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.password}</p>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <FormControl
              sx={{
                flexShrink: 0,
                width: '100%',
              }}
            >
              <Autocomplete
                value={selectedApi}
                onChange={handleApiChange}
                options={apitableData}
                getOptionLabel={(option) => (option.label !== undefined ? option.label : '')}
                renderInput={(params) => (
                  <TextField {...params} label="Select Scheme" variant="outlined" />
                )}
              />
            </FormControl>
            <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.schema_id}</p>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={0.5} alignItems="center">
              <FormControl component="fieldset">
                <RadioGroup row value={formData.gender || ''} onChange={handleInputChange}>
                  <FormControlLabel
                    value="male"
                    control={<Radio size="medium" />}
                    label="Male"
                    name="gender"
                  />
                  <FormControlLabel
                    value="female"
                    control={<Radio size="small" />}
                    label="Female"
                    name="gender"
                  />
                </RadioGroup>
              </FormControl>
              <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.gender}</p>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl sx={{ flexShrink: 0, width: { xs: 5, md: '100%' } }}>
              <InputLabel>Member Type</InputLabel>

              <Select
                name="membar_type"
                value={formData.membar_type || ''}
                onChange={handleInputChange}
                input={<OutlinedInput label="Member Type" />}
                renderValue={(selected) => {
                  const selectedMember = membertype.find((type) => type.value === selected);
                  return selectedMember ? selectedMember.name : '';
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 240,
                      maxWidth: '100%',
                    },
                  },
                }}
              >
                {membertype.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.membar_type}</p>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
          {!checked && (
              <TextField
                type="text"
                name="whatsapp_no"
                label="WhatsApp Number"
                onChange={handleInputChange}
                value={formData.whatsapp_no || ''}
                onKeyPress={handleMobileNoKeyPress}
                onInput={(e) => {
                  setValue('whatsapp_no', e.target.value.replace(/[^0-9]/g, '').slice(0, 10));
                }}
                sx={{ width: '100%' }}
              />
            )}

            <FormControlLabel
              label="Same as WhatsApp number"
              control={<Checkbox size="medium" checked={checked} onChange={handleCheckboxChange} />}
            />
                          <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.whatsapp_no}</p>

          </Grid>
          <Grid item xs={12} md={12} lg={12} textAlign="center" sx={{ mt: 3 }}>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isSubmitting}
              onClick={handleNext}
            >
              Next
            </LoadingButton>
          </Grid>
        </Grid>
      </FormProvider>
      <Dialog open={open} onClose={handleClose} className="add_dialog">
        <form>
          <DialogTitle>Email & Number Verification</DialogTitle>
          <DialogContent>
            <FormProvider {...methods}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 3,
                      mx: 'auto',
                      display: 'block',
                      color: 'text.disabled',
                    }}
                  >
                    Mobile OTP
                  </Typography>
                  <div className="otp_input">
                    <OTPInput
                      value={mobileotp}
                      onChange={handleMobileOtpChange}
                      numInputs={4}
                      renderInput={(props) => <input {...props} />}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 3,
                      mx: 'auto',
                      display: 'block',
                      color: 'text.disabled',
                    }}
                  >
                    Email OTP
                  </Typography>
                  <div className="otp_input">
                    <OTPInput
                      value={emailotp}
                      onChange={handleEmailOtpChange}
                      numInputs={4}
                      renderInput={(props) => <input {...props} />}
                    />
                  </div>
                </Grid>
              </Grid>
            </FormProvider>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton
              onClick={handleSave}
              variant="contained"
              type="submit"
              loading={isSubmitting}
            >
              Save
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </Container>
  );
}
function checkMemberPermission(permissions,isAdmin) {
  const data = [];
  if (permissions?.includes(UsersPermissions.member.CreateRetailer)  || isAdmin===true) {
    data.push({ value: Constants.ROLE.RETAILER, name: 'Retailer' });
  }
  if (permissions?.includes(UsersPermissions.member.CreateDistributore)  || isAdmin===true) {
    data.push({ value: Constants.ROLE.DISTRIBUTOR, name: 'Distributor' });
  }
  if (permissions?.includes(UsersPermissions.member.CreateMasterDistributore)  || isAdmin===true) {
    data.push({ value: Constants.ROLE.MASTER_DISTRIBUTOR, name: 'Master Distributor' });
  }
  if (permissions?.includes(UsersPermissions.member.CreateWhitelabel)  || isAdmin===true) {
    data.push( { value: Constants.ROLE.WHITELABEL, name: 'Whitelabel' });
  }
  return data;
}
RegisterMemberData.propTypes = {
  onNextButtonClick: PropTypes.func.isRequired,
};
