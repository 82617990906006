'use client';

/* eslint-disable import/no-extraneous-dependencies */

import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import React, { useState, useEffect, useCallback } from 'react';

import { Stack } from '@mui/system';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import { DatePicker } from '@mui/x-date-pickers';
import TableContainer from '@mui/material/TableContainer';
import { Grid, Dialog, TextField, DialogTitle, FormControl, DialogActions, DialogContent, InputAdornment, Autocomplete, CircularProgress } from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import FormProvider from 'src/components/hook-form/form-provider';
import {
  useTable,
  getComparator,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';
import OperatorManagerTable from './operator-table';
import operatorTypes from './opraterTypes';


const TABLE_HEAD = [
  { id: 'id', label: 'Id' },
  { id: 'name', label: 'Name' },
  { id: 'type', label: 'Type' },
  { id: 'status', label: 'Status' },
  { id: 'operatorapi', label: 'Operator API' },
  { id: 'action', label: 'Action' },
];


// ----------------------------------------------------------------------

export default function OperatorManagerData() {
  const table = useTable();

  const settings = useSettingsContext();

  const router = useRouter();

  const [tableData, setTableData] = useState([]);
  const [apitableData, setApiTableData] = useState([]);

  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    handleClose();
  };

  const methods = useForm();
  const [formData, setFormData] = useState({
    name: '',
    recharge1: '',
    recharge2: '',
    api_id: '',
    type: '',
    customer_id_type: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData, ...formData,
      [name]: value,
    }));
  };
  const getApiData = async () => {
    setIsSubmitting(true)
    try {
      ApiService.post(Endpoints.getapimanager).then((response) => {
        const responseData = response.data
        // console.log('responseData', responseData)
        if (responseData?.status === true) {
          const apidata = responseData.data.map((element) => ({ value: element.id, label: element.display_name }))
          console.log('responseData.data', apidata)
          setApiTableData(apidata);
        }


        setIsSubmitting(false)

      });
    } catch (error) {
      setIsSubmitting(false)
      console.error("Error submitting form:", error);
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
  const getTableData = async () => {
    setIsSubmitting(true)
    try {
      ApiService.post(Endpoints.providers.get).then((response) => {
        const responseData = response.data
        // console.log('responseData', responseData)
        // if (responseData?.status === true) {
        //   console.log(response.data);
        //   setTableData(responseData.data);
        // }
        setTableData(responseData.data);
        setIsSubmitting(false)

      });
    } catch (error) {
      setIsSubmitting(false)
      console.error("Error submitting form:", error);
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  useEffect(() => {
    getApiData();
    getTableData();
  }, []);


  const onSubmit = async (data) => {
    // console.log(data);

    try {
      setIsSubmitting(true)
      await ApiService.post(Endpoints.providers.create, {...formData,api_id:selectedApi.value}).then(async (response) => {
        // console.log('formData', formData)
        const responseData = response.data
        if (responseData?.status === true) {
          console.log(response.data);
          await getTableData()
          toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          handleClose();
          setIsSubmitting(false)
        } else {
          toast.error(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
        }
      });
    } catch (error) {
      setIsSubmitting(false)
      console.error("Error submitting form:", error);
      toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

  };
  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
  });

  const handleEditRow = useCallback(
    (id) => {
      router.push(`${paths.setuptools.opertaor}`);
    },
    [router]
  );
  const [data, setData] = useState([]); // State to store the fetched data
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    name: '',
  });


  const handleSearch = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const handleSearchButtonClick = () => {
    // Call your API or fetch data based on the filters
    getTableData(filters).then((response) => {
      setData(response.data); // Assuming your API response has a 'data' property
    });
  };

  const [selectedStatus, setSelectedStatus] = React.useState(null);

  const handleStatusChange = (event, newValue) => {
    setSelectedStatus(newValue);
  };

  const options = [
    { value: '1', label: 'Active' },
    { value: '2', label: 'De-Avtive' },
  ]

  const [selectedType, setSelectedType] = React.useState(null);

  const handleTypeChange = (event, newValue) => {
    setSelectedType(newValue);
    setFormData({ ...formData, type: newValue ? newValue.value : null });

  };
  const [selectedApi, setSelectedApi] = React.useState(null);

  const handleApiChange = (event, newValue) => {
    console.log('event, newValue', event, newValue)
    if(newValue?.id){
      setSelectedApi(newValue);
      setFormData({ ...formData, api_id: newValue.id ? newValue.id : null });
    }

  };


  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>

        <CustomBreadcrumbs
          heading="Operator Manager"
          links={[
            { name: 'Dashboard', href: paths.dashboard.root },
            { name: 'Setup Tools' },
            { name: 'Operator Manager' },
          ]}
          action={
            <>
              <LoadingButton
                // onClick={handleOpen}
                variant="contained"
                startIcon={<Iconify icon="mdi:refresh" />}
                sx={{ mr: 1 }}
                loading={isSubmitting}
                onClick={getTableData}
              >
                Refresh
              </LoadingButton>
              <Button
                onClick={handleOpen}
                variant="contained"
                startIcon={<Iconify icon="mingcute:add-line" />}
              >
                Add New
              </Button>
            </>
          }
          sx={{ mb: { xs: 3, md: 5 } }}
        />
        <Card>
          <Stack
            spacing={2}
            alignItems={{ xs: 'flex-end', md: 'center' }}
            direction={{
              xs: 'column',
              md: 'row',
            }}
            sx={{
              p: 2.5,
              // pr: { xs: 2.5, md: 1 },
            }}
          >
            <DatePicker
              label="Start date"
              format="dd/MM/yyyy"
              value={filters.startDate}
              onChange={(date) => handleSearch('startDate', date)}
              // onChange={handleFilterStartDate}
              slotProps={{ textField: { fullWidth: true } }}
              sx={{
                maxWidth: { md: 150 },
              }}
            />

            <DatePicker
              label="End date"
              format="dd/MM/yyyy"
              value={filters.endDate}
              onChange={(date) => handleSearch('endDate', date)}
              // onChange={handleFilterEndDate}
              slotProps={{
                textField: {
                  fullWidth: true,
                  // error: dateError,
                },
              }}
              sx={{
                maxWidth: { md: 150 },
              }}
            />

            <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
              <TextField
                fullWidth
                value={filters.name}
                onChange={(e) => handleSearch('name', e.target.value)}
                // onChange={(e) => handleFilterName(e.target.value)}
                placeholder="Search..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                  ),
                }}
              />

              <FormControl
                sx={{
                  flexShrink: 0,
                  width: { xs: 5, md: 250 },
                }}
              >
                {/* <InputLabel>Scheme</InputLabel> */}

                <Autocomplete
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  options={options}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField {...params} label="Api Status" variant="outlined" />
                  )}
                />
              </FormControl>
            </Stack>


            <FormControl
              sx={{
                flexShrink: 0,
                width: { xs: 5, md: 130 },
              }}
            >
              <Button onClick={handleSearchButtonClick}
                variant="contained" type="submit" startIcon={<Iconify icon="eva:search-fill" />} sx={{ height: 50 }} >
                Search
              </Button>
            </FormControl>
          </Stack>



          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>


            <Scrollbar>

              <Table>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                  sx={{
                    width: 'auto'
                  }}
                />
                {isSubmitting ? (<CircularProgress style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto' }} size={30} />
                ) : (
                  <TableBody>
                    {dataFiltered
                      .slice(
                        table.page * table.rowsPerPage,
                        table.page * table.rowsPerPage + table.rowsPerPage
                      )
                      .map((row) => (
                        <OperatorManagerTable
                          key={row.id}
                          row={row}
                          selected={table.selected.includes(row.id)}
                          onSelectRow={() => table.onSelectRow(row.id)}
                          onEditRow={() => handleEditRow(row.id)}
                          getData={getTableData}
                          apitableData={apitableData}
                        />

                      ))}

                    {/* <TableNoData notFound={notFound} /> */}
                  </TableBody>
                )}
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={dataFiltered.length}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}

          />
        </Card>
      </Container>

      {/*  */}
      <Dialog open={open} onClose={handleClose} className='add_dialog'>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle>Add Operator</DialogTitle>
          <DialogContent>
            <FormProvider {...methods}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField name='name' label="Operator Name" onChange={handleInputChange} value={formData.name || ''} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField name='recharge1' label="Operator Id1" onChange={handleInputChange} value={formData.recharge1 || ''} />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField name='recharge2' label="Operator Id2" onChange={handleInputChange} value={formData.recharge2 || ''} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl
                    sx={{
                      flexShrink: 0,
                      width: '100%',
                    }}
                  >
                    <Autocomplete
                      name="type"
                      value={selectedType}
                      onChange={handleTypeChange}
                      options={operatorTypes}
                      getOptionLabel={(option) => (option.label !== undefined ? option.label : '')}
                      renderInput={(params) => (
                        <TextField {...params} label="Operator Type" variant="outlined" />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl
                    sx={{
                      flexShrink: 0,
                      width: '100%',
                    }}
                  >
                    <Autocomplete
                      name="api_id"
                      value={selectedApi}
                      onChange={handleApiChange}
                      options={apitableData}
                      getOptionLabel={(option) => (option.label !== undefined ? option.label : '')}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Api" variant="outlined" />
                      )}
                    />

                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField name='customer_id_type' label="Name" onChange={handleInputChange} value={formData.customer_id_type || ''} />
                </Grid>
              </Grid>

            </FormProvider>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>Cancel</Button>
            <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
              Save
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>


    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator, filters }) {
  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);


  return inputData;
}
