'use client';

/* eslint-disable import/no-extraneous-dependencies */

import PropTypes from 'prop-types';
import OTPInput from 'react-otp-input';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useState, useCallback } from 'react';

import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { Grid, Button, Dialog, DialogTitle, DialogActions, DialogContent } from '@mui/material';

import { useSearchParams } from 'src/routes/hooks';

import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';

import FormProvider, { RHFTextField, RHFUploadAvatar } from 'src/components/hook-form';

export default function PG_3({ params, onNextStep }) {
  const prms = useSearchParams();
  const tname = prms.get('t');
  console.log('tname', tname)
  const [aadharotp, setAadharotp] = useState("");
  const [aadharfrontimg, setAadharfrontimg] = useState(null);
  const [aadharbackimg, setAadharbackimg] = useState(null);
  const [aadharno, setAadharno] = useState(null);
  const [open, setOpen] = useState(false);
  const [resT, setResT] = useState("");
  const [resPg, setResPg] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState('');

  const handleAadharOtpChange = (value) => {
    setAadharotp(value);

  };
  const handleClose = () => {
    setOpen(false);
  };

  const [errors, setErrors] = useState({
    frontimg: '',
    backimg: '',
    aadhar: '',
  });

  const handleSave = () => {
    const sendObj = {
      t: resT,
      otp: aadharotp
    }

    try {
      setIsSubmitting(true)
      ApiService.post(Endpoints.aadharotpverify, sendObj).then((response) => {
        const responseData = response.data;
        console.log(response.data);
        if (responseData?.status === true) {
          onNextStep(response.data?.data?.pg, response.data?.data?.t);
          toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          handleClose();
        } else {
          setIsSubmitting(false)
          toast.error( responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

        setIsSubmitting(false)
      });
    } catch (error) {
      setIsSubmitting(false)
      console.error("Error submitting form:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requiredFields = ['aadhar', 'frontimg', 'backimg'];
    const newErrors = {};

    // Check if any of the required fields are empty
    requiredFields.forEach((field) => {
        if (field === 'aadhar' && !aadharno) {
            newErrors.aadhar = 'Aadharno is required';
        } else if (field === 'frontimg' && !aadharfrontimg) {
            newErrors.frontimg = 'Frontimg is required';
        } else if (field === 'backimg' && !aadharbackimg) {
            newErrors.backimg = 'Backimg is required';
        }
    });

    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error !== '')) {
        return;
    }

    try {
      setIsSubmitting(true)
      await ApiService.post(Endpoints.aadharcard, {
        aadhar_no: aadharno,
        front_image: aadharfrontimg,
        back_image: aadharbackimg,
        t: tname
      }).then((response) => {
        setIsSubmitting(false)
        const responseData = response.data;
        setMessage(responseData ? responseData.message : 'No message available');
        console.log(responseData);

        if (responseData?.status === true) {
          console.log(response.data);

          if (responseData?.data?.show_model === 1) {
            setResPg(response.data?.data?.pg)
            setResT(response.data?.data?.t)
            toast.success(responseData ? responseData.message : 'No message available', {
              position: toast.POSITION.TOP_RIGHT,
            });
            setOpen(true);
          }

        } else {
          setIsSubmitting(false)
          toast.error(responseData ? responseData.message : 'No message available', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });

    } catch (error) {
      setIsSubmitting(false)
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    };
  }

  const methods = useForm({});

  const {
    setValue,
  } = methods;



  const handleDropFront = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (!file) {
        console.error('No file selected');
        return;
      }

      // Check if the file format is allowed
      const allowedFormats = ['.jpeg', '.jpg', '.png'];
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (!allowedFormats.includes(`.${fileExtension}`)) {
        console.error(`Invalid file format. Allowed formats: ${allowedFormats.join(', ')}`);
        toast.error(`Invalid file format. Allowed formats: ${allowedFormats.join(', ')}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }



      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;

        if (!base64String) {
          console.error('Failed to read file');

          return;
        }

        // Update comment
        setAadharfrontimg(base64String);

        const newFile = Object.assign(file, {
          preview: URL.createObjectURL(file),
          base64: base64String,
        });

        setValue('frontimg', newFile, { shouldValidate: true });
      };

      reader.onerror = (error) => {
        console.error('Error reading the file:', error);
        // You can provide user feedback here, e.g., display an error message
      };

      reader.readAsDataURL(file);
    },
    [setValue, setAadharfrontimg]
  );
  const handleDropBack = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (!file) {
        console.error('No file selected');
        return;
      }

      // Check if the file format is allowed
      const allowedFormats = ['.jpeg', '.jpg', '.png'];
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (!allowedFormats.includes(`.${fileExtension}`)) {
        console.error(`Invalid file format. Allowed formats: ${allowedFormats.join(', ')}`);
        toast.error(`Invalid file format. Allowed formats: ${allowedFormats.join(', ')}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }



      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;

        if (!base64String) {
          console.error('Failed to read file');

          return;
        }

        // Update comment
        setAadharbackimg(base64String);

        const newFile = Object.assign(file, {
          preview: URL.createObjectURL(file),
          base64: base64String,
        });

        setValue('backimg', newFile, { shouldValidate: true });
      };

      reader.onerror = (error) => {
        console.error('Error reading the file:', error);
        // You can provide user feedback here, e.g., display an error message
      };

      reader.readAsDataURL(file);
    },
    [setValue, setAadharbackimg]
  );

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
      <Typography variant="h4">Aadharcard Verification</Typography>


    </Stack>
  );

  const renderTerms = (
    <Typography
      component="div"
      sx={{
        color: 'text.secondary',
        mt: 2.5,
        typography: 'caption',
        textAlign: 'center',
      }}
    >
      {'By signing up, I agree to '}
      <Link underline="always" color="text.primary">
        Terms of Service
      </Link>
      {' and '}
      <Link underline="always" color="text.primary">
        Privacy Policy
      </Link>
      .
    </Typography>
  );

  const renderForm = (
    <FormProvider methods={methods} onSubmit={handleSubmit}>
      <Stack spacing={2.5}>
        <RHFTextField name="aadhar" label="Aadharcard Number" onChange={(e) => { setAadharno(e.target.value) }} value={aadharno || ''} />
        <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.aadhar}</p>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

          <Stack ddirection={{ xs: 'column', sm: 'row' }} spacing={0.5}>
            <Typography variant="body2" sx={{ mb: 2, }}> Aadharcard Front Image </Typography>

            <RHFUploadAvatar
              name="frontimg"
              maxSize={2097152}
              onDrop={handleDropFront}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 3,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.disabled',
                  }}
                >
                  Allowed *.jpeg, *.jpg, *.png
                </Typography>
              }
              sx={{ borderRadius: 0 }} />
              <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.frontimg}</p>

          </Stack>
          <Stack ddirection={{ xs: 'column', sm: 'row' }} spacing={0.5}>
            <Typography variant="body2" sx={{ mb: 2, }}> Aadharcard Back Image </Typography>
            <RHFUploadAvatar
              name="backimg"
              maxSize={2097152}
              onDrop={handleDropBack}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 3,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.disabled',
                  }}
                >
                  Allowed *.jpeg, *.jpg, *.png

                </Typography>
              }
              sx={{ borderRadius: 0 }} />
                      <p style={{ color: 'red', margin: '0',fontSize:'12px' }}>{errors.backimg}</p>

          </Stack>
        </Stack>
        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Next
        </LoadingButton>
      </Stack>
    </FormProvider>
  );

  return (
    <>
      {renderHead}

      {renderForm}

      {renderTerms}
      <Dialog open={open} onClose={handleClose} className='add_dialog'>
        <form>
          <DialogTitle>Aadharcard Verification</DialogTitle>
          <DialogContent>
            <FormProvider {...methods}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>

                  <Typography
                    variant="caption"
                    sx={{
                      mb: 1,
                      mx: 'auto',
                      fontSize: '18px',
                      display: 'block',
                      textAlign: 'center',
                      color: '#00a76f',
                      fontWeight: '600'
                    }}
                  >
                    {message}
                  </Typography>
                  <div className='otp_input'>
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 0,
                        mx: 'auto',
                        display: 'block',
                        color: 'text.disabled',
                      }}
                    >
                      Aadharcard OTP
                    </Typography>
                    <OTPInput
                      value={aadharotp}
                      onChange={handleAadharOtpChange}
                      numInputs={6}
                      renderInput={(props) => <input {...props} />}
                    />
                  </div>
                </Grid>
              </Grid>
            </FormProvider>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>Cancel</Button>
            <LoadingButton onClick={handleSave} variant="contained" type="button" loading={isSubmitting}
            >
              Verify
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

PG_3.propTypes = {
  params: PropTypes.object.isRequired,
  onNextStep: PropTypes.func.isRequired,
};
