'use client'

/* eslint-disable import/no-extraneous-dependencies */

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { useBoolean } from 'src/hooks/use-boolean';


import moment from 'moment';
import Label from 'src/components/label';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
import { usePopover } from 'src/components/custom-popover';




// ----------------------------------------------------------------------

export default function ChangeIconTable({ row, selected, onEditRow, getData }) {
  // const { id, mobile, display_name, updatedAt } = row;
  const [isSubmitting, setIsSubmitting] = useState()
  const quickCompalints = useBoolean();
  const quickEdit = useBoolean();
  const popover = usePopover();
  const jsonData = JSON.stringify(JSON.parse(row?.data), null, 2)
  const format = moment(row.createdAt).format('D-MM-YYYY')
  // , h:mm:ss a

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {row?.id}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}> {row?.User_Change_User?.first_name}{row?.User_Change_User?.last_name}
          <br /> ({row?.User_Change_User?.mobile_no})<br />({row?.User_Change_User?.Role?.display_name}) </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {row?.User_Change_Update?.first_name}{row?.User_Change_Update?.last_name}
          <br /> {row?.User_Change_Update?.mobile_no}
          <br />({row?.User_Change_Update?.Role?.display_name})
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {row?.type}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }} >
          Browser: {row?.browser}<br />
          IP: {row?.client_ip}<br />
          OS: {row?.clientos}<br />
          Type: {row?.isMobile === '0' ? "Web" : 'Mobile'}<br />
          Lat: {row?.latitude}<br />
          Log: {row?.longitude}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }} >
          <Button variant='contained' onClick={handleOpen} sx={{ padding:0 ,minWidth:'auto'}}>
            <IconButton style={{ color: 'white' }}>
              <Iconify icon="carbon:view" />
            </IconButton>
          </Button>
        </TableCell>


      </TableRow>
      {/* <AepsStatementEdit currentUser={row} getData={getData} aepsopen={quickEdit.value} onClose={quickEdit.onFalse} /> */}
      {/* <AepsStatementComplaints currentUser={row} getData={getData} compalints={quickCompalints.value} onClose={quickCompalints.onFalse} /> */}


      <Dialog open={open} onClose={handleClose} className='add_dialog' sx={{ maxWidth: '1000px', m: 'auto' }}>
        <form >
          <DialogTitle>
            View Data
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <pre>
                  {jsonData}
                </pre>
              </Grid>
            </Grid>

          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>Cancel</Button>
            {/* <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
              Download Receipt
            </LoadingButton> */}
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

ChangeIconTable.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  getData: PropTypes.func,
};
