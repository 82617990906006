
import axios from 'axios';

// async function getUserIpAddress() {
//   try {
//     const response = await axios.get('https://api.ipify.org/?format=json');
//     return response.data.ip;
//   } catch (error) {
//     console.error('Error fetching IP address:', error);
//     return null;
//   }
// }

const ApiService = axios.create({
  // baseURL: 'http://ec2-65-0-139-4.ap-south-1.compute.amazonaws.com:3001/'
  // baseURL: 'https://bb54-2402-a00-162-c3aa-c763-5b12-6453-ef5d.ngrok-free.app/'
  // baseURL: 'http://65.0.139.4:3001/'
  // baseURL: 'http://192.168.1.19:3001/'
  // baseURL: 'http://localhost:3001/',
  baseURL: 'https://hallipay2apis.hallipay.com/'
  // baseURL: 'http://uatapi.hallipay.com:3001/'
  // baseURL: 'https://api.hallipay.com/'
  // baseURL: 'http://192.168.1.7:3001/'
})

// Request interceptor
ApiService.interceptors.request.use(
  (config) => {
    const accessToken = sessionStorage.getItem('accessToken');
    // const ipAddress = await getUserIpAddress();

    if (accessToken) {
      if (config.headers) config.headers.token = accessToken;
    }
    // if (ipAddress) {
    //   config.headers.ipAddress = ipAddress;
    // }
    return config;
  },
  (error) =>
    // Handle request errors here

    Promise.reject(error)
);
// End of Request interceptor

// Response interceptor
ApiService.interceptors.response.use(
  (response) =>
    // Modify the response data here

    response,
    // (error) => Promise.reject(error.response.data)

    (error) => Promise.reject(error.response)

);
// End of Response interceptor

export default ApiService;
