/* eslint-disable import/no-extraneous-dependencies */
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import React, { useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { TextField, FormControl, Autocomplete } from '@mui/material';

import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';

import FormProvider, { RHFTextField } from 'src/components/hook-form';
import operatorTypes from './opraterTypes';

// ----------------------------------------------------------------------

export default function OperatorManagerEdit({ apitableData, currentUser, apimanager, onClose, getData }) {
  const [isSubmitting, setIsSubmitting] = useState();
  const [tableData, setTableData] = useState([]);

  const defaultValues = useMemo(
    () => ({
      id: currentUser?.id,
      name: currentUser?.name || '',
      recharge1: currentUser?.recharge1 || '',
      recharge2: currentUser?.recharge2 || '',
      type: currentUser?.type || '',
      api_id: currentUser?.api_id || '',
      customer_id_type: currentUser?.customer_id_type || '',
    }),
    [currentUser]
  );

  const methods = useForm({
    defaultValues,
  });
  const {
    handleSubmit,
  } = methods;


  const onSubmit = handleSubmit(async (data) => {
    try {
      setIsSubmitting(true)
      await ApiService.post(Endpoints.providers.update, { ...data, api_id: selectedApi, type: selectedType }).then(async (response) => {
        const responseData = response.data
        if (responseData?.status === true) {
          await getData();
          toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
          onClose();
        } else {
          toast.error(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
        }

      });
    } catch (error) {
      setIsSubmitting(false)
      console.error("Error submitting form:", error);
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

  });

  const [selectedType, setSelectedType] = React.useState({ type: defaultValues.type });

  const handleTypeChange = (event, newValue) => {
    const selectedValue = newValue ? newValue.value : null;
    setSelectedType(selectedValue);

  };
  // const operatorTypes = [
  //   { value: "prepaid", label: "Prepaid" },
  //   { value: "dth", label: "DTH" },
  //   { value: "electricity", label: "Electricity" },
  //   { value: "broadband", label: "Broadband" },
  //   { value: "cable", label: "Cable" },
  //   { value: "datacardpostpaid", label: "Datacard Postpaid" },
  //   { value: "datacardprepaid", label: "Datacard Prepaid" },
  //   { value: "emi", label: "EMI Payment" },
  //   { value: "fasttag", label: "Fasttag" },
  //   { value: "gas", label: "Gas" },
  //   { value: "hospital", label: "Hospital" },
  //   { value: "insurance", label: "Insurance" },
  //   { value: "landline", label: "Landline" },
  //   { value: "lpgbooking", label: "LPG Booking" },
  //   { value: "municipality", label: "Municipality" },
  //   { value: "postpaid", label: "Postpaid" },
  //   { value: "water", label: "Water" },
  //   { value: "pancard", label: "Pancard" },
  //   { value: "dmt", label: "Dmt" },
  //   { value: "aeps", label: "Aeps" },
  //   { value: "matm", label: "Matm" },
  //   { value: "fund", label: "Fund" },
  //   { value: "nsdlpan", label: "Nsdl Pancard" },
  //   { value: "cash", label: "Cash Deposit" },
  //   { value: "aadhar", label: "Aadhar Pay" },
  //   { value: "fd", label: "Fixed Deposit" },
  //   { value: "paytm", label: "Paytm Wallet" },
  //   { value: "mobikwik", label: "Mobikwik Wallet" },
  //   { value: "faeps", label: "FAeps" },
  //   { value: "faadhar", label: "Fingpay Aadhar" },
  //   { value: "travel", label: "Travel" },
  //   { value: "airtelcms", label: "Airtel CMS" }
  // ];


  const [selectedApi, setSelectedApi] = React.useState(defaultValues.api_id);

  const handleApiChange = (event, newValue) => {
    setSelectedApi(newValue?.value);

  };



  return (
    <Dialog
      maxWidth={false}
      open={apimanager}
      onClose={onClose}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>Edit Operator</DialogTitle>

        <DialogContent className='mt-2'>

          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField name="name" label="Operator Name" />
            <RHFTextField name="recharge1" label="Operator Id1" />
            <RHFTextField name="recharge2" label="Operator Id2" />

            <FormControl
              sx={{
                flexShrink: 0,
                width: '100%',
              }}
            >
              <Autocomplete
                name="type"
                value={operatorTypes.find((option) => option.value === currentUser?.type) || null}
                onChange={handleTypeChange}
                options={operatorTypes}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField {...params} label="Operator Type" variant="outlined" />
                )}
              />
            </FormControl>


            <FormControl
              sx={{
                flexShrink: 0,
                width: '100%',
              }}
            >

              <Autocomplete
                name="api_id"
                value={apitableData.find((option) => option.value === currentUser?.api_id) || null}
                onChange={handleApiChange}
                options={apitableData}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField {...params} label="Api" variant="outlined" />
                )}
              />
            </FormControl>
            <RHFTextField name="customer_id_type" label="Name" />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Close
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Submit
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>


  );
}

OperatorManagerEdit.propTypes = {
  currentUser: PropTypes.object,
  apitableData: PropTypes.object,
  onClose: PropTypes.func,
  getData: PropTypes.func,
  apimanager: PropTypes.bool,
};
