import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { useMockedUser } from 'src/hooks/use-mocked-user';

import { fData } from 'src/utils/format-number';

import { countries } from 'src/assets/data';

import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFSwitch,
  RHFTextField,
  RHFUploadAvatar,
  RHFAutocomplete,
} from 'src/components/hook-form';
import { TextField } from '@mui/material';
import ApiService from 'src/common';
import { toast } from 'react-toastify';
import Endpoints from 'src/common/endpoints';

// ----------------------------------------------------------------------

export default function BankData({ row }) {

  const [isSubmitting, setIsSubmitting] = useState(false);
  const defaultValues = useMemo(
    () => ({
      id:row?.id,
      account_number: row?.account_number,
      beneficiary_name: row?.beneficiary_name,
      ifsc: row?.ifsc,
      name: row?.name,
    }),
    [row]
  );

  const methods = useForm({
    defaultValues,
  });
  const {
    setValue,
    handleSubmit,
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    console.log('data',data);
    let endpointAdd;
    if(data?.id){
      endpointAdd=Endpoints.profile.bankaccountupdate
    }else{
      endpointAdd=Endpoints.profile.bankaccountcreate
    }
    try {
      setIsSubmitting(true)
      await ApiService.post(endpointAdd, data).then(async (response) => {
        const responseData = response.data
        if (responseData?.status === true) {
          console.log(response.data);
          toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
        } else {
          toast.error(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
        }
      });
    } catch (error) {
      setIsSubmitting(false)
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  });



  return (

    <div className='append_form'>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Grid container spacing={3}>
          <Grid xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Box
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 2fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <RHFTextField name="account_number" label="Account Number" />
                <RHFTextField name="name" label="Bank Name" />
                <RHFTextField name="beneficiary_name" label="Beneficiary Name" />
                <RHFTextField name="ifsc" label="Ifsc Code" />
              </Box>


              <span style={{ display: 'block', textAlign: 'center' }}>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting} sx={{ mt: 3 }}>
                  Submit
                </LoadingButton>
              </span>

            </Card>
          </Grid>
        </Grid>
      </FormProvider>
    </div>
  );
}
BankData.propTypes = {
  row: PropTypes.object,
};
