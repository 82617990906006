'use client';

/* eslint-disable import/no-extraneous-dependencies */
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import React, { useState, useCallback, useEffect } from 'react';

import { Stack } from '@mui/system';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import { DatePicker } from '@mui/x-date-pickers';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import {
  Grid,
  Dialog,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  DialogTitle,
  FormControl,
  DialogActions,
  DialogContent,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
  Autocomplete,
  CardHeader,
  CardContent,
  Tabs,
  tabsClasses,
  Tab,
} from '@mui/material';

import { paths } from 'src/routes/paths';
import { useParams, useRouter, useSearchParams } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';

import { _userList } from 'src/_mock';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import FormProvider from 'src/components/hook-form/form-provider';
import {
  useTable,
  getComparator,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';
import { toast } from 'react-toastify';
// import { notFound } from 'next/navigation';

// const STATUS_OPTIONS = [{ value: 'all', label: 'All' }, ...USER_STATUS_OPTIONS];

// ----------------------------------------------------------------------

export default function AddressMemberData({ onPrevButtonClick }) {
  const table = useTable();

  const settings = useSettingsContext();

  const router = useRouter();

  const confirm = useBoolean();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [resT, setResT] = useState('');
  const prms = useSearchParams();
  const tname = prms.get('t');
  const [address, setAddress] = useState(false);
  const [district, setDistrict] = useState(false);
  const [state, setState] = useState(false);
  const [pincode, setPincode] = useState(false);
  const [city, setCity] = useState(false);
  const { id } = useParams();

  const [selectedState, setSelectedState] = useState(null);
  console.log('selectedState', selectedState)
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  console.log('selectedDistrict', selectedDistrict)
  const [stateData, setStateData] = useState();
  const [districtData, setDistrictData] = useState();
  const [isloadingData, setIsloadingData] = useState(true);

  const methods = useForm();
  const { setValue } = methods;

  const [errors, setErrors] = useState({
    address: '',
    state: '',
    district: '',
    city: '',
    pincode: '',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const requiredFields = [ 'address', 'state','district', 'city', 'pincode'];
    // const newErrors = {};

    // // Check if any of the required fields are empty
    // requiredFields.forEach((field) => {
    //   if (!errors[field]) {
    //     newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
    //   } else {
    //     newErrors[field] = '';
    //   }
    // });

    // setErrors(newErrors);

    // if (Object.values(newErrors).some((error) => error !== '')) {
    //   return;
    // }

    try {
      setIsSubmitting(true);
      await ApiService.post(Endpoints.membercreate.pg4, {
        t: tname,
        district: selectedDistrict?.label,
        address,
        state: selectedState?.label,
        city,
        pincode,
      }).then((response) => {
        setIsSubmitting(false);
        const responseData = response.data;

        if (responseData?.status === true) {
          setResT(response.data?.data?.t);
          router.push(paths.member.unregistered);
          toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setIsSubmitting(false);
          toast.error(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    } catch (error) {
      setIsSubmitting(false);
      toast.error('Error submitting form', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handlePrev = async (e) => {
    e.preventDefault();
    onPrevButtonClick(id, 3, resT);
  };

  // state get

  const getStateData = async () => {
    try {
      ApiService.post(Endpoints.portalsetting.getstate).then((response) => {
        const responseData = response.data;
        if (responseData?.status === true) {
          const apidata = responseData.data
            // .filter((element) => element.status === true)
            .map((element) => ({
              value: element.id,
              label: element.name,
            }));
          setStateData(apidata);
        }
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Error submitting form', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const getDistrictData = async (state_id) => {
    try {
      ApiService.post(Endpoints.portalsetting.getdistrict, { id: state_id }).then((response) => {
        console.log('response', response);
        const responseData = response.data;
        console.log('responseData', responseData);
        if (responseData?.status === true) {
          console.log('responseData', responseData);

          const apidata = responseData.data
            // .filter((element) => element.status === true)
            .map((element) => ({
              value: element.id,
              label: element.name,
            }));
          setDistrictData(apidata);
          console.log('apidata', apidata);
        }
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Error submitting form', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleStateChange = async (event, newValue) => {
    if (newValue?.value) {
      setSelectedState(newValue);
      await getDistrictData(newValue?.value);

      // setFormData({ ...formData, schema_id: newValue.value ? newValue.value : null });
    }
  };
  const handlePincodeNoKeyPress = (e) => {
    // Allow only numeric digits (0-9)
    if (e.key && !/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
    if (e.target.value.length >= 5) {
      e.preventDefault();
    }
  };
  const handleDistrictChange = (event, newValue) => {
    if (newValue?.value) {
      setSelectedDistrict(newValue);

      // setFormData({ ...formData, schema_id: newValue.value ? newValue.value : null });
    }
  };

  const getData = async () => {
    try {
      const response = await ApiService.post(Endpoints.memberprofile.unregisterstatusget, { id });
      const responseData = response.data;
      console.log('responseData', responseData);

      if (responseData?.status === true) {
        setAddress(responseData?.data?.data?.address);
        setSelectedDistrict(responseData?.data?.data?.district);
        setSelectedState(responseData?.data?.data?.state);
        setPincode(responseData?.data?.data?.pincode);
        setCity(responseData?.data?.data?.city);
        setResT(responseData?.data?.t);

        setIsloadingData(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error); // Add this line to log the error
      toast.error('Error fetching data', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    getStateData();
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      {isloadingData === false ? (
        <FormProvider {...methods}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Address"
                name="address"
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                value={address || ''}
                sx={{ width: '100%' }}
              />
              <p style={{ color: 'red', margin: '0', fontSize: '12px' }}>{errors.address}</p>

            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              {/* <TextField label="State" name='state' onChange={(e) => { setState(e.target.value) }} value={state || ''} sx={{ width:'100%' }}/> */}
              <FormControl
                sx={{
                  flexShrink: 0,
                  width: '100%',
                }}
              >
                <Autocomplete
                  name="state"
                  value={selectedState}
                  onChange={handleStateChange}
                  options={stateData}
                  getOptionLabel={(option) => (option.label !== undefined ? option.label : '')}
                  renderInput={(params) => (
                    <TextField {...params} label="Select State" variant="outlined" />
                  )}
                />
              </FormControl>
              <p style={{ color: 'red', margin: '0', fontSize: '12px' }}>{errors.state}</p>

            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {/* <TextField label="District" name='district' onChange={(e) => { setDistrict(e.target.value) }} value={district || ''} sx={{ width:'100%' }}/> */}
              <FormControl
                sx={{
                  flexShrink: 0,
                  width: '100%',
                }}
              >
                <Autocomplete
                  name="district"
                  value={selectedDistrict}
                  onChange={handleDistrictChange}
                  options={districtData}
                  getOptionLabel={(option) => (option.label !== undefined ? option.label : '')}
                  renderInput={(params) => (
                    <TextField {...params} label="Select District" variant="outlined" />
                  )}
                />
              </FormControl>
              <p style={{ color: 'red', margin: '0', fontSize: '12px' }}>{errors.district}</p>

            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="City"
                name="city"
                onChange={(e) => {
                  setCity(e.target.value);
                }}
                value={city || ''}
                sx={{ width: '100%' }}
              />
              <p style={{ color: 'red', margin: '0', fontSize: '12px' }}>{errors.city}</p>

            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Pincode"
                name="pincode"
                onChange={(e) => {
                  setPincode(e.target.value);

                }}
                onKeyPress={handlePincodeNoKeyPress}
                onInput={(e) => { setValue('mobile_no', e.target.value.replace(/[^0-9]/g, '').slice(0, 5)) }}
                value={pincode || ''}
                sx={{ width: '100%' }}
              />
              <p style={{ color: 'red', margin: '0', fontSize: '12px' }}>{errors.pincode}</p>
            </Grid>
            <Grid item xs={12} md={12} lg={12} textAlign="center" sx={{ mt: 3 }}>
              <LoadingButton variant="contained" type="submit" onClick={handlePrev} sx={{ mr: 1 }}>
                Previous
              </LoadingButton>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                Save
              </LoadingButton>
            </Grid>
          </Grid>
        </FormProvider>
      ) : (
        <CircularProgress
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 'auto',
          }}
          size={30}
        />
      )}
    </Container>
  );
}

AddressMemberData.propTypes = {
  onPrevButtonClick: PropTypes.func.isRequired,
};
