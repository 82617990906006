/* eslint-disable import/no-extraneous-dependencies */
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import React, { useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { TextField, FormControl, Autocomplete } from '@mui/material';

import ApiService from 'src/common';
import Endpoints from 'src/common/endpoints';

import FormProvider, { RHFTextField } from 'src/components/hook-form';

// ----------------------------------------------------------------------

export default function ApiMangaerEdit({ currentUser, apimanager, onClose, getData }) {
  const [isSubmitting, setIsSubmitting] = useState();
  const [tableData, setTableData] = useState([]);

  const defaultValues = useMemo(
    () => ({
      product_name:currentUser?.product_name || '',
      display_name: currentUser?.display_name || '',
      api_code: currentUser?.api_code || '',
      url: currentUser?.url || '',
      username: currentUser?.username || '',
      password: currentUser?.password || '',
      optional1: currentUser?.optional1 || '',
      type: currentUser?.type || '',
    }),
    [currentUser]
  );

  const methods = useForm({
    defaultValues,
  });


  const [selectedStatus, setSelectedStatus] = React.useState(null);

  const handleStatusChange = (event, newValue) => {
    setSelectedStatus(newValue);
  };
  const {
    handleSubmit,
  } = methods;


  const onSubmit = handleSubmit(async (data) => {
    try {
      setIsSubmitting(true)
      await ApiService.post(Endpoints.updateapimanager, { ...data, role_id: currentUser?.id }).then(async (response) => {
        const responseData = response.data
        if (responseData?.status === true) {
          await getData();
          toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
          onClose();
        } else {
          toast.error(responseData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false)
        }

      });
    } catch (error) {
      setIsSubmitting(false)
      console.error("Error submitting form:", error);
      toast.error("Error submitting form", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

  });
  const options = [
    { value: 'active', label: 'Active' },
    { value: 'de-active', label: 'De-active' },
    { value: 'vip-plan', label: 'VIP Plan' },
    { value: 'subscriber', label: 'Subscriber' },
  ];


  const [selectedType, setSelectedType] = React.useState(defaultValues.type);

  const handleTypeChange = (event, newValue) => {
    setSelectedType(newValue);

  };


  const type = [
    { value: 'recharge', label: 'Recharge' },
    { value: 'payment', label: 'Bill Payment' },
    { value: 'money', label: 'Money' },
  ]
  return (
      <Dialog
        maxWidth={false}
        open={apimanager}
        onClose={onClose}
      >
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <DialogTitle>Edit Role</DialogTitle>

          <DialogContent className='mt-2'>

            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField name="product_name" label="Product Name" />
              <RHFTextField name="display_name" label="Display Name" />
              <RHFTextField name="api_code" label="Api Code" />
              <RHFTextField name="url" label="Url" />
              <RHFTextField name="username" label="username" />
              <RHFTextField name="password" label="Password" />
              <RHFTextField name="optinal1" label="Optinal1" />

              <FormControl
                    sx={{
                      flexShrink: 0,
                      width: { xs: 5, md: 250 },
                    }}
                  >
                    {/* <InputLabel>Scheme</InputLabel> */}

                      <Autocomplete
                        name="type"
                        onChange={handleTypeChange}
                        options={type}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField {...params} label="Product Type" variant="outlined" />
                        )}
                      />
                  </FormControl>
            </Box>
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" onClick={onClose}>
              Close
            </Button>

            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              Submit
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </Dialog>


  );
}

ApiMangaerEdit.propTypes = {
  currentUser: PropTypes.object,
  onClose: PropTypes.func,
  getData: PropTypes.func,
  apimanager: PropTypes.bool,
};
