'use client';

import React, { useState, useEffect, useCallback } from "react";

import { LoadingButton } from "@mui/lab";
import { Tab, Grid, Tabs, Card, Table, Radio, Dialog, Button, TableRow, TableHead, TableCell, TableBody, CardHeader, RadioGroup, tabsClasses, DialogTitle, CardContent, DialogContent, DialogActions, TableContainer, FormControlLabel } from "@mui/material";

import Biometric from "src/common/biometric";

import { useAuthContext } from "src/auth/hooks";
import Iconify from "src/components/iconify";
import ApiService from "src/common";
import Endpoints from "src/common/endpoints";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import CashWithdrawal from "./cash_tab";
import AadharPay from "./aadharpay_tab";
import BalanceEnquiry from "./enquiry_tab";
import MiniStatement from "./statement_tab";



const TABS = [
  {
    value: 'balanceenquiry',
    label: 'Balance Enquiry',
    icon: <Iconify icon="mdi:credit-card-outline" width={24} />,
  },
  {
    value: 'cashwithdrawal',
    label: 'Cash Withdrawal',
    icon: <Iconify icon="mdi:cash-multiple" width={24} />,
  },
  {
    value: 'ministatement',
    label: 'Mini Statement',
    icon: <Iconify icon="mdi:file-document-outline" width={24} />,
  },
  {
    value: 'aadharpay',
    label: 'Aadhar Pay',
    icon: <Iconify icon="mdi:credit-card-multiple-outline" width={24} />,
  },
];

export default function FAepsData() {

  const { user } = useAuthContext();
  const [currentTab, setCurrentTab] = useState('balanceenquiry');

  const [pStatus, setPStatus] = useState(1); // Assuming initial status is 0

  const [open, setOpen] = useState(user?.is_authentication_register === true ? (!user?.daily_authenticate) : false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [capturData, setCapturData] = useState('');

  // ip fetch
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [ipAddress, setIpAddress] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);


          // Update formData with location data
          setFormData((prevData) => ({
            ...prevData,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }));
        },
      );
    }

    fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data1) => {
        setIpAddress(data1.ip);

        // Update formData with IP address
        setFormData((prevData) => ({
          ...prevData,
          ipaddress: data1.ip,
        }));
      })
      .catch((error) => console.error('Error getting IP address:', error));
  }, []);



  const onChangeCaptureData = (data) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      rawData: data,
    }));
    setCapturData(data)
  }
  const [formData, setFormData] = useState({
    latitude: null,
    longitude: null,
    ipaddress: null,
  });

  const handleClose = () => {
    setOpen(false);
    setRegister(false);
  }

  const methods = useForm({
  });
  const [reister, setRegister] = useState(!user?.is_authentication_register);

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitting(true)
      await ApiService.post(Endpoints.twofactorregister, formData).then((response) => {
        const responseData = response.data
        toast.success(responseData.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
      setIsSubmitting(false)
      handleClose();
    } catch (error) {
      setIsSubmitting(false)
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

  }

  const handleSubmit = async (data) => {
    try {
      setIsSubmitting(true)
      await ApiService.post(Endpoints.twofactordaily, formData).then((response) => {
        // console.log( response.data);
        const responseData = response.data
        toast.success(responseData.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
      setIsSubmitting(false)

    } catch (error) {
      setIsSubmitting(false)
      // console.error("Error submitting form:", error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setOpen(false);
  }


  const handleCashWithdrawalClick = async (data) => {
    // alert(1)
    // try {
    //   setIsSubmitting(true)
    //   await ApiService.post(Endpoints.checkcashwithdraw).then((response) => {
    //     const responseData = response.data
    //     console.log('responseData', responseData)
    //     toast.success(responseData.message, {
    //       position: toast.POSITION.TOP_RIGHT,
    //     });
    //   });
    //   setIsSubmitting(false)

    // } catch (error) {
    //   setIsSubmitting(false)
    //   // console.error("Error submitting form:", error);
    //   toast.error(error.message, {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // }
  }


  const handleChangeTab = ((event, newValue) => {
    setCurrentTab(newValue);
    if (newValue === 'cashwithdrawal') {
      handleCashWithdrawalClick();
    }
  });



  const OnBoarding = (
    <Grid container>
      <Grid container>
        <Grid item xs={12} md={8} sx={{
          mx: 'auto',
        }}>

          <Card sx={{ p: 2 }} >
            <CardHeader title="AEPS Onboarding" sx={{ mb: 2 }} />
            <CardContent >
              <TableContainer sx={{ overflow: 'unset' }}>
                <Table sx={{ minWidth: "auto" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>BC ID</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>STEK17175</TableCell>

                      <TableCell>9019519363</TableCell>

                      <TableCell>Not initiated Process</TableCell>

                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
              <div className="text-center">

                <LoadingButton
                  color="inherit"
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{
                    mb: 3,
                  }}
                >
                  Initiate Onboarding Process
                </LoadingButton>
              </div>

            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );

  const Aeps = (
    <>
      <Grid container sx={{ mb: 2 }} >
        <Grid item xs={12} md={12} sx={{
          mx: 'auto',
          position: 'relative'

        }}>
          <Card sx={{ p: 2 }} >
            <CardHeader title="Biometric Devices" />
            <CardContent >
              <RadioGroup row defaultValue="mantra">
                <Grid item xs={12} md={6} lg={4} >
                  <FormControlLabel value="mantra" control={<Radio size="medium" />} label="Mantra" />
                </Grid>
                <Grid item xs={12} md={6} lg={4} >
                  <FormControlLabel value="secugen" control={<Radio size="medium" />} label="Secugen" />
                </Grid>
                <Grid item xs={12} md={6} lg={4} >
                  <FormControlLabel value="next" control={<Radio size="medium" />} label="Next" />
                </Grid>
                <Grid item xs={12} md={6} lg={4} >
                  <FormControlLabel value="tatvik" control={<Radio size="medium" />} label="Tatvik TMF20" />
                </Grid>
                <Grid item xs={12} md={6} lg={4} >
                  <FormControlLabel value="morpho" control={<Radio size="medium" />} label="Morpho" />
                </Grid>
                <Grid item xs={12} md={6} lg={4} >
                  <FormControlLabel value="precision" control={<Radio size="medium" />} label="Precision" />
                </Grid>
                <Grid item xs={12} md={6} lg={4} >
                  <FormControlLabel value="startek" control={<Radio size="medium" />} label="Startek" />
                </Grid>
                <Grid item xs={12} md={6} lg={4} >
                  <FormControlLabel value="aratek" control={<Radio size="medium" />} label="Aratek" />
                </Grid>
              </RadioGroup>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12} sx={{
          mx: 'auto',
          position: 'relative'

        }}>
          <Card sx={{ p: 2 }} >
            <CardHeader title="FAEPS" />
            <CardContent sx={{ p: 0 }}>
              <Tabs
                value={currentTab}
                onChange={handleChangeTab}
                sx={{
                  width: 1,
                  bottom: 0,
                  zIndex: 9,
                  // position: 'absolute',
                  bgcolor: 'background.paper',
                  [`& .${tabsClasses.flexContainer}`]: {
                    pr: { md: 3 },
                  },
                  p: 3
                }}
              >
                {TABS.map((tab) => (
                  <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
                ))}
              </Tabs>

              {currentTab === 'balanceenquiry' && <BalanceEnquiry />}

              {currentTab === 'cashwithdrawal' &&  <CashWithdrawal /> }

              {currentTab === 'ministatement' && (
                <MiniStatement />
              )}

              {currentTab === 'aadharpay' && <AadharPay />}

            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Dialog className='add_dialog' open={open} onClose={handleClose} >
        <FormProvider methods={methods} onSubmit={handleSubmit}>
          <DialogTitle>Authentication</DialogTitle>
          <DialogContent>
            {/* Your FormProvider content */}

            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Biometric capturData={onChangeCaptureData} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" type="submit" disabled={!capturData} onClick={() => {
              handleSubmit();
            }} loading={isSubmitting}
            >
              Save
            </Button>
          </DialogActions>
        </FormProvider>
      </Dialog>


      <Dialog className='add_dialog' open={reister} onClose={handleClose} >
        <form>
          <DialogTitle>Register Two Factor Authentication</DialogTitle>
          <DialogContent>
            {/* Your form content */}
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Biometric capturData={onChangeCaptureData} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" type="submit" disabled={!capturData} onClick={handleRegister}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );






  return (
    <>
      {pStatus === 0 ? (
        OnBoarding
      ) : (
        Aeps
      )}

    </>

  );

}
